import { useSchedule } from "routes/schedule/lib/useSchedule"
import Schedules from "routes/schedule/schedules"
import { ScheduleDetails } from "routes/schedule/scheduleDetails"
import { getScheduleRecordId } from "routes/schedule/lib/get-schedule-record-id"
import { LoadingTemplate } from "lib/@components/loading-template"

export function ScheduleById({ id }) {
    const { schedule, ...props } = useSchedule(getScheduleRecordId(id))
    return (
        <Schedules>
            <LoadingTemplate {...props} schedulePath={id} schedule={schedule} target={schedule}>
                <ScheduleDetails />
            </LoadingTemplate>
        </Schedules>
    )
}
