import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { MdDelete } from "@react-icons/all-files/md/MdDelete"
import { MdWarning } from "@react-icons/all-files/md/MdWarning"

export function Rule({ item, index }) {
    const { target, refresh } = useBoundContext()
    return (
        <ListItem>
            {item === "*" && (
                <ListItemIcon sx={{ color: "warning.main" }}>
                    <MdWarning />
                </ListItemIcon>
            )}
            {item === "*" ? (
                <ListItemText primary={"All users, no email address restrictions"} />
            ) : (
                <TextField
                    fullWidth
                    size="small"
                    autoFocus
                    value={item}
                    onChange={setFromEvent((v) => {
                        target[index] = v
                        refresh()
                    })}
                />
            )}

            <ListItemSecondaryAction>
                <IconButton color="secondary" size="small" onClick={remove}>
                    <MdDelete />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )

    function remove() {
        target.splice(index, 1)
        refresh()
    }
}
