import { gql } from "@apollo/client"
import Iconify from "minimals-template/components/Iconify"
import { GetIcon, HasInvalidated, SidebarTreeRoots } from "event-definitions"
import { refreshTree } from "ioc"
import { cacheTreeRoot, linkUncache, removeCache } from "lib/cached"
import { cloneDeep } from "lib/clone"
import { keyBy } from "lib/keyBy"
import noop from "lib/noop"
import { pick } from "lib/pick"
import { splitId } from "library/database/split-id"
import { Uncache } from "library/server-event-definitions"
import { Basket } from "routes/schedule/plugins/baskets/tree/basket"
import { BasketItem } from "routes/schedule/plugins/baskets/tree/basket-item"
import { mappingSort } from "routes/schedule/tree/schedules/mappingSort"
import { getIconForNav } from "routes/schedule/lib/get-icon-for-nav"
import { isActiveWhen } from "routes/schedule/components/is-active-when"
import { TreeRoot } from "library/tree"
import { getTreeIndex } from "routes/schedule/lib/getTreeIndex"
import { hasDemand } from "lib/authorization/has-demand"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { query } from "lib/graphql/query"
import { addDebugItem } from "debug-window"

Uncache("**").after.handleOnce(refreshTree)

GetIcon.handleOnce((props) => {
    if (props.type === "Tag") {
        props.icon = (
            <Iconify
                icon={props.singular ? "bi:tag-fill" : "bi:tags-fill"}
                sx={{ width: 32, height: 32, color: "primary.main", ...props.sx }}
            />
        )
    }
    if (props.type === "ScheduleBasket" && !props.singular) {
        props.icon = <Iconify icon="mdi:hearts" sx={{ width: 32, height: 32, color: "primary.main", ...props.sx }} />
    }
})

const knownBaskets = new Set()
const currentBaskets = {}

export function getCurrentBaskets() {
    return currentBaskets
}

HasInvalidated("itemBaskets").after.handleOnce(refreshBaskets)
HasInvalidated("baskets").after.handleOnce(refreshBaskets)

function refreshBaskets() {
    Uncache("basket").raise()
    Uncache("tags").raise()
    removeCache("tags")
    removeCache("basket")
    refreshTree()
}

SidebarTreeRoots.handleOnce(
    cacheTreeRoot("basket", async ({ add }) => {
        const { root } = await createTreeViewOfBaskets(
            "ScheduleBasket",
            "Favourites",
            <Basket type={"ScheduleBasket"} />
        )

        add(root)
    })
)

linkUncache("basket", "tags")

SidebarTreeRoots.handleOnce(
    cacheTreeRoot("tags", async ({ add }) => {
        const { root } = await createTreeViewOfBaskets("Tag", "Tags", <Basket label="Tags" type="Tag" />)

        add(root)
    })
)

registerConditionalPageNavigation(
    () => hasDemand("user") && hasDemand("!sharing") && hasDemand("schedules"),
    "/app/schedules?id=basket-ScheduleBasket",
    "Favourites",
    getIconForNav("ScheduleBasket"),
    {
        group: "Schedules",
        priority: 200,
        isActive: isActiveWhen((c) => c.id === "basket-ScheduleBasket"),
    }
)

registerConditionalPageNavigation(
    () => hasDemand("user") && hasDemand("!sharing") && hasDemand("schedules"),
    "/app/schedules?id=basket-Tag",
    "Tags",
    getIconForNav("Tag"),
    {
        group: "Schedules",
        priority: 200,
        isActive: isActiveWhen((c) => c.id === "basket-Tag"),
    }
)

async function createTreeViewOfBaskets(type, label, content) {
    addDebugItem(`Refresh baskets ${type}`)

    const root = {
        content,
        id: `basket-${type}`,
        label,
        priority: 500,
        hasChildren: true,
        [TreeRoot]: true,
        visible: true,
        onExpand: noop,
        onCollapse: noop,
    }
    const baskets = cloneDeep(
        await query(
            gql`
                query baskets($type: String!) {
                    baskets(type: $type) @connection(key: "basketContents", filter: ["type"]) {
                        baskets {
                            id
                            name
                            visible
                            canEdit
                            items {
                                id
                                referenceId
                                reference(fields: ["title", "_id", "code", "notLicensed"])
                            }
                        }
                    }
                }
            `,
            { type },
            { returns: "baskets.baskets" }
        )
    )

    root.children = baskets
        .sortBy("name")
        .filter((b) => b.visible !== false)
        .map(({ id, name, items, canEdit }) => {
            const children = items
                .filter(Boolean)
                .filter(({ reference }) => !!reference)
                .map(({ reference, referenceId }) => {
                    const payload = {
                        label: reference?.title,
                        code: reference?.code,
                        _id: reference?._id,
                        notLicensed: reference?.notLicensed,
                    }

                    knownBaskets.add(id)
                    const retrieveId = referenceId ?? reference?._id
                    const schedule = {
                        ...payload,
                        id: `${id}!${splitId(retrieveId).id}`,
                        isAlias: true,
                        hasChildren: false,
                    }
                    const result = Object.defineProperties(
                        {
                            ...schedule,
                        },
                        {
                            content: {
                                get() {
                                    const newRetrieved = find(retrieveId)
                                    if (!newRetrieved) return null
                                    const { content } = newRetrieved
                                    if (!content?.type) return null
                                    return <content.type {...content.props} schedule={result} />
                                },
                            },
                            $: {
                                get() {
                                    return find(retrieveId)?.$
                                },
                            },
                            notLicensed: {
                                get() {
                                    const retrieved = find(retrieveId)
                                    return schedule?.notLicensed || retrieved?.$?.notLicensed
                                },
                            },
                        }
                    )
                    return result
                })
                .filter(Boolean)
                .sortBy(mappingSort)

            return {
                id: `${id}`,
                hasChildren: true,
                visible: true,
                children,
                canEdit,
                label: name,
                content: <BasketItem canEdit={canEdit} childCount={items.length} type={type} name={name} />,
            }
        })

    currentBaskets[type] = keyBy(root.children, pick("id"))

    return { root, baskets: root.children }
}

export function find(item) {
    const finder = getTreeIndex().retrieve
    return finder?.(item)
}
