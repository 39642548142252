import { FacilityPlugs, StatsPanels, StatsTabs } from "slot-definitions"
import { Box, Tab } from "@mui/material"
import { TabPanel } from "@mui/lab"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Bound } from "lib/@components/binding/Bound"
import { AssetProfileDetails } from "routes/asset-profile/asset-profile.runtime"
import { useAssetProfileStats } from "routes/facilities/controller/asset-profile-stats"

FacilityPlugs.plug(<MaintenanceProfileTab />)

function MaintenanceProfileTab() {
    const context = useBoundContext()
    context.LinkComponent = MyLink
    StatsTabs.usePlug(<Tab value="Profile" label="Profile" />)
    StatsPanels.usePlug(
        <TabPanel value="Profile">
            <Profile />
        </TabPanel>
    )
    return null
}

function MyLink({ children }) {
    return <Box sx={{ fontSize: 13 }}>{children}</Box>
}

export function Profile() {
    const { parentItem } = useBoundContext()
    const stats = useAssetProfileStats(parentItem.id, [parentItem.id])
    return (
        !!stats && (
            <Bound stats={stats}>
                <AssetProfileDetails />
            </Bound>
        )
    )
}
