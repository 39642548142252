import Color from "color"

const colorPalette = [
    "#ffa600",
    "#ff7c43",
    "#f95d6a",
    "#d45087",
    "#a05195",
    "#665191",
    "#2f4b7c",
    "#003f5c",
    "#FF6384", // Vivid Pink
    "#36A2EB", // Bright Blue
    "#FFCE56", // Canary Yellow
    "#9966FF", // Amethyst Purple
    "#FF9F40", // Orange Peel
    "#FFCD56", // Mellow Yellow
    "#C9CBCF", // Pale Silver
    "#FF6384", // Brick Red
    "#36A2EB", // Steel Blue
    "#4BC0C0", // Medium Turquoise
    "#9966FF", // Lavender Purple
    "#C77800", // Saffron
    "#80B1D3", // Sky Blue
    "#B3DE69", // Yellow Green
    "#FCCDE5", // Pale Pink
    "#D9D9D9", // Light Gray
    "#BC80BD", // Wisteria
    "#CCEBC5", // Algae Green
    "#FFED6F", // Dandelion Yellow
]

export function selectionColor(isSelected, i) {
    return isSelected
        ? Color(colorPalette[i % colorPalette.length])
              .lighten(0.2)
              .hex()
        : Color(colorPalette[i % colorPalette.length])
              .darken(0)
              .hex()
}
