import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { useCallback, useMemo, useRef } from "react"
import { completions } from "routes/regime/completions/controller/completions"
import { StatsTabs } from "slot-definitions"
import { Tab } from "@mui/material"
import { Bound } from "lib/@components/binding/Bound"
import { isEnabled } from "lib/@components/feature"
import { FEATURE_FLAG_COMPANION_TASKS } from "routes/sharing/sharing.runtime"

export function CheckForCompletions({ children }) {
    const { parentItem } = useBoundContext()
    const root = parentFind(parentItem, (p) => p[PlanRoot])
    const results = useRef()
    const now = new Date().addHours(1)
    now.setMinutes(0, 0, 0)
    const folder = root !== parentItem ? parentItem.id.split("!").at(-1) : undefined
    results.current = completions.useResults({
        regime: root.id,
        folder,
        startDate: new Date().addYears(-1).beginningOfDay(),
        endDate: now,
    })

    if (results.current) {
        results.current.days = results.current.days.sortBy((c) => -Date.create(c.date))
    }

    const scheduleLookup = useMemo(
        () => (results.current?.schedules ? results.current.schedules.groupBy("id") : {}),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [results.current]
    )

    StatsTabs.usePlug(
        <Tab
            priority={2600}
            value="Completions"
            label="Completed Checks"
            if={() => isEnabled(FEATURE_FLAG_COMPANION_TASKS) && results.current?.count > 0}
        />,
        [results.current]
    )

    const getTask = useCallback(_getTask, [scheduleLookup])

    return (
        <Bound
            completions={results.current}
            regime={root.id}
            folder={folder}
            root={root}
            group={parentItem}
            scheduleLookup={scheduleLookup}
            getTask={getTask}
        >
            {children}
        </Bound>
    )

    function _getTask(id) {
        if (!id) return null
        const [location, task] = id.split("!")
        const [, folder] = location.split(".")
        const [scheduleId, ...taskId] = task.split(".")
        const schedule = scheduleLookup[`${folder}!${scheduleId}`]?.[0]
        const tid = taskId.join(".")
        const result = schedule.tasks.find((t) => tid.startsWith(t.id))
        if (result) result.$schedule = schedule
        return result
    }
}
