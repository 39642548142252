import { Box, ListItemButton } from "@mui/material"
import { prevent } from "lib/prevent"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"
import { RegimeItemTitle, SelectorTitleExtras } from "slot-definitions"
import { getTreeIndex } from "routes/schedule/lib/getTreeIndex"
import { uniqueId } from "library/database/split-id"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { BadgeLabel } from "routes/regime/plugins/regime-title/badge-label"
import { RecycleType } from "event-definitions"
import { parentFind } from "library/tree"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { or } from "lib/logic"

export function Regime({ last, regime = {}, Component = ListItemButton }) {
    const { name = "Regime" } = regime
    const { onClick, selected, noDivider } = useBoundContext()
    last = last || noDivider
    return (
        <Component divider={!last} selected={selected} onClick={prevent(clickRegime)}>
            <ListItemBox spacing={1}>
                <FolderItemPart />
                <FolderItemPart>
                    <FolderIcon icon="pajamas:issue-type-maintenance" sx={{ color: "myStuff.main" }} />
                </FolderItemPart>
                <Box flex={1}>{name}</Box>
            </ListItemBox>
        </Component>
    )

    function clickRegime(e) {
        onClick(e)
    }
}

RegimeItemTitle.plug(<Ready if={or(isReadyPlan, hasReadyPlan)} />)
SelectorTitleExtras.plug(<Ready if={isReadyPlan} />)
RegimeItemTitle.plug(<Live if={or(isLivePlan, hasLivePlan)} />)
SelectorTitleExtras.plug(<Live if={isLivePlan} />)
RecycleType.handle(({ item }) => {
    if (item.icon === "ph:tree-structure") {
        return { type: "Regime" }
    }
    return undefined
})

function Live() {
    return (
        <Box pb={1} sx={{ zoom: 0.8 }}>
            <BadgeLabel color="myStuff" badgeContent="Live" />
        </Box>
    )
}

function Ready() {
    return (
        <Box pb={1} sx={{ zoom: 0.8 }}>
            <BadgeLabel color="secondary" badgeContent="Candidate" />
        </Box>
    )
}

function hasLivePlan({ parentItem }) {
    const treeIndex = getTreeIndex()
    const rootPlan = parentFind(parentItem, (c) => c[PlanRoot])
    return treeIndex[`live|${uniqueId(rootPlan._id.split("|").at(-1))}`]
}

function hasReadyPlan({ parentItem }) {
    const treeIndex = getTreeIndex()
    const rootPlan = parentFind(parentItem, (c) => c[PlanRoot])
    return treeIndex[`ready|${uniqueId(rootPlan._id.split("|").at(-1))}`]
}

function isLivePlan({ parentItem }) {
    const rootPlan = parentFind(parentItem, (c) => c[PlanRoot])

    return rootPlan?._id.startsWith("live|")
}

function isReadyPlan({ parentItem }) {
    const rootPlan = parentFind(parentItem, (c) => c[PlanRoot])
    return rootPlan?._id.startsWith("ready|")
}
