import { Box, Tooltip, Typography } from "@mui/material"
import { uniqueId } from "library/database/split-id"
import Iconify from "minimals-template/components/Iconify"
import { statusIcons } from "routes/facilities/lib/status-icons"
import { useModifiedSchedule } from "routes/schedule/lib/use-modified-schedule"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function ScheduleCode({ sx }) {
    const { parentItem, schedule } = useBoundContext()
    const modified = useModifiedSchedule(parentItem?.id, uniqueId(schedule._id))
    if (!modified) return null
    return (
        <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary", ml: 2, whiteSpace: "nowrap", ...sx }}
        >
            #{modified.code}
        </Typography>
    )
}

export function ScheduleStatus() {
    const { parentItem, schedule } = useBoundContext()
    let status = parentItem.data?.statuses?.[uniqueId(schedule._id)]
    if (typeof status === "string") {
        status = parentItem.data.statuses[uniqueId(schedule._id)] = { summary: status, reasons: [] }
    }
    return <ScheduleStatusTooltipIcon reasons={status?.reasons} summary={status?.summary} />
}

export function ScheduleStatusTooltipIcon({ reasons = [], summary }) {
    return (
        <Tooltip arrow title={reasons.join(", ")}>
            <Box width={16} sx={{ color: summary }}>
                <Iconify icon={statusIcons[summary]} />
            </Box>
        </Tooltip>
    )
}
