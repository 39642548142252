import { Button, InputAdornment, Stack, TextField, Tooltip } from "@mui/material"
import { MdSearch } from "@react-icons/all-files/md/MdSearch"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { setFromEvent } from "lib/setFromEvent"
import { useState } from "react"
import { isMobileApp } from "routes/@layouts/app/header/is-mobile-app"
import { getSchedulesForExport } from "../controller/get-schedules-for-export"
import LoadingScreen from "minimals-template/components/LoadingScreen"

export function ExportScheduleSearch() {
    const { setPayload } = useBoundContext()
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState("")

    const tooltip = search ? undefined : "This button is disabled while the search bar is empty."
    return isLoading ? (
        <LoadingScreen />
    ) : (
        <Stack spacing={2} sx={{ width: 1, my: 4 }}>
            <TextField
                variant="outlined"
                autoFocus
                name="search"
                onKeyUp={(e) => search && e.key === "Enter" && handleSearch(search)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip arrow title={tooltip}>
                                <span>
                                    <Button
                                        onClick={() => handleSearch(search)}
                                        size="small"
                                        sx={{ fontSize: 24 }}
                                        color="primary"
                                        variant="contained"
                                        disabled={search === ""}
                                    >
                                        <MdSearch />
                                    </Button>
                                </span>
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
                placeholder={isMobileApp ? "Search..." : "Search by title, code, or id"}
                value={search}
                onChange={setFromEvent(setSearch)}
                fullWidth
                data-cy="export-schedule-search"
            />
        </Stack>
    )

    async function handleSearch(searchTerm) {
        try {
            setSearch("")
            setIsLoading(true)
            setPayload(await getSchedulesForExport(searchTerm))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            throw new Error(e)
        }
    }
}
