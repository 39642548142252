import { ContextMenu } from "lib/@components/context-menu-bar"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { appRoute } from "routes/app/home.runtime"
import { useParams } from "react-router-dom"

import Schedules from "routes/schedule/schedules"
import { useAssetProfileStats } from "routes/facilities/controller/asset-profile-stats"
import Page from "minimals-template/components/Page"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { Bound } from "lib/@components/binding/Bound"

import {
    Box,
    Button,
    Container,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import useSettings from "minimals-template/components/@hooks/useSettings"
import { keyBy } from "lib/keyBy"
import { pick } from "lib/pick"
import { formatTimeShort } from "lib/format-time"
import Iconify from "minimals-template/components/Iconify"
import { clone } from "lib/clone"
import { ListItemBox } from "lib/@components/ListItemBox"
import { ToolbarBox } from "lib/@components/toolbarBox"
import { TopBox } from "lib/@components/topBox"
import { isInRegime } from "routes/facilities/lib/when-parent-item"
import { RouterLink } from "lib/routes/router-link"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

ContextMenu("folder").plug(({ context }) => {
    if (!isInRegime(context)) return null
    if (!context.parentItem.children?.length) {
        return null
    }
    return (
        <ContextMenuItem
            priority={900}
            divider={true}
            icon="cil:spreadsheet"
            label="Maintenance Profile"
            onClick={go}
        />
    )

    function go() {
        navigate(`/app/assetProfile/${context.parentItem.id}`)
    }
})

appRoute.register(
    "assetProfile/:id",
    <Schedules>
        <AssetProfile />
    </Schedules>
)

function AssetProfile() {
    const { themeStretch } = useSettings()

    const { id } = useParams()
    const stats = useAssetProfileStats(id)
    const parentItem = useTreeItem(id)

    return (
        !!parentItem &&
        !!stats && (
            <Bound parentItem={parentItem} group={parentItem} stats={stats}>
                <Page title={`Overview Of ${parentItem.label}`}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            overflow: "hidden",
                            height: 1,
                        }}
                    >
                        <Container maxWidth={themeStretch ? false : "lg"}>
                            <Box height={40} />
                            <ToolbarBox>
                                <TopBox>
                                    <Button
                                        size="small"
                                        onClick={() => window.history.back()}
                                        startIcon={<Iconify icon="ic:round-keyboard-backspace" />}
                                        variant="contained"
                                        sx={{ bgcolor: "primary.dark", mb: 2, ml: 1 }}
                                    >
                                        Back
                                    </Button>
                                </TopBox>
                            </ToolbarBox>

                            <Stack spacing={1} sx={{ ml: 1 }}>
                                <Typography variant="subtitle1">Maintenance Profile of {parentItem.label}</Typography>
                            </Stack>
                        </Container>
                        <Container
                            maxWidth={themeStretch ? false : "xl"}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                overflow: "auto",
                                height: 1,
                            }}
                        >
                            <AssetProfileDetails />
                        </Container>
                    </Box>
                </Page>
            </Bound>
        )
    )
}

export function AssetProfileDetails() {
    const { stats } = useBoundContext()

    return (
        <TableContainer sx={{ mt: 2 }} component={Paper}>
            <Table stickyHeader={true} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                backdropFilter: "blur(20px)",
                                bgcolor: "action.hover",
                                minWidth: "fit-content(30em)",
                            }}
                        >
                            Schedule Name
                        </TableCell>
                        {stats.periods.map((period) => (
                            <TableCell
                                sx={{ textAlign: "center", backdropFilter: "blur(20px)" }}
                                key={period.frequency}
                            >
                                {period.frequency}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody sx={{ whiteSpace: "nowrap" }}>
                    {clone(stats.all)
                        .sortBy(["where", "title"])
                        .filter((s) => !!s.periods?.length)
                        .map((scheduleStats) => {
                            const schedulePeriods = keyBy(scheduleStats.periods, pick("frequency"))
                            return (
                                <TableRow
                                    sx={{
                                        "&:nth-of-type(even) td": {
                                            backgroundColor: "action.hover",
                                        },
                                        "&:nth-of-type(odd) td": {
                                            backgroundColor: "background.paper",
                                        },
                                        "& td": {
                                            borderRight: "1px solid #44444418",
                                        },
                                    }}
                                    key={scheduleStats.id}
                                >
                                    <TableCell
                                        sx={{
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <ScheduleAssetTitle stats={scheduleStats} />
                                    </TableCell>
                                    {stats.periods.map((period) => (
                                        <TableCell
                                            sx={{
                                                py: 0.7,
                                                px: 0.4,
                                                textAlign: "right",
                                                "&:last-of-type": {
                                                    pr: 0.4,
                                                },
                                            }}
                                            key={period.frequency}
                                        >
                                            <SchedulePeriodProfile
                                                period={period}
                                                scheduleStats={scheduleStats}
                                                schedulePeriods={schedulePeriods}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function ScheduleAssetTitle({ stats }) {
    const { LinkComponent = Link } = useBoundContext()
    return (
        <Box display="flex" flexDirection="column" alignItems="stretch">
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {stats.where}
            </Typography>
            <LinkComponent component={RouterLink} to={`/app/schedules/${stats.id}`}>
                <ListItemBox>
                    <Typography variant="body2">{stats.title.truncate(45, false)}</Typography>
                    <Box flex={1} />
                    <Box ml={1}>{stats.code}</Box>
                </ListItemBox>
            </LinkComponent>
        </Box>
    )
}

function SchedulePeriodProfile({ schedulePeriods, period }) {
    const { isPDF } = useBoundContext()
    const schedulePeriod = schedulePeriods[period.frequency]
    if (!schedulePeriod) return null
    const classifications = keyBy(schedulePeriod.measurements, pick("classification"))

    let icon
    let color
    if (classifications.Red) {
        icon = "bi:diamond-fill"
        color = "red"
    } else if (classifications.Pink) {
        icon = "fluent:triangle-32-filled"
        color = "hotpink"
    } else if (classifications.Amber) {
        icon = "fluent:circle-24-filled"
        color = "orange"
    } else if (classifications.Green) {
        icon = "fluent:triangle-down-12-filled"
        color = "green"
    }

    return (
        <Box
            sx={{
                ml: 0.5,
                fontSize: "0.8rem",
                ...(isPDF && { width: 80, maxWidth: 80, mr: 0.5, flexWrap: "wrap" }),
            }}
            display="flex"
            alignItems="center"
        >
            <Box mr={1} display="flex" justifyContent="flex-end">
                <Box mr={0.5} className={`${color} dot`}>
                    <Iconify icon={icon} sx={{ color }} />
                </Box>
                <Box
                    minWidth={16}
                    sx={{ ...(isPDF && { wordWrap: "break-word", whiteSpace: "normal", maxWidth: 70 }) }}
                >
                    {schedulePeriod.count}
                </Box>
            </Box>
            <Box
                sx={{
                    minWidth: 35,
                    fontSize: "0.7rem",
                    color: "text.secondary",
                    textAlign: "right",
                    ...(isPDF && { wordWrap: "break-word", whiteSpace: "normal", maxWidth: 70 }),
                }}
            >
                ({formatTimeShort(schedulePeriod.minutes)})
            </Box>
        </Box>
    )
}
