import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getFlagsForId } from "routes/schedule/plugins/flags/controller/flag-controller"
import { Repeat } from "lib/@components/repeat"
import { Flag } from "routes/schedule/plugins/flags/components/flag"

export function Flags() {
    const { schedule } = useBoundContext()
    const flags = getFlagsForId.useResults(schedule._id) ?? []
    return <Repeat list={flags} item={<Flag />} bind />
}
