import { BoundTypography } from "lib/@components/binding/bound-components"
import { Box } from "@mui/material"
import { DATE_FORMATS } from "lib/@constants/date-format"

export function CompletedOn() {
    return (
        <Box pr={1}>
            <BoundTypography
                sx={{ width: 52, textAlign: "right" }}
                component="div"
                variant="caption"
                color="text.secondary"
                field="completedOn"
                transformIn={(v) => new Date(v).format(DATE_FORMATS.medium)}
            />
        </Box>
    )
}
