import { Task } from "routes/schedule/tasks/task"
import { ListItemBox } from "lib/@components/ListItemBox"
import { BoundCheckBox } from "lib/@components/standard-checkbox"
import { Box } from "@mui/material"
import { taskId } from "routes/asset-register/plugins/schedule-options/task-id"

export function OptionalTask({ item, schedule, last }) {
    let $index = 1
    schedule.tasks.forEach((t) => {
        if (!t.isHeader) t.$index = $index++
    })

    const task = schedule.tasks?.find((t) => t.id === item)
    return (
        !!task && (
            <ListItemBox>
                <Box alignSelf="flex-start">
                    <BoundCheckBox field={taskId(task.id)} defaultValue={true} label="" />
                </Box>
                <Box flex={1}>
                    <Task item={task} last={last} />
                </Box>
            </ListItemBox>
        )
    )
}
