import { Box, Link, Typography } from "@mui/material"
import { OverarchingIntroductionLink } from "event-definitions"
import { InPdf } from "lib/@components/pdf/in-pdf"
import { NotInPdf } from "lib/@components/pdf/not-in-pdf"
import { RouterLink } from "lib/routes/router-link"

export function OverarchingIntroductionText({ overarchingIntroductionId, variant = "" }) {
    const { link } = OverarchingIntroductionLink.call({
        link: `/app/schedules/${overarchingIntroductionId}`,
        id: overarchingIntroductionId,
    })

    return (
        <>
            <Box>
                <Typography variant={variant}>
                    Please refer to the{" "}
                    <NotInPdf>
                        <Link component={RouterLink} to={link}>
                            overarching introduction
                        </Link>
                    </NotInPdf>
                    <InPdf>overarching introduction</InPdf> (SFG 00-01) to make sure you are of the correct skill level
                    as indicated within the task schedule to carry out the described works. Ensure you have read and
                    understood the manufacturer&apos;s recommendations, carried out risk assessment(s) on each item of
                    plant to identify the correct frequency of maintenance, identified all safety procedures that need
                    to be applied and recorded in order to carry out the work in a safe and reliable manner.
                </Typography>
            </Box>
            <Box mt={1}>
                <Typography variant={variant}>
                    If this asset (item of equipment/system) is within the warranty or guarantee period, it is essential
                    that you maintain it in full accordance with the specific manufacturer&apos;s or installer&apos;s
                    maintenance requirements. If you are in any doubt about the maintenance requirements or the warranty
                    or guarantee period please contact the manufacturer, installer or their representative to seek
                    clarity.
                </Typography>
            </Box>
        </>
    )
}
