import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function updateAction(id, payload) {
    return mutate(
        gql`
            mutation UpdateAction($id: String!, $payload: JSONObject!) {
                updateAction(id: $id, payload: $payload)
            }
        `,
        { id, payload },
        { returns: "updateAction" }
    )
}
