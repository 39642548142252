import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { Box, Chip, Link, Stack, TableCell, TableRow, Typography } from "@mui/material"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"
import Iconify from "minimals-template/components/Iconify"
import { formatTime } from "lib/format-time"
import { Repeat } from "lib/@components/repeat"
import { navigate } from "lib/routes/navigate"
import { uniqueId } from "library/database/split-id"

export function AssetEntry({ item }) {
    return (
        !!item && (
            <TableRow>
                <TableCell
                    sx={{
                        minWidth: 200,
                        position: "sticky",
                        left: 0,
                        bgcolor: "background.default",
                        zIndex: 100,
                        boxShadow: "-1px 0px 0px 0px rgba(0,0,0,0.1) inset",
                    }}
                >
                    <Link onClick={openAsset}>{item.tag}</Link>
                </TableCell>
                <TableCell>
                    <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                        {!!item.red && (
                            <>
                                <Box sx={{ color: COLOR_FROM_CLASSIFICATION.Red }}>
                                    <Iconify icon={ICON_FROM_CLASSIFICATION.Red} />
                                </Box>
                                <Typography
                                    component="div"
                                    sx={{ pr: 1, minWidth: 20, textAlign: "right" }}
                                    variant="caption"
                                >
                                    {item.red}
                                </Typography>
                            </>
                        )}
                        {!!item.pink && (
                            <>
                                <Box sx={{ color: COLOR_FROM_CLASSIFICATION.Pink }}>
                                    <Iconify icon={ICON_FROM_CLASSIFICATION.Pink} />
                                </Box>
                                <Typography
                                    component="div"
                                    sx={{ pr: 1, minWidth: 20, textAlign: "right" }}
                                    variant="caption"
                                >
                                    {item.pink}
                                </Typography>
                            </>
                        )}
                        {!!item.amber && (
                            <>
                                <Box sx={{ color: COLOR_FROM_CLASSIFICATION.Amber }}>
                                    <Iconify icon={ICON_FROM_CLASSIFICATION.Amber} />
                                </Box>
                                <Typography
                                    component="div"
                                    sx={{ pr: 1, minWidth: 20, textAlign: "right" }}
                                    variant="caption"
                                >
                                    {item.amber}
                                </Typography>
                            </>
                        )}
                        {!!item.green && (
                            <>
                                <Box sx={{ color: COLOR_FROM_CLASSIFICATION.Green }}>
                                    <Iconify icon={ICON_FROM_CLASSIFICATION.Green} />
                                </Box>
                                <Typography
                                    component="div"
                                    sx={{ pr: 1, minWidth: 20, textAlign: "right" }}
                                    variant="caption"
                                >
                                    {item.green}
                                </Typography>
                            </>
                        )}
                    </Stack>
                </TableCell>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.facility}</TableCell>
                <TableCell>{item.floor}</TableCell>
                <TableCell>{item.space}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>
                    <Stack direction="row" spacing={0.5}>
                        <Repeat list={item.tasks.unique("scheduleId")} item={<ScheduleEntry />} />
                    </Stack>
                </TableCell>
                <TableCell>{item.system}</TableCell>
                <TableCell>{item.manufacturer}</TableCell>
                <TableCell align="right">{formatTime(item.minutes)}</TableCell>

                <TableCell align="right">{item.tasks.length}</TableCell>
                <TableCell
                    sx={{
                        position: "sticky",
                        right: 0,
                        zIndex: 100,
                        bgcolor: "background.default",
                        boxShadow: "1px 0px 0px 0px rgba(0,0,0,0.1) inset",
                    }}
                >
                    <Stack direction="row" spacing={0.5} justifyContent="center">
                        <Repeat list={item.tasks.unique("scheduleId")} item={<ScheduleCodeEntry />} />
                    </Stack>
                </TableCell>
            </TableRow>
        )
    )

    function openAsset() {
        navigate("/app/asset/" + uniqueId(item.id))
    }
}

function ScheduleEntry({ item }) {
    const { parentItem } = useBoundContext()
    const planRoot = parentFind(parentItem, (c) => c[PlanRoot])

    return (
        <Link sx={{ fontSize: 12 }} onClick={go}>
            {item.scheduleTitle}
        </Link>
    )

    function go() {
        const [group, scheduleId] = item.scheduleId.split("!")
        navigate(`/app/schedules/${group}~${planRoot.id}!${scheduleId}`)
    }
}

function ScheduleCodeEntry({ item }) {
    const { parentItem } = useBoundContext()
    const planRoot = parentFind(parentItem, (c) => c[PlanRoot])

    return <Chip sx={{ fontSize: 12, color: "primary.main" }} onClick={go} label={item.code} size="small" />

    function go() {
        const [group, scheduleId] = item.scheduleId.split("!")
        navigate(`/app/schedules/${group}~${planRoot.id}!${scheduleId}`)
    }
}
