import { clone } from "lib/clone"
import { getSchedule } from "routes/schedule/lib/get-schedule"
import { AdaptSchedule, FlushScheduleCache, HasInvalidated } from "event-definitions"
import { FlushAllCache, HighMemoryUsage } from "library/server-event-definitions"

export const scheduleCache = new Map()

HighMemoryUsage.handle(() => scheduleCache.clear())
FlushAllCache.handle(() => scheduleCache.clear())
FlushScheduleCache.handle(() => scheduleCache.clear())
HasInvalidated("schedule").handle(() => scheduleCache.clear())

export async function getCachedSchedule(code, version) {
    const key = `${code}:${version}`
    if (!code) return undefined
    try {
        let result
        if (scheduleCache.has(key)) {
            result = clone(await scheduleCache.get(key))
        } else {
            const promise = getSchedule(code, version)
            scheduleCache.set(key, promise)
            result = clone(await promise)

            if (!result) {
                scheduleCache.delete(key)
            }
        }
        return AdaptSchedule.call({
            error: null,
            schedule: result,
            loading: false,
        })
    } catch (e) {
        console.error(e)
        return undefined
    }
}
