import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"
import { ListItemBox } from "lib/@components/ListItemBox"
import AppWidget from "minimals-template/pages/sections/@dashboard/general/app/AppWidget"
import { navigate } from "lib/routes/navigate"

export function Summaries() {
    const treeIndex = useTreeItemIndex()

    const licensed = treeIndex.licensed?.children.length ?? 0
    const notLicensed = treeIndex.notLicensed?.children.length ?? 0
    const custom = treeIndex.custom?.children.length ?? 0
    const total = licensed + notLicensed + custom

    return (
        <ListItemBox my={2} spacing={1} sx={{ flexWrap: "wrap", justifyContent: "stretch" }}>
            <AppWidget
                data-cy="licensed-summmary"
                sx={{ cursor: "pointer", flex: 1 }}
                role="button"
                aria-label="Licensed Schedules"
                onClick={() => navigate("/app/schedules?id=licensed")}
                title="Licensed"
                color="success"
                total={licensed}
                icon="mdi:file-document-outline"
                chartData={{
                    series: [Math.round((licensed / total) * 100)],
                }}
            />

            <AppWidget
                data-cy="unlicensed-summmary"
                sx={{ cursor: "pointer", flex: 1 }}
                role="button"
                aria-label="Unlicensed Schedules"
                onClick={() => navigate("/app/schedules?id=notLicensed")}
                title="To Purchase"
                color="warning"
                total={notLicensed}
                icon="mdi:file-document-outline"
                chartData={{
                    series: [Math.round((notLicensed / total) * 100)],
                }}
            />

            <AppWidget
                data-cy="custom-summary"
                sx={{ cursor: "pointer", flex: 1 }}
                role="button"
                aria-label="Custom Schedules"
                onClick={() => navigate("/app/schedules?id=custom")}
                title="Custom"
                total={custom}
                icon="mdi:file-document-outline"
                chartData={{
                    series: [Math.round((custom / total) * 100)],
                }}
            />
        </ListItemBox>
    )
}
