import { Box, Table, TableBody, TableContainer } from "@mui/material"
import { useState } from "react"
import { Repeat } from "lib/@components/repeat"
import { TabledRepeatHeaderRow } from "./tabled-repeat-header-row"
import { searchItems, TabledRepeatSearchBar } from "./tabled-repeat-search-bar"
import { NoResults } from "../no-results/no-results"
import { useMobile } from "../mobile"
import { useReferenceState } from "lib/@hooks/use-reference"
import { TablePaginator } from "./tabled-repeat-paginator"
import { ResetTabledRepeat } from "event-definitions"

/**
 * TabledRepeat is a component that will take an array of objects and display them in a table.
 * The table can have searching, sorting, and paginating functionality.
 * @param {Array} items - Array of objects which will populate the table
 * @param {string} itemPass - String passed to the Repeat component to recognise the item passed in each row.
 * @param {React.Component} itemComponent - React component passed to the Repeat component. It will be used to display each item from the items array.
 * @param {Array} tableHeaders - Array of objects, containing information regarding the table headers.
 *  Each object can have the following properties:
 *      title: string, display name of the table header e.g. "Numerical Id"
 *      fieldKey: string, name of the header key e.g. "numericalId"
 *      sortable:  boolean, if set to true, will be able to sort by this field
 * @param {Array} searchKeys - Array of objects, used to set the fields to be searcheable. If not passed, the search bar will not be displayed on the page.
 *  Each object can have the following properties:
 *      key: string, name of the key to be searched
 *      title: string, display name of the key
 * @param {boolean} [paged=false] - Pagination disabled by default. If set to true, pagination will be enabled.
 *
 * @returns {JSX.Element} - a table component, along with optional search bar and/ or pagination.
 */

export function TabledRepeat({ items, itemPass, itemComponent, tableHeaders, searchKeys, paged = false }) {
    const noSorting = !tableHeaders.some((th) => th.sortable === true)
    const initialFieldKey = noSorting ? "" : tableHeaders.filter((th) => th.sortable === true)[0].fieldKey
    const [search, setSearch] = useState("")
    const [sortedItems, setSortedItems] = useState(items.sortBy(initialFieldKey))
    const [order, setOrder] = useState("")
    const [orderBy, setOrderBy] = useState(initialFieldKey)
    const searchResults = searchItems(search, sortedItems, searchKeys)
    const isMobile = useMobile()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useReferenceState("tabled-repeat-page-size", 5)

    let onPageResults = []
    let showPaginator = false
    if (paged) {
        onPageResults = searchResults?.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
        if (page !== 1 || onPageResults?.length > 4) {
            showPaginator = true
        }
    } else {
        onPageResults = searchResults
    }

    ResetTabledRepeat.useEvent(() => {
        setSortedItems(items.sortBy(initialFieldKey))
        setPage(1)
        setOrderBy(initialFieldKey)
        setOrder("")
    }, [items])

    return (
        <>
            <TabledRepeatSearchBar searchKeys={searchKeys} search={search} setSearch={setSearch} setPage={setPage} />
            {searchResults.length === 0 && <NoResults />}
            {searchResults.length > 0 && (
                <Box>
                    {paged && showPaginator && (
                        <TablePaginator
                            items={searchResults}
                            isMobile={isMobile}
                            page={page}
                            pageSize={pageSize}
                            setPage={setPage}
                            setPageSize={setPageSize}
                        />
                    )}
                    <TableContainer>
                        <Table
                            sx={{
                                "tr:nth-of-type(even) td": {
                                    backgroundColor: "action.hover",
                                },
                                "tr:nth-of-type(odd) td": {
                                    backgroundColor: "background.paper",
                                },
                            }}
                        >
                            <TabledRepeatHeaderRow
                                tableHeaders={tableHeaders}
                                orderBy={orderBy}
                                order={order}
                                sortedItems={sortedItems}
                                setSortedItems={setSortedItems}
                                setOrder={setOrder}
                                setOrderBy={setOrderBy}
                                initialFieldKey={initialFieldKey}
                            />

                            <TableBody>
                                <Repeat list={onPageResults} pass={itemPass} item={itemComponent} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!isMobile && paged && showPaginator && (
                        <TablePaginator
                            items={searchResults}
                            isMobile={isMobile}
                            page={page}
                            pageSize={pageSize}
                            setPage={setPage}
                            setPageSize={setPageSize}
                        />
                    )}
                </Box>
            )}
        </>
    )
}
