import useSettings from "minimals-template/components/@hooks/useSettings"
import { useMemo } from "react"
import palette from "theme/palette"
import typography from "theme/typography"
import breakpoints from "theme/breakpoints"
import shadows, { customShadows } from "theme/shadows"
import { createTheme, ThemeProvider as MUIThemeProvider } from "@mui/material/styles"
import componentsOverride from "theme/overrides"

export function DarkThemeProvider({ children }) {
    const { themeDirection } = useSettings()
    const themeOptions = useMemo(
        () => ({
            palette: palette.dark,
            typography,
            breakpoints,
            shape: { borderRadius: 8 },
            direction: themeDirection,
            shadows: shadows.dark,
            customShadows: customShadows.dark,
        }),
        [themeDirection]
    )

    const theme = createTheme(themeOptions)
    theme.components = componentsOverride(theme)

    return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
}
