import { useEffect } from "react"
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"
import { getDisplayActions } from "routes/actions/controller/get-display-actions"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PageBreadCrumbs } from "slot-definitions"
import { useReferenceState } from "lib/@hooks/use-reference"
import { OutlinedBadge } from "lib/@components/outlined-badge"
import LoadingScreen from "minimals-template/components/LoadingScreen"

// const sorts = [sortCreated, sortDue, sortDue, sortDue, sortDue]

// function sortCreated(c) {
//     return -new Date(c.created).getTime()
// }

// function sortDue(c) {
//     return c.dueDate
// }

const types = ["recent", "all", "silenced", "starred", "overdue", "completed"]

export function ListFilters() {
    const { setList, setComplete, skip, setTotal, setSkip } = useBoundContext()
    const [filter, setFilter] = useSearchParam("filter", 0, (v) => +v)
    const [search] = useSearchParam("search")
    const [{ value }] = useReferenceState("actionFilter", {})
    const actions = getDisplayActions.useResults({
        type: types[filter],
        skip,
        take: 10,
        search,
        filter: value,
    })
    useEffect(() => {
        if (actions?.actions) {
            setList(actions.actions)
            setTotal(actions.total)
            setComplete(filter === 5)
        }
    }, [actions, filter, setComplete, setList, setTotal])

    if (!actions) return <LoadingScreen />

    return (
        <List
            className="action-filters"
            sx={{ minWidth: 240, py: 0, "& .Mui-selected": { color: "text.primary" }, color: "text.secondary" }}
        >
            <ListItemButton
                className="recent action-filter"
                selected={filter === 0}
                onClick={() => changeActionFilter(0)}
            >
                <ListItemBox>
                    <ListItemIcon>
                        <Iconify icon="mdi:clipboard-text-clock" />
                    </ListItemIcon>
                    <ListItemText primary="Recent Actions" />
                    {filter === 0 && (
                        <PageBreadCrumbs.Plug>
                            <>Recent</>
                        </PageBreadCrumbs.Plug>
                    )}
                    <Box flex={1} mr={1} />
                    <OutlinedBadge color="primary.main" content={actions?.recent} />
                </ListItemBox>
            </ListItemButton>
            <ListItemButton className="all action-filter" selected={filter === 1} onClick={() => changeActionFilter(1)}>
                <ListItemBox>
                    <ListItemIcon>
                        <Iconify icon="mdi:clipboard-text" />
                    </ListItemIcon>
                    <ListItemText primary="All Actions" />
                    {filter === 1 && (
                        <PageBreadCrumbs.Plug>
                            <>All</>
                        </PageBreadCrumbs.Plug>
                    )}
                    <Box flex={1} mr={1} />
                    <OutlinedBadge color="primary.main" content={actions?.all} />
                </ListItemBox>
            </ListItemButton>
            <ListItemButton
                className="silenced action-filter"
                selected={filter === 2}
                onClick={() => changeActionFilter(2)}
            >
                <ListItemBox>
                    <ListItemIcon>
                        <Iconify icon="mdi:bell-off" />
                    </ListItemIcon>
                    {filter === 2 && (
                        <PageBreadCrumbs.Plug>
                            <>Silenced</>
                        </PageBreadCrumbs.Plug>
                    )}
                    <ListItemText primary="Silenced Actions" />
                    <Box flex={1} mr={1} />
                    <OutlinedBadge content={actions?.silenced} />
                </ListItemBox>
            </ListItemButton>
            <ListItemButton
                className="overdue action-filter"
                selected={filter === 4}
                onClick={() => changeActionFilter(4)}
            >
                <ListItemBox>
                    <ListItemIcon>
                        <Iconify icon="ic:twotone-access-time-filled" />
                    </ListItemIcon>
                    <ListItemText primary="Overdue Actions" />
                    {filter === 4 && (
                        <PageBreadCrumbs.Plug>
                            <>Overdue</>
                        </PageBreadCrumbs.Plug>
                    )}
                    <Box flex={1} mr={1} />
                    <OutlinedBadge color="error.main" content={actions?.overdue} />
                </ListItemBox>
            </ListItemButton>
            <ListItemButton
                className="starred action-filter"
                selected={filter === 3}
                onClick={() => changeActionFilter(3)}
            >
                <ListItemBox>
                    <ListItemIcon>
                        <Iconify icon="ic:round-star" />
                    </ListItemIcon>
                    <ListItemText primary="Starred" />
                    <Box flex={1} mr={1} />
                    {filter === 3 && (
                        <PageBreadCrumbs.Plug>
                            <>Starred</>
                        </PageBreadCrumbs.Plug>
                    )}
                    <OutlinedBadge color="primary.main" content={actions?.starred} />
                </ListItemBox>
            </ListItemButton>
            <ListItemButton
                className="complete action-filter"
                selected={filter === 5}
                onClick={() => changeActionFilter(5)}
            >
                <ListItemBox>
                    <ListItemIcon>
                        <Iconify icon="material-symbols:check-small" />
                    </ListItemIcon>
                    {filter === 5 && (
                        <PageBreadCrumbs.Plug>
                            <>Complete</>
                        </PageBreadCrumbs.Plug>
                    )}
                    <ListItemText primary="Complete" />
                    <Box flex={1} mr={1} />
                    <OutlinedBadge color="primary.main" content={actions?.complete} />
                </ListItemBox>
            </ListItemButton>
        </List>
    )
    function changeActionFilter(filterIndex) {
        if (filterIndex !== filter) {
            setFilter(filterIndex)
            setSkip(0)
        }
    }
}
