import { useState } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { assetsFromManyRegisters } from "routes/asset-register/controller/assets-from-many-registers"
import { Stack } from "@mui/material"
import { DebouncedTextField } from "lib/@components/debounced-text-field"
import { setFromEvent } from "lib/setFromEvent"
import { RequestPageRepeat } from "lib/@components/request-page-repeat"
import { AssetItem } from "routes/regime/assets/asset-item"
import { range } from "lib/range"

export function AssetSelector() {
    const [search, setSearch] = useState("")
    const [skip, setSkip] = useState(0)
    const { target, uom, assetRegisters } = useBoundContext()
    const { totalCount, items: assets } = assetsFromManyRegisters.useResults({
        registers: assetRegisters,
        skip,
        search,
        sort: "tag",
        take: 10,
    }) ?? { totalCount: 0, items: [] }
    const count = +target[uom]
    const ignore = new Set(range(count).map((i) => target[`${uom}_${i + 1}`]))

    return (
        <Stack spacing={1} pt={2}>
            <DebouncedTextField
                autoFocus
                placeholder="Filter..."
                onChange={setFromEvent(setSearch)}
                value={search}
                fullWidth
                variant="standard"
            />
            <RequestPageRepeat
                total={totalCount}
                setSkip={setSkip}
                list={assets.filter((i) => !ignore.has(i.id))}
                item={<AssetItem />}
            />
        </Stack>
    )
}
