import { useStoreState } from "lib/store"
import { Collapse } from "@mui/material"
import { TreeList } from "routes/schedule/plugins/used-schedules/tree-list"
import { AboveRightBarTabs, ScheduleTreeBar } from "slot-definitions"
import { RelatedTrees } from "routes/schedule/plugins/used-schedules/related-trees"
import { ScheduleEmbed } from "lib/@components/schedule-embed"
import { WithDemand } from "lib/authorization/secure"

export function RelatedTreeDetails({ schedule }) {
    const [open] = useStoreState(`related_${schedule._id}`, false)

    return (
        <Collapse in={open} mountOnEnter sx={{ width: 1 }}>
            <TreeList schedule={schedule} />
        </Collapse>
    )
}

ScheduleTreeBar.plug(
    <WithDemand demand="manageRegime">
        <ScheduleEmbed>
            <RelatedTrees />
        </ScheduleEmbed>
    </WithDemand>
)

AboveRightBarTabs.plug(
    <ScheduleEmbed>
        <RelatedTreeDetails />
    </ScheduleEmbed>
)
