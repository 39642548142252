import { Link } from "@mui/material"

import { parentFind } from "library/tree"
import { AdjustStack, GetCrumbLink, OverarchingIntroductionLink } from "event-definitions"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { Bound } from "lib/@components/binding/Bound"
import { RouterLink } from "lib/routes/router-link"
import { FacilityPlugs } from "slot-definitions"
import { isFacilityLike } from "routes/schedule/my-schedules/my-group-types/space/is-facility-like"

export function FacilityOverrides({ children }) {
    AdjustStack.useEvent((props) => {
        props.group = props.group.slice(1)
        props.group.unshift(
            <Link key="facilities" component={RouterLink} to="/app/facilities">
                Maintenance Regimes
            </Link>
        )
    })
    GetCrumbLink.useEvent((props) => {
        if (props.item.isSmart) {
            props.link = null
        } else if (props.item[PlanRoot]) {
            props.link = `/app/facilities/${props.item.id}`
        } else if (isFacilityLike(props.item)) {
            props.link = `/app/facilities/facility/${props.item.id}`
        } else if (props.item.data?.type === "space" || props.item.type === "group") {
            console.log("Should link", props.item.label)
            props.link = `/app/facilities/facility/${parentFind(props.item, (i) => isFacilityLike(i))?.id}/${
                props.item.id
            }`
        } else {
            props.link = `/app/facilities/schedule/${props.item.id}`
        }
    })
    OverarchingIntroductionLink.useEvent((props) => {
        props.link = `/app/facilities/schedule/${props.id}`
    })
    return (
        <Bound inFacility={true}>
            <FacilityPlugs.Slot />
            {children}
        </Bound>
    )
}
