import { ExtendedSchedule } from "routes/schedule/extendedSchedule"
import { Bound } from "lib/@components/binding/Bound"
import { Lazy } from "lib/make-lazy"
import { useRef, useState } from "react"
import { ScheduleHolder } from "routes/asset-register/plugins/regime-display/schedule-holder"

export function TaskEditor({ scheduleId, identifier, task, ok }) {
    const [dirty, setDirty] = useState(false)
    const store = useRef()

    return (
        <Bound dirty={dirty} save={store.current} identifier={identifier} ok={ok}>
            <ExtendedSchedule scheduleId={scheduleId} isEdit onConfigureStorage={configure}>
                <ScheduleHolder scheduleId={scheduleId} task={task}>
                    <Lazy importer={() => import("./task-line-editor")} extract="TaskLineEditor" />
                </ScheduleHolder>
            </ExtendedSchedule>
        </Bound>
    )

    function configure(_, save) {
        store.current = save
        return (target) => setDirty(target)
    }
}
