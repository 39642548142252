import { Divider, Stack, Typography } from "@mui/material"
import { Accordion } from "minimals-template/components/accordion"
import { useState } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ListItemBox } from "lib/@components/ListItemBox"
import { MdOutlineChecklist } from "@react-icons/all-files/md/MdOutlineChecklist"
import { AISteps } from "routes/schedule/plugins/ai-steps/a-i-steps"

export function TaskSteps() {
    const [openDetails, setOpenDetails] = useState(false)
    const { schedule, task, schedulePath, currentId } = useBoundContext()
    return (
        schedule &&
        (schedulePath || currentId) && (
            <>
                <Divider sx={{ mt: 1 }} />
                <Accordion
                    open={openDetails}
                    onChange={setOpenDetails}
                    title={
                        <ListItemBox spacing={1}>
                            <MdOutlineChecklist />
                            <Typography>Check List</Typography>
                        </ListItemBox>
                    }
                >
                    <Stack spacing={2} sx={{ mt: 2, pl: 5, pr: 2 }}>
                        <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 500 }}>
                            This check list is a <strong>short summary of the task</strong>, it is{" "}
                            <strong>
                                <em>not the SFG20 task</em>
                            </strong>
                            . This summary may be used to remind individuals performing the task about the steps, but
                            they <strong>must be familiar with the full schedule</strong>. They can access the full
                            schedule by using the SFG20 app and the Smart Words associated with the task.
                        </Typography>
                        <AISteps schedule={schedule} task={task} />
                    </Stack>
                </Accordion>
            </>
        )
    )
}
