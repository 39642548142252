import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"
import { query } from "lib/graphql/query"
import { busyWhile } from "lib/@components/busy"

export const getRecyclerContents = api(async function getRecyclerContents() {
    return query(
        gql`
            query GetRecycledItems {
                getRecyclerContents {
                    _id
                    name
                    icon
                    date
                }
            }
        `,
        {},
        { returns: "getRecyclerContents" }
    )
})

export const restoreRecyclerItem = api(async function restoreRecyclerItem(id) {
    return busyWhile(
        () =>
            mutate(
                gql`
                    mutation RestoreItem($id: String!) {
                        restoreRecyclerItem(id: $id)
                    }
                `,
                { id },
                { returns: "restoreRecyclerItem" }
            ),
        "Restoring item..."
    )
})

export const deleteRecyclerItem = api(async function deleteRecyclerItem(id) {
    return mutate(
        gql`
            mutation DeleteItem($id: String!) {
                deleteRecyclerItem(id: $id)
            }
        `,
        { id },
        { returns: "deleteRecyclerItem" }
    )
})
