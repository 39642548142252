import { appRoute } from "routes/app/home.runtime"
import Schedules from "routes/schedule/schedules"
import Schedule from "routes/schedule/schedule"
import { Bound } from "lib/@components/binding/Bound"
import { FacilityOverrides } from "routes/facilities/facility-overrides"
import { ExtendedSchedule } from "routes/schedule/extendedSchedule"
import { pdfRoute } from "routes/pdf/pdf.runtime"

appRoute.register("facilities/schedule/:id", <FacilitySchedule />)
pdfRoute.register("facilities/schedule/:id", <FacilitySchedule />)

function FacilitySchedule() {
    return (
        <Bound inSelector={true}>
            <FacilityOverrides>
                <Schedules>
                    <ExtendedSchedule>
                        <Schedule />
                    </ExtendedSchedule>
                </Schedules>
            </FacilityOverrides>
        </Bound>
    )
}
