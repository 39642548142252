import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"
import { busyWhileWithDelay } from "lib/@components/busy"

export const summariseTasks = api(({ summarise, filters, regimeId, group, search, sort }) =>
    busyWhileWithDelay(
        () =>
            query(
                gql`
                    query SummariseTasks(
                        $regimeId: String!
                        $group: String
                        $search: String
                        $sort: [String]
                        $summarise: [String!]!
                        $filters: [JSONObject]
                    ) {
                        regimeTaskSummary(
                            regimeId: $regimeId
                            group: $group
                            sort: $sort
                            search: $search
                            filters: $filters
                        ) {
                            summarise(by: $summarise) {
                                summary(take: 10000) {
                                    key
                                    value
                                    count
                                    minutes
                                }
                            }

                            id
                        }
                    }
                `,
                {
                    summarise,
                    regimeId,
                    group,
                    sort,
                    search,
                    filters,
                },
                { returns: "regimeTaskSummary.summarise.summary" }
            ),
        100,

        "Summarising"
    )
)
