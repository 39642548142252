import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"

export const applyChangesToMany = api(({ source, group, regimeId, filters, search }) =>
    mutate(
        gql`
            mutation ApplyChangesToMany(
                $source: String!
                $regimeId: String!
                $group: String
                $filters: [JSONObject]
                $search: String
            ) {
                applyChangesToMany(
                    source: $source
                    regimeId: $regimeId
                    group: $group
                    filters: $filters
                    search: $search
                )
            }
        `,
        { source, group, regimeId, filters, search },
        { returns: "applyChangesToMany" }
    )
)
