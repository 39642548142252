import { ActionItem } from "slot-definitions"
import { Avatar, Button, ListItemAvatar } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { uniqueId } from "library/database/split-id"
import { navigate } from "lib/routes/navigate"
import { confirm } from "lib/@dialogs/confirm"
import { getGroupId } from "routes/schedule/lib/getGroupId"
import { getScheduleRecordId } from "library/get-unique-id-from-tree"
import { closeTailoredReview } from "routes/actions/plugins/review-tailored/controller/close-tailored-review"
import { TruncatedListItemText } from "lib/@components/truncatedListItemText"

ActionItem("review-tailored").plug(<ReviewTailored />)

function ReviewTailored({ action }) {
    return (
        <>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: "myStuff.main", color: "myStuff.contrastText" }}>
                    <Iconify icon={"mdi:file-document-edit-outline"} />
                </Avatar>
            </ListItemAvatar>
            <TruncatedListItemText
                primary={`${action.title}`}
                secondary={`Changes have been made to a schedule which has been tailored.`}
            />
            <Button onClick={goRegime} color="primary" size="small">
                Review
            </Button>
            <Button onClick={approve} color="primary" variant="contained" size="small">
                Done
            </Button>
        </>
    )

    function goRegime() {
        navigate(
            `/app/schedules/${getGroupId(action.payload.id)}~${uniqueId(action.referenceId)}!${getScheduleRecordId(
                action.payload.id
            )}?editEx=true`
        )
    }

    async function approve() {
        if (
            await confirm("Are you sure that all necessary modifications have been made?", "Review Tailored Schedule")
        ) {
            await closeTailoredReview(action._id)
        }
    }
}
