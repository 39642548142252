import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { getPlanId, usePlanFor } from "routes/plan/controller/use-plan"
import { getPublishedPlan, publishPlan } from "routes/plan/controller/publish-plan"
import { Secure } from "lib/authorization/secure"
import { Button } from "@mui/material"
import { busyWhile } from "lib/@components/busy"
import { navigate } from "lib/routes/navigate"
import { uniqueId } from "library/database/split-id"
import { waitForScheduleRefresh } from "routes/schedule/lib/wait-for-schedule-refresh"
import { Frag } from "lib/@components/slot/frag"

export function UpdateDraft() {
    const { parentItem } = useBoundContext()
    const parent = parentFind(parentItem, (c) => c[PlanRoot])
    const id = getPlanId(parent)
    usePlanFor(id)
    const plan = getPublishedPlan.useResults(id, "publish")
    return plan?.plan ? (
        <Secure hide demand="manageRegime" fallback={<Frag />}>
            <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={publish}
                sx={{ mt: 1, mx: "auto", display: "block" }}
            >
                Update Review Version
            </Button>
        </Secure>
    ) : null

    async function publish() {
        return busyWhile(
            async () => {
                await publishPlan(id)
                navigate(`/app/review/${uniqueId(id)}`)
            },
            "Publishing To Draft",
            waitForScheduleRefresh
        )
    }
}
