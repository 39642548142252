import { api } from "lib/graphql/api"
import { hasDemand } from "lib/authorization/has-demand"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getRelatedTrees = api(async function getRelatedTrees({ id, take, skip }) {
    if (!hasDemand("manageRegime")) return null
    return !id
        ? []
        : query(
              gql`
                  query GetRelatedTrees($id: String!, $skip: Int, $take: Int) {
                      getRelatedTrees(id: $id, skip: $skip, take: $take) {
                          count
                          trees {
                              id
                              name
                              count
                              groups {
                                  name
                                  id
                                  groupId
                                  image
                                  tailoredStatus {
                                      reasons
                                      summary
                                  }
                                  icon
                              }
                          }
                      }
                  }
              `,
              { id, take, skip },
              { returns: "getRelatedTrees" }
          )
})
