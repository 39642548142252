import { TreeToolbar } from "slot-definitions"
import { Box, Divider, IconButton, Tooltip } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { UndoRedoChange } from "event-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { UndoLive } from "routes/schedule/undo-portal"

TreeToolbar.plug(
    <UndoLive>
        <Undo priority={10} />
    </UndoLive>
)

export function Undo() {
    const { editMode, redo, canRedo, canUndo, undo } = useBoundContext()
    UndoRedoChange.useRefresh()
    return (
        !!editMode &&
        !!canUndo &&
        !!canRedo && (
            <>
                <Box sx={{ lineHeight: 0 }} mr={1} data-cy="undo-redo-buttons">
                    <Tooltip title="Undo">
                        <span>
                            <IconButton size="small" onClick={undo} color="primary" disabled={!canUndo()}>
                                <Iconify icon="mdi:undo" />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Redo">
                        <span>
                            <IconButton size="small" onClick={redo} color="primary" disabled={!canRedo()}>
                                <Iconify icon="mdi:redo" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Divider orientation="vertical" flexItem />
            </>
        )
    )
}
