import { waitForEvent } from "library/local-events"
import { getLastRenderTime, resetRenderTime } from "lib/render-trap"

window.waitForEvent = async function wait(eventName, timeout) {
    try {
        console.log("Waiting For Event", eventName)
        return await waitForEvent(eventName, timeout)
    } finally {
        console.log("Released Due To Event", eventName)
    }
}

window.waitForStable = (time = 100) =>
    new Promise((resolve) => {
        try {
            let start = Date.now()
            resetRenderTime()
            window.addEventListener("beforeunload", unload)

            check()

            // eslint-disable-next-line no-inner-declarations
            function unload() {
                resolve()
                window.removeEventListener("beforeunload", unload)
            }

            // eslint-disable-next-line no-inner-declarations
            function check() {
                try {
                    if (Date.now() - getLastRenderTime() < time && Date.now() - start < 8000) {
                        setTimeout(check, 200)
                    } else {
                        resolve()
                        window.removeEventListener("beforeunload", unload)
                    }
                } catch (e) {
                    resolve()
                    window.removeEventListener("beforeunload", unload)
                }
            }
        } catch (e) {
            resolve()
        }
    })
