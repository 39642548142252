import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    List,
    ListItemButton,
    Stack,
    Tab,
} from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { getCustomSkillCodes } from "routes/settings/plugins/custom-skills/controller/get-custom-skill-codes"
import { Bound } from "lib/@components/binding/Bound"
import { useRefresh } from "lib/@hooks/useRefresh"
import { BoundBox, BoundTextField, BoundTypography } from "lib/@components/binding/bound-components"
import { MdDelete } from "@react-icons/all-files/md/MdDelete"
import { DataInputDialog } from "lib/@dialogs/data-input-dialog"
import { useDialog } from "lib/@hooks/useDialog"
import { generate } from "library/guid"
import { confirm } from "lib/@dialogs/confirm"
import { BlockClicks } from "lib/@components/block-clicks"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { registerTab } from "lib/@components/tabs"
import { setCustomSkillCode } from "routes/settings/plugins/custom-skills/controller/set-custom-skill-code"
import { deleteCustomSkillCode } from "routes/settings/plugins/custom-skills/controller/delete-custom-skill-code"
import { areSchedulesUsingCode } from "routes/settings/plugins/custom-skills/controller/are-schedules-using-code"
import { hasDemand } from "lib/authorization/has-demand"
import { convertToMinMaxNumber } from "lib/number"
import Iconify from "minimals-template/components/Iconify"
import { convertToUppercase } from "lib/case-helper"
import { ClientPanels, ClientTabs, ScheduleItemCode } from "slot-definitions"
import { TabPanel } from "@mui/lab"
import { invalidate } from "lib/graphql/cache"
import { isAdmin } from "library/authorization"
import { getClient } from "minimals-template/components/contexts/NognitoContext"
import { RelatedSchedules } from "./related-schedules"
import { RelatedTailoredSchedules } from "./related-tailored"
import { RelatedCustomSchedules } from "./related-custom"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { ScheduleCode } from "routes/regime/schedule-code"
import LoadingScreen from "minimals-template/components/LoadingScreen"

registerTab({
    tab: "clientAdmin",
    id: "customskills",
    title: "Custom Skills",
    content: <CustomSkills priority={150} />,
    predicate: () => hasDemand("clientAdmin"),
})

ClientTabs.plug(<Tab label="Custom Skills" value="Custom Skills" />)

ClientPanels.plug(
    <TabPanel value="Custom Skills">
        <CustomSkills priority={150} />
    </TabPanel>
)

export function CustomSkills() {
    ScheduleItemCode.usePlug(
        replaceAllWith(<ScheduleCode sx={{ whiteSpace: "nowrap", minWidth: 50, textAlign: "right" }} />)
    )
    const { clientId: client } = useBoundContext()
    const customSkills = getCustomSkillCodes.useResults(client) ?? []
    const create = useDialog(<SkillCodeDialog />)
    return (
        <>
            <List className="custom-skills">
                <PagedRepeat list={customSkills.sortBy("Skilling")} item={<CustomSkill />} bind />
            </List>
            <Button variant="contained" onClick={add}>
                Add Skill
            </Button>
        </>
    )

    async function add() {
        const skill = await create()
        if (skill) {
            const id = generate()
            await setCustomSkillCode(id, skill, client)
            refreshForAdmin(client)
        }
    }
}

function SkillCodeDialog({ skill }) {
    return (
        <DataInputDialog initialValue={skill} okDisabled={isSkillValid}>
            <DialogTitle>Custom Skill Code</DialogTitle>
            <DialogContent>
                <Stack sx={{ mt: 2 }} spacing={2}>
                    <BoundTextField
                        field="SkillingCode"
                        label="Code"
                        yup="string.trim.max(4).required"
                        transformOut={convertToUppercase}
                    />
                    <BoundTextField field="Skilling" label="Description" yup="string.trim.required" />
                    <BoundTextField
                        field="Rate"
                        label="Hourly Rate"
                        yup="string.trim.required"
                        transformOut={convertToMinMaxNumber(0, 100000000000)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon="uil:pound" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
            </DialogContent>
        </DataInputDialog>
    )
}

function isSkillValid(skill) {
    if (skill) {
        const valid = skill.SkillingCode?.trim().length <= 4 && skill.Skilling && typeof skill.Rate === "number"
        return !valid
    }
    return false
}

function CustomSkill() {
    const { target } = useBoundContext()
    const { clientId: client } = useBoundContext()
    const refresh = useRefresh()
    const edit = useDialog(<SkillCodeDialog skill={{ ...target }} />)
    const { data, loading } = areSchedulesUsingCode.useResults.status(target._id, client)
    if (loading) return <LoadingScreen />
    if (!data) return null
    console.log({ data })
    const { isInUse, tailoredLength, customLength } = data
    return (
        <Bound refresh={refresh}>
            <ListItemButton onClick={modify} className="skill-item">
                <ListItemBox>
                    <BoundBox component="code" mr={1} field="SkillingCode" />
                    <BoundTypography field="Skilling" variant="body2" />
                    <Box flex={1} />
                    {isInUse ? (
                        <RelatedSchedules
                            tailored={tailoredLength}
                            custom={customLength}
                            skillId={target.CoreSkillingID}
                        />
                    ) : null}
                    <BoundTypography
                        transformIn={(v) => (v ? `£ ${v.toFixed(2)} per hour` : "£ 0.00 per hour")}
                        field="Rate"
                        variant="body2"
                    />
                    <BlockClicks>
                        <IconButton disabled={isInUse} color="secondary" onClick={remove}>
                            <MdDelete />
                        </IconButton>
                    </BlockClicks>
                </ListItemBox>
            </ListItemButton>
            {tailoredLength > 0 ? <RelatedTailoredSchedules skillId={target.CoreSkillingID} /> : null}
            {customLength > 0 ? <RelatedCustomSchedules skillId={target.CoreSkillingID} client={client} /> : null}
        </Bound>
    )

    async function modify() {
        const update = await edit()
        if (update) {
            await setCustomSkillCode(
                target._id,
                {
                    Skilling: update.Skilling,
                    SkillingCode: update.SkillingCode,
                    Rate: update.Rate,
                },
                client
            )
            refreshForAdmin(client)
        }
    }

    async function remove() {
        if (await confirm("Are you sure you want to remove the code?", "Remove Skill")) {
            await deleteCustomSkillCode(target._id, client)
            refreshForAdmin(client)
        }
    }
}

function refreshForAdmin(client) {
    const adminManagingClient = !!(isAdmin() && client !== getClient())
    if (adminManagingClient) invalidate("getCustomSkillCodes")
}
