import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getMyDerivedSchedulesCount = api(async function getMyDerivedSchedules(derivedFrom, drafts) {
    return !derivedFrom
        ? 0
        : query(
              gql`
                  query getMyDerivedSchedulesCount($derivedFrom: String!, $drafts: Boolean) {
                      getMyDerivedSchedules(derivedFrom: $derivedFrom, drafts: $drafts) {
                          count
                      }
                  }
              `,
              { derivedFrom, drafts },
              { returns: "getMyDerivedSchedules.count" }
          )
})
