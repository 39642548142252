import { useSave } from "lib/@hooks/use-save"
import { Bound } from "lib/@components/binding/Bound"
import { ActionItem } from "slot-definitions"
import { updateAction } from "routes/actions/controller/update-action"
import { ListItem } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FavoriteAction } from "routes/actions/favorite-action"
import { Silence } from "routes/actions/silence"
import { Created } from "routes/actions/created"
import { DueDate } from "routes/actions/due-date"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { UserIcon } from "lib/user/user-icon"
import { CompletedOn } from "routes/actions/completed-on"

export function ActionEntry({ action, last }) {
    const change = useSave({ save: saveUpdates, target: action, wait: 100 })
    return (
        <Bound action={action} target={action} onChange={change}>
            <ListItem divider={!last} className={`action-item ${action.type}`} data-cy="action-item">
                {action.completedOn ? (
                    <ListItemBox
                        spacing={1}
                        sx={{
                            "& button": {
                                display: "none !important",
                            },
                            width: 1,
                        }}
                    >
                        <ActionItem.Slot type={action.type} action={action} />
                        <CompletedOn />
                        <UserIcon id={action.completedBy.id} />
                    </ListItemBox>
                ) : (
                    <ListItemBox spacing={1}>
                        <FavoriteAction />
                        <ActionItem.Slot type={action.type} action={action} />
                        <Created />
                        <DueDate />
                        <Silence />
                    </ListItemBox>
                )}
            </ListItem>
        </Bound>
    )

    async function saveUpdates(target) {
        try {
            await updateAction(target._id, target.payload)
        } catch (e) {
            errorSnackbar("Failed to save action")
        }
    }
}
