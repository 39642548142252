import { WheelChart } from "lib/@components/wheel-chart"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

export function DueDate() {
    const [created] = useBoundValue("created")
    let [dueDate] = useBoundValue("dueDate")
    dueDate ??= new Date(created).addDays(45)
    const days = new Date().daysUntil(dueDate)
    const ms = new Date().millisecondsUntil(dueDate)
    const totalTimeToDue = new Date(dueDate).millisecondsSince(created)
    const percentage = (Math.max(0, ms) / totalTimeToDue) * 100
    const color = percentage < 8 ? "error.dark" : percentage < 50 ? "warning.main" : "success.main"
    return (
        !!dueDate && (
            <WheelChart
                bgcolor={days < 3 ? "error.light" : undefined}
                color={color}
                percentage={percentage}
                label={`${days} ${"day".pluralize(Math.abs(days))}`}
            />
        )
    )
}
