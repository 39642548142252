import { Stack, TableBody, TableCell, TableRow, Typography, useTheme } from "@mui/material"
import { ActionFor } from "routes/asset-register/plugins/regime-display/action-for"
import { COLOR_FROM_CLASSIFICATION } from "library/SFG20/constants"
import { MemoryWidthTableCell } from "routes/asset-register/plugins/regime-display/memory-width-table-cell"

export function AssetsBodyAndActionRow({ children }) {
    const theme = useTheme()
    return (
        <TableBody>
            <TableRow>
                <MemoryWidthTableCell
                    name="tag"
                    align="right"
                    sx={{
                        minWidth: 200,
                        position: "sticky",
                        left: 0,
                        bgcolor: "background.default",
                        zIndex: 100,
                        boxShadow: "-1px 0px 0px 0px rgba(0,0,0,0.1) inset",
                    }}
                >
                    <Typography color="primary.dark" variant="caption">
                        Filters
                    </Typography>
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="summary">
                    <Stack direction="row" spacing={1}>
                        <Stack>
                            <Typography sx={{ opacity: 0.6 }} color="text.secondary" variant="caption">
                                Red
                            </Typography>
                            <ActionFor
                                field="hasRed"
                                transformValue={convertToTick}
                                color={(_, __, v) =>
                                    v === "true" ? COLOR_FROM_CLASSIFICATION.Red : theme.palette.background.default
                                }
                            />
                        </Stack>
                        <Stack>
                            <Typography sx={{ opacity: 0.6 }} color="text.secondary" variant="caption">
                                Pink
                            </Typography>
                            <ActionFor
                                color={(_, __, v) =>
                                    v === "true" ? COLOR_FROM_CLASSIFICATION.Pink : theme.palette.background.default
                                }
                                field="hasPink"
                                transformValue={convertToTick}
                            />
                        </Stack>
                        <Stack>
                            <Typography sx={{ opacity: 0.6 }} color="text.secondary" variant="caption">
                                Amber
                            </Typography>
                            <ActionFor
                                color={(_, __, v) =>
                                    v === "true" ? COLOR_FROM_CLASSIFICATION.Amber : theme.palette.background.default
                                }
                                field="hasAmber"
                                transformValue={convertToTick}
                            />
                        </Stack>
                        <Stack>
                            <Typography sx={{ opacity: 0.6 }} color="text.secondary" variant="caption">
                                Green
                            </Typography>
                            <ActionFor
                                field="hasGreen"
                                transformValue={convertToTick}
                                color={(_, __, v) =>
                                    v === "true" ? COLOR_FROM_CLASSIFICATION.Green : theme.palette.background.default
                                }
                            />
                        </Stack>
                    </Stack>

                    {/* <SortOn field="score">Summary</SortOn> */}
                </MemoryWidthTableCell>

                <MemoryWidthTableCell name="category">
                    <ActionFor field="category" />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="facility">
                    <ActionFor field="facility" />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="floor">
                    <ActionFor field="floor" />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="space">
                    <ActionFor field="space" />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="type">
                    <ActionFor field="type" />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell>
                    <ActionFor field="schedule.title" />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="system">
                    <ActionFor field="system" />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="manufacturer">
                    <ActionFor field="manufacturer" />
                </MemoryWidthTableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                    align="center"
                    sx={{
                        position: "sticky",
                        right: 0,
                        zIndex: 100,
                        bgcolor: "background.default",
                        boxShadow: "1px 0px 0px 0px rgba(0,0,0,0.1) inset",
                    }}
                ></TableCell>
            </TableRow>
            {children}
        </TableBody>
    )
}

function convertToTick(value) {
    switch (value) {
        case "true":
            return "Yes"
        default:
            return "No"
    }
}
