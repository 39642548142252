import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ListItemButton, Radio, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useReferenceState } from "lib/@hooks/use-reference"

export function FilterLabel({ item, type }) {
    const { target } = useBoundContext()
    const [filters, setFilters] = useReferenceState("asset-filters" + target.id, {})

    const selected = !!filters[type]?.find((t) => t === item)
    return (
        <ListItemButton selected={selected} onClick={set}>
            <ListItemBox spacing={0.5}>
                <Radio size="small" checked={selected} />
                <Typography variant="body2">{item}</Typography>
            </ListItemBox>
        </ListItemButton>
    )

    function set({ shiftKey }) {
        if (shiftKey) {
            setFilters((filters) => {
                filters = { ...filters }

                let current = filters[type] ?? []
                if (current.some((c) => c === item)) {
                    current = current.filter((c) => c !== item)
                } else {
                    current = [...current, item].unique()
                }
                filters[type] = current
                return filters
            })
        } else {
            if (filters[type]?.some((i) => i === item)) {
                if (Object.keys(filters).length > 1 || filters[type]?.length > 1) {
                    setFilters({
                        [type]: [item],
                    })
                } else {
                    setFilters({})
                }
            } else {
                setFilters({
                    [type]: [item],
                })
            }
        }
    }
}
