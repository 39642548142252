import { useStoreState } from "lib/store"
import { Collapse } from "@mui/material"
import { ListOfDetails } from "routes/schedule/plugins/derived-schedules/list-of-details"
import { AboveRightBarTabs } from "slot-definitions"
import { ScheduleEmbed } from "lib/@components/schedule-embed"

export function Details({ schedule }) {
    const [open] = useStoreState(`details_${schedule._id}`, false)
    return (
        <Collapse in={open} mountOnEnter sx={{ width: 1 }}>
            <ListOfDetails schedule={schedule} />
        </Collapse>
    )
}

AboveRightBarTabs.plug(
    <ScheduleEmbed>
        <Details />
    </ScheduleEmbed>
)
