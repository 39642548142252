import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Stack } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { ICON_FROM_IMAGE } from "routes/schedule/plugins/flags/components/constants"
import { BoundBox, BoundTypography } from "lib/@components/binding/bound-components"
import { mapLinks } from "routes/schedule/plugins/flags/components/map-links"

export function Flag() {
    const { target } = useBoundContext()
    return (
        <Stack spacing={1} justifyContent="center" alignItems="center">
            <Iconify sx={{ fontSize: 40 }} icon={ICON_FROM_IMAGE[target.IconFileName]} />
            <BoundTypography field="Name" variant="subtitle2" />
            <BoundBox sx={{ px: 2, fontSize: "85%" }} mt={1} field="Content" transformIn={mapLinks} />
        </Stack>
    )
}
