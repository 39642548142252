import { RefreshSchedule } from "event-definitions"
import { noChange } from "lib/@hooks/useRefresh"
import { SelectorTitleText } from "slot-definitions"
import useAsync from "lib/@hooks/useAsync"
import { getCachedSchedule } from "routes/schedule/lib/get-cached-schedule"
import { VersionInfo } from "routes/schedule/lib/version-info"

SelectorTitleText.plug(<VersionInfo priority={120} />)

export function useSchedule(code, version = "no_v") {
    const key = `${code}:${version}`

    const refresh = RefreshSchedule.useRefresh(noChange)
    return (
        useAsync(() => getCachedSchedule(code, version), undefined, [key, refresh.id]) ?? {
            loading: true,
            error: null,
        }
    )
}
