import { appRoute } from "routes/app/home.runtime"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { Lazy } from "lib/make-lazy"
import { isEnabled } from "lib/@components/feature"
import { FEATURE_FLAG_ASSET_MAPPING } from "routes/asset-register/feature-flag-asset-mapping"

appRoute.register(
    "assets",
    <Lazy importer={() => import("routes/asset-register/asset-registers")} extract={"AssetRegisters"} />
)

appRoute.register(
    "assets/:id",
    <Lazy importer={() => import("routes/asset-register/asset-register")} extract={"AssetRegister"} />
)
appRoute.register("asset/:assetId", <Lazy importer={() => import("routes/asset-register/asset")} extract={"Asset"} />)

appRoute.register(
    "assets/:id/:assetId",
    <Lazy importer={() => import("routes/asset-register/asset")} extract={"Asset"} />
)

registerConditionalPageNavigation(
    () => isEnabled(FEATURE_FLAG_ASSET_MAPPING),
    `/app/assets`,
    "Asset Registers",
    "healthicons:register-book",
    {
        group: "Planning",
        subPaths: true,
    }
)
