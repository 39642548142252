import { isInEditableRegime } from "routes/facilities/lib/when-parent-item"
import { makeLazy } from "lib/make-lazy"
import { and } from "lib/logic"
import { registerTab } from "lib/@components/tabs"
import { REGIME_ASSETS_TAB } from "routes/asset-register/plugins/regime-display/regime-display.runtime"

const TailoringRegimeTab = makeLazy(() => import("./tailoring-regime-tab"), "TailoringRegimeTab")
registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "tailoring",
    title: "Tailored Schedules",
    predicate: and(isInEditableRegime),
    content: <TailoringRegimeTab priority={60} />,
})
