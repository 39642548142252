import { PDFTabs, Tabs } from "lib/@components/tabs"
import { Box, Card } from "@mui/material"
import { AboveRightBarTabs, ScheduleRightBar } from "slot-definitions"

ScheduleRightBar.plug(<RightBarTabs priority={200} if={(c) => !c.isPDF} />)
ScheduleRightBar.plug(<RightBarTabsForPDF priority={200} if={(c) => !!c.isPDF} />)

function RightBarTabs() {
    return (
        <>
            <AboveRightBarTabs.Slot />
            <Card sx={{ mx: 0, my: 1 }}>
                <Tabs
                    id="ScheduleRightBar"
                    tabHolder={<Box sx={{ px: 2, bgcolor: "background.neutral" }} />}
                    panelHolder={<Box sx={{ m: 1 }} />}
                />
            </Card>
        </>
    )
}

function RightBarTabsForPDF() {
    return (
        <PDFTabs
            id="ScheduleRightBar"
            tabHolder={<Box sx={{ px: 2, bgcolor: "background.neutral" }} />}
            panelHolder={<Box sx={{ m: 1 }} />}
        />
    )
}
