import { Box, useTheme } from "@mui/material"
import { useReferenceState } from "lib/@hooks/use-reference"
import { useEffect } from "react"
import { AdvancedFilterChanged, AdvancedFilterSetting } from "event-definitions"

export function SelectableCriticality({ children, criticality }) {
    const theme = useTheme()
    const [selected, setSelected] = useReferenceState("criticality-" + criticality, false, "criticality")
    useEffect(() => {
        AdvancedFilterChanged.raiseOnceDelay(500)
        return () => AdvancedFilterChanged.raiseOnceDelay(500)
    }, [selected])
    AdvancedFilterSetting.useEvent(
        ({ filters }) => {
            if (selected) {
                filters.push({ type: "criticality", criticality })
            }
        },
        [selected]
    )
    return (
        <Box
            sx={{
                cursor: "pointer",
                outline: selected ? "1px solid " + theme.palette.primary.main : undefined,
                borderRadius: 1,
                bgcolor: selected ? theme.palette.primary.lighter + "80" : undefined,
            }}
            onClick={() => setSelected(!selected)}
        >
            {children}
        </Box>
    )
}
