import { Box, CircularProgress, Stack } from "@mui/material"

export function WheelChart({
    children,
    size = 50,
    label,
    percentage,
    bgcolor,
    thickness = 4,
    color = "primary.main",
    sx,
    containerSx = { position: "relative", ml: 2, mr: -1 },
}) {
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ ...containerSx, height: size, width: size, position: "relative" }}
        >
            <Box position="absolute" sx={{ left: -48 }}>
                {children}
            </Box>
            <Box
                sx={{
                    fontSize: "55%",
                    fontWeight: "bold",
                    color: "text.secondary",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    lineHeight: 1,
                    p: 1,
                    maxWidth: 50,
                    textAlign: "center",
                    transform: "translate3d(-50%, -50%, 0)",
                }}
            >
                {label}
            </Box>
            <CircularProgress
                variant="determinate"
                value={percentage}
                size={size}
                thickness={thickness}
                sx={{ color, opacity: 0.8, ...sx }}
            />

            <CircularProgress
                variant="determinate"
                value={100}
                size={size}
                thickness={thickness}
                sx={{ color: bgcolor ?? "grey.50016", position: "absolute", top: 0, left: 0, opacity: 0.48 }}
            />
        </Stack>
    )
}
