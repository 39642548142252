import { List, ListItem, ListItemText } from "@mui/material"

export function FeatureFlagDescription() {
    const descriptionArray = [
        "This features enables clients to grant/remove access to maintenance regimes on a user by user basis.",
        "Users with no access will not be able to view maintenance regimes.",
        "Users with access can view maintenance regimes. The actions that users can perform within a regime is determined by their individual role/demands (can be found on the user roles page).",
        "Access can be set via the new Access button which appears within each regime.",
        "The ability to view the access button is set by an individual demand (Manage Access Settings)",
    ]
    return (
        <List disablePadding>
            {descriptionArray.map((item, index) => (
                <ListItem
                    key={index}
                    sx={{
                        p: 0,
                        pb: 1,
                    }}
                >
                    <ListItemText primary={`\u2022 ${item}`} />
                </ListItem>
            ))}
        </List>
    )
}
