import { SearchBoxUsingSearchParam } from "lib/@components/searchBoxUsingSearchParam"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { ScheduleSelectorList } from "slot-definitions"
import { AdvancedFilterChanged, FilterSchedule, RefreshScheduleSelector } from "event-definitions"
import { useEffectOnSubsequentChange } from "lib/@hooks/use-effect-on-subsequent-change"
import { isInRegime } from "routes/facilities/lib/when-parent-item"

export function FilterView({ sx }) {
    let [search] = useSearchParam("search", "")
    search = search.toLowerCase()
    FilterSchedule.useEvent(filter, [search])
    useEffectOnSubsequentChange(() => {
        console.log("Search changed", search)
        AdvancedFilterChanged.raiseOnce()
        RefreshScheduleSelector.raiseOnce()
    }, [search])
    return <SearchBoxUsingSearchParam placeholder="Filter..." sx={sx} />

    function filter({ items }) {
        return {
            items: items
                .filter(Boolean)
                .filter(
                    (item) => item.label?.toLowerCase().includes(search) || item.code?.toLowerCase().startsWith(search)
                ),
        }
    }
}

ScheduleSelectorList("*").plug(
    <FilterView
        if={(context) =>
            !isInRegime(context) &&
            context.parentItem.id !== "schedules" &&
            !context.inSelector &&
            !context.parentItem.id.startsWith("my")
        }
        priority={1}
    />
)
