import { DataInputDialog } from "lib/@dialogs/data-input-dialog"
import { generate } from "library/guid"
import { DialogContent, DialogTitle, Grid, Stack } from "@mui/material"
import { BoundAutocomplete, BoundTextField } from "lib/@components/binding/bound-components"
import { BoundPictureListEditor } from "lib/@components/document-viewer/picture-list-editor"
import { uniqueValues } from "routes/asset-register/controller/uniqueValues"
import { Bound } from "lib/@components/binding/Bound"

const NewAsset = Symbol("NewAsset")

export function EditAssetDialog({ register, source = { id: generate(), [NewAsset]: true } }) {
    const {
        systems = [],
        categories = [],
        types = [],
        floors = [],
        facilities = [],
        spaces = [],
        manufacturers = [],
    } = uniqueValues.useResultsOnce(register) ?? {}
    return (
        <Bound hideDescription>
            <DataInputDialog initialValue={{ ...source }}>
                <DialogTitle>{source?.[NewAsset] ? "Create Asset" : "Edit Asset"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Stack spacing={1} sx={{ pt: 2 }}>
                                <BoundTextField autoFocus yup="string.required" field="name" />
                                <BoundTextField field="tag" />
                                <BoundTextField field="description" />
                                <BoundAutocomplete freeSolo options={categories} field="category" />
                                <BoundAutocomplete freeSolo options={systems} field="system" />
                                <BoundAutocomplete freeSolo options={facilities} field="facility" />
                                <BoundAutocomplete freeSolo options={floors} field="floor" />
                                <BoundAutocomplete freeSolo options={spaces} field="space" />
                            </Stack>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Stack spacing={1} sx={{ pt: 2 }}>
                                <BoundAutocomplete freeSolo options={types} field="type" />
                                <BoundTextField field="barcode" />
                                <BoundTextField field="uniclass" />
                                <BoundTextField field="nrm" />
                                <BoundAutocomplete freeSolo options={manufacturers} field="manufacturer" />
                                <BoundTextField field="model" />
                                <BoundTextField field="serialNumber" />
                                <BoundPictureListEditor field="picture" />
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            </DataInputDialog>
        </Bound>
    )
}
