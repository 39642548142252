import { gql } from "@apollo/client"
import useAsync from "lib/@hooks/useAsync"
import { convertFolderName } from "routes/schedule/folder-manager/folder-management"
import { ClientTreeUpdated } from "event-definitions"
import { query } from "lib/graphql/query"

export function assetProfileStats(group) {
    if (!group) return null

    return query(
        gql`
            query getModelStatsAll($group: String!) {
                getModelStats(group: $group) {
                    all {
                        id
                        code
                        title
                        where
                        count
                        annualMinutes
                        periods {
                            interval
                            period
                            frequency
                            count
                            minutes
                            annualMinutes
                            measurements {
                                classification
                            }
                        }
                    }
                    periods {
                        interval
                        period
                        frequency
                    }
                }
            }
        `,
        { group: convertFolderName(group) },
        { returns: "getModelStats" }
    )
}

export function useAssetProfileStats(group, deps = []) {
    const { id } = ClientTreeUpdated.useRefresh()
    return useAsync(async () => assetProfileStats(group), null, [id, group, ...deps])
}
