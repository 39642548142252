import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"
import { busyWhileWithDelay } from "lib/@components/busy"

export const regimeTasks = api(({ regimeId, filters = [], group, search, sort, skip = 0, take = 10 }) =>
    busyWhileWithDelay(
        () =>
            query(
                gql`
                    query RegimeTaskSummary(
                        $regimeId: String!
                        $group: String
                        $search: String
                        $sort: [String]
                        $skip: Int
                        $take: Int
                        $filters: [JSONObject]
                    ) {
                        regimeTaskSummary(
                            regimeId: $regimeId
                            group: $group
                            sort: $sort
                            search: $search
                            filters: $filters
                        ) {
                            id
                            tasks {
                                total
                                items(take: $take, skip: $skip) {
                                    id
                                    fullId
                                    title
                                    assetId
                                    classification
                                    frequency {
                                        label
                                    }
                                    where
                                    minutes
                                    skill
                                    schedule {
                                        id
                                        _id
                                        version
                                        code
                                        title
                                    }
                                    assets
                                }
                            }
                        }
                    }
                `,
                {
                    regimeId,
                    group,
                    sort,
                    skip,
                    take,
                    search,
                    filters,
                },

                { returns: "regimeTaskSummary.tasks" }
            ),
        100,
        "Retrieving Tasks"
    )
)
