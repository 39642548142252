import { useStoreState } from "lib/store"
import { getRelatedTreeCount } from "routes/schedule/plugins/used-schedules/controller/get-related-tree-count"
import { Box, Button, Stack, Tooltip } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { useEffect } from "react"
import { BlockClicks } from "lib/@components/block-clicks"
import { OutlinedBadge } from "lib/@components/outlined-badge"

export function RelatedTrees({ schedule }) {
    const [open, setOpen] = useStoreState(`related_${schedule?._id}`, false)
    const count = getRelatedTreeCount.useResults(schedule?._id)
    useEffect(() => {
        if (!count && open) setOpen(false)
    }, [count, open, setOpen])

    return (
        !!schedule &&
        !!count && (
            <BlockClicks>
                <Tooltip title="Maintenance regimes that use this schedule" arrow>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        role="button"
                        component={Button}
                        aria-label="Related regimes"
                        sx={{ cursor: "pointer", color: "text.primary" }}
                        onClick={toggle}
                    >
                        <Iconify icon="pajamas:issue-type-maintenance" />
                        <OutlinedBadge content={count} />
                        <Box
                            sx={{
                                fontSize: 24,
                                lineHeight: 0,
                                transition: "transform linear 0.3s",
                                transform: `rotate(${open ? -180 : 0}deg)`,
                            }}
                        >
                            <Iconify icon="mdi:arrow-down-drop" />
                        </Box>
                    </Stack>
                </Tooltip>
            </BlockClicks>
        )
    )

    function toggle() {
        setOpen((o) => !o)
    }
}
