import { useUserMetadata } from "lib/store/user"
import { Box } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { setFavourites } from "plugins/user/controller/set-favourites"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

export function FavoriteAction() {
    const [id] = useBoundValue("_id")
    const { favourites = [] } = useUserMetadata()
    const isFavourite = favourites.includes(id)
    const icon = isFavourite ? "ic:round-star" : "ic:round-star-border"
    return (
        <Box
            className={`favourite-action ${isFavourite ? "favourite" : "not-favourite"}`}
            sx={{ fontSize: 24, cursor: "pointer", lineHeight: 0, color: isFavourite ? "gold" : undefined }}
            role="button"
            aria-label="Favourite"
            onClick={toggle}
        >
            <Iconify icon={icon} />
        </Box>
    )

    async function toggle() {
        if (isFavourite) {
            await setFavourites(favourites.filter((f) => f !== id))
        } else {
            await setFavourites([...favourites, id])
        }
    }
}
