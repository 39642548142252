import { BasketTopBar, TagTopBar } from "slot-definitions"
import { Button } from "@mui/material"
import { findBasket } from "routes/schedule/plugins/baskets/find-basket"
import { confirm } from "lib/@dialogs/confirm"
import { parentTreeItem } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { makeBusyFunction } from "lib/@components/busy"
import { deleteBasket } from "routes/schedule/plugins/baskets/controller/deleteBasket"
import { waitForScheduleRefresh } from "routes/schedule/lib/wait-for-schedule-refresh"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

BasketTopBar.plug(<DeleteBasket caption="Remove List" priority={200} />)
TagTopBar.plug(<DeleteBasket caption="Remove Tag" priority={200} />)

function DeleteBasket({ caption }) {
    const { parentId } = useBoundContext()
    const basket = findBasket(parentId)
    const schedules = basket?.children ?? []
    if (basket && schedules.length === 0) {
        return (
            <Button variant="contained" color="secondary" onClick={remove}>
                {caption}
            </Button>
        )
    }
    return null

    async function remove() {
        if (await confirm("Are you sure?", caption)) {
            await makeBusyFunction(() => deleteBasket(parentId), "Deleting", waitForScheduleRefresh)()
            navigate(`/app/schedules?id=${basket[parentTreeItem].id}`)
        }
    }
}
