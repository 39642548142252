import { busyWhile } from "lib/@components/busy"
import { gql } from "@apollo/client"
import { getClient } from "minimals-template/components/contexts/NognitoContext"
import { clone } from "lib/clone"
import { getTreeId } from "routes/schedule/lib/getTreeId"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { mutate } from "lib/graphql/mutate"
import { query } from "lib/graphql/query"

export async function storeGroupData(id, data, name, tree) {
    await busyWhile(async () => saveGroupData(id, data, name, tree ?? getTreeId(id)), "Updating...")
}

export async function saveGroupData(id, data, name, tree) {
    return mutate(
        gql`
            mutation setFacilityData($tree: String!, $group: String!, $data: JSONObject, $name: String) {
                setGroup(tree: $tree, group: $group, data: $data, label: $name)
            }
        `,
        {
            tree: tree ?? getTreeId(id) ?? getTreeIdFromItem(data) ?? getClient(),
            group: id,
            data,
            name,
        }
    )
}

export async function getGroup(id) {
    return clone(
        await query(
            gql`
                query getGroup($tree: String!, $group: String!) {
                    getGroup(tree: $tree, group: $group)
                }
            `,
            { tree: getTreeId(id) ?? getClient(), group: id },
            { returns: "getGroup" }
        )
    )
}
