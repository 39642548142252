import { Box, ListItemButton, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"
import { navigate } from "lib/routes/navigate"
import { uniqueId } from "library/database/split-id"
import { Selected } from "routes/schedule/components/selected"
import { Bound } from "lib/@components/binding/Bound"

export function DerivedItem({ item, last }) {
    const isSchedule = item.itemType === "schedule"
    return (
        <Bound target={item}>
            <ListItemButton divider={!last} onClick={go}>
                <ListItemBox spacing={1}>
                    <Selected size="small" id={uniqueId(item._id)} />
                    <Iconify
                        sx={{ color: !isSchedule && "primary.main" }}
                        icon={isSchedule ? "mdi:file-document-outline" : "mdi:file-document-edit-outline"}
                    />
                    <Box flex={1}>
                        <Typography variant="body2">{item.title}</Typography>
                    </Box>
                    <Typography variant="caption">{item.code}</Typography>
                </ListItemBox>
            </ListItemButton>
        </Bound>
    )

    function go() {
        if (isSchedule) {
            navigate(`/app/schedules/${uniqueId(item._id)}`)
        } else {
            navigate(`/app/schedules/allDrafts!${uniqueId(item._id)}/edit`)
        }
    }
}
