import { useBoundContext } from "lib/@components/binding/use-bound-context"
import {
    Paper,
    Stack,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    Button,
    TableRow,
    TablePagination,
} from "@mui/material"
import { Head } from "./table-head"
import { NoResults } from "lib/@components/no-results/no-results"
import { useEffect, useState } from "react"
import { MdFileDownload } from "@react-icons/all-files/md/MdFileDownload"
import { downloadDocX } from "../../helpers/download-docx"
import { SortButton } from "../sort-btn"
import { clone } from "lodash"

export function SchedulesTable() {
    const { target } = useBoundContext()
    const [schedules, setSchedules] = useState([])
    const [isSchedules, setIsSchedules] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [isSorted, setIsSorted] = useState(true)

    const copyOfSchedules = clone(target)

    useEffect(() => {
        setSchedules(target)
        setIsSorted(true)
    }, [target])

    useEffect(() => {
        if (schedules !== undefined && schedules !== null && schedules?.edges?.length) {
            setIsSchedules(true)
        } else {
            setIsSchedules(false)
        }
    }, [schedules])

    if (!isSchedules) {
        return <NoResults />
    }

    return (
        <Stack>
            <SortButton isSortDesc={isSorted} handleSort={handleSort} />
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <Head />
                    <TableBody>
                        {schedules !== undefined &&
                            schedules.edges.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((s) => {
                                const { approved, id } = s?.node ?? {}
                                const schedulesToUse = approved
                                schedulesToUse["id"] = id

                                return (
                                    <TableRow
                                        key={schedulesToUse?.id}
                                        data-cy={`scheduleTableRow-scheduleId-${schedulesToUse?.id}`}
                                    >
                                        <TableCell data-cy={"scheduleIdCell"}>{schedulesToUse?.id}</TableCell>
                                        <TableCell data-cy={"scheduleTitleCell"}>{schedulesToUse?.title}</TableCell>
                                        <TableCell data-cy={"scheduleCodeCell"}>
                                            {schedulesToUse?.code} (version: {schedulesToUse?.version})
                                        </TableCell>
                                        <TableCell data-cy={"scheduleDownloadCell"}>
                                            <Button
                                                startIcon={<MdFileDownload />}
                                                onClick={() => doDownload(schedulesToUse)}
                                            >
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={schedules?.edges?.length || 0}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Stack>
    )

    //JK: event is needed
    function handleChangePage(event, newPage) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value))
        setPage(0)
    }

    async function doDownload(data) {
        downloadDocX(data)
    }

    function handleSort() {
        const schedulesToSort = [...schedules.edges]
        let sortByVersion
        if (isSorted) {
            sortByVersion = schedulesToSort.sort((a, b) => b.node.approved.version - a.node.approved.version)
            setSchedules({ ...schedules, edges: sortByVersion })
            setIsSorted((p) => !p)
        } else {
            setSchedules(copyOfSchedules)
            setIsSorted((p) => !p)
        }
    }
}
