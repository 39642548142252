import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useReferenceState } from "lib/@hooks/use-reference"
import { Box, Button, ButtonGroup, IconButton, InputAdornment, Stack, Typography } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { summariseAssets } from "routes/asset-register/plugins/regime-display/controller/summarise-assets"
import { SetAlike } from "library/map-plus"
import { PopupBox } from "lib/@components/popup-box"
import { useState } from "react"
import { BlockClicks } from "lib/@components/block-clicks"
import { ListItemBox } from "lib/@components/ListItemBox"
import { StandardSelector } from "lib/@components/combos/standard-selector"
import { DebouncedTextField } from "lib/@components/debounced-text-field"
import { setFromEvent } from "lib/setFromEvent"
import { compareStrings } from "library/compare-strings"
import { MdClear } from "@react-icons/all-files/md/MdClear"
import { ActionItem } from "routes/asset-register/plugins/regime-display/action-item"
import { createSelectionFromItem } from "routes/asset-register/plugins/regime-display/create-selection-from-item"
import { ColumnPie } from "routes/asset-register/plugins/regime-display/column-pie"
import { Bound } from "lib/@components/binding/Bound"
import { selectionColor } from "routes/asset-register/plugins/regime-display/selection-color"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

const actionSortOptions = [
    { value: "name", label: "Value" },
    { value: "count", label: "Count" },
]

export function ActionFor({ field, transformValue = (v) => v, method = summariseAssets, color = selectionColor }) {
    const { regimeId, group, filterKey = "task_filters" } = useBoundContext()
    const [sort, setSort] = useReferenceState("task-view-sort", "name")
    const [filters, setFilters] = useBoundValue(filterKey, [])
    const all = method.useResults({
        regimeId,
        group,
        summarise: [field],
        filters: filters.filter((f) => !f[field]),
    })
    const [search, setSearch] = useState("")

    const total = all?.reduce((a, c) => a + c.minutes, 0) ?? Infinity
    const list =
        all
            ?.sortBy(sort === "name" ? "value[0]" : "count", sort !== "name")
            .filter((s) => !!s.value[0] && (!search || compareStrings(s.value[0], search))) ?? []
    const myFilters = new SetAlike(list.map(createSelectionFromItem))
    const selected = filters.filter((f) => myFilters.has(f))
    const [editFilters, setEditFilters] = useState([])
    const currentFilters = new SetAlike(editFilters)

    return (
        <Bound transformValue={transformValue} currentFilters={currentFilters} selectionColor={color}>
            <PopupBox
                onOpen={open}
                onClose={close}
                hidePopup={list.length < 2 && !search}
                sx={{ whiteSpace: "initial" }}
                popup={
                    <BlockClicks>
                        <Stack
                            sx={{ px: 1, maxHeight: "50vh", minWidth: "30vw", fontSize: 13 }}
                            overflow="hidden"
                            spacing={1}
                        >
                            <ListItemBox spacing={1}>
                                <ButtonGroup size="small">
                                    <Button onClick={() => setEditFilters(list.map(createSelectionFromItem))}>
                                        All
                                    </Button>
                                    <Button onClick={() => setEditFilters(filters.filter((v) => !v[field]))}>
                                        None
                                    </Button>
                                </ButtonGroup>
                                <Box flex={1} sx={{ "& input": { fontSize: 13 } }}>
                                    <DebouncedTextField
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton size="small" onClick={() => setSearch("")}>
                                                        <MdClear />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        autoFocus
                                        fullWidth
                                        variant="standard"
                                        placeholder="Filter..."
                                        value={search}
                                        onChange={setFromEvent(setSearch)}
                                    />
                                </Box>
                                <StandardSelector options={actionSortOptions} value={sort} onChange={setSort} />
                            </ListItemBox>
                            <Box flex={1} overflow="auto" sx={{ fontSize: 13, minHeight: 32 }}>
                                <ColumnPie list={all} onClick={chooseItem} />
                                <Repeat
                                    list={list}
                                    item={
                                        <ActionItem filters={editFilters} setFilters={setEditFilters} total={total} />
                                    }
                                />
                            </Box>
                        </Stack>
                    </BlockClicks>
                }
            >
                <Typography variant="caption" color="primary.main">
                    {selected?.length
                        ? `${selected
                              .slice(0, 4)
                              .map((v) => transformValue(Object.values(v)[0]))
                              .unique()
                              .join(", ")} ${selected.length > 4 ? ` +${selected.length - 4} more` : ""}`
                        : list.length >= 2
                          ? "All"
                          : "-"}
                </Typography>
            </PopupBox>
        </Bound>
    )

    function chooseItem(index) {
        const filter = createSelectionFromItem(list[index])
        if (!currentFilters.has(filter)) {
            setEditFilters((filters) => [...filters, filter])
        } else {
            setEditFilters((filters) => filters.filter((f) => !Object.isEqual(f, filter)))
        }
    }

    function open() {
        const newFilters = filters.filter((f) => !!f[field])
        setEditFilters(newFilters)
    }

    function close() {
        setFilters((filters) => [
            ...filters.filter((f) => !f[field]),
            ...editFilters.unique((c) => Object.values(c)[0]),
        ])
    }
}
