import { AssetAvailableSchedule, ScheduleItemMainExtra } from "slot-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getScheduleSummary } from "routes/schedule/plugins/ai-summary/controller/get-schedule-summary"
import { Box, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { BlockClicks } from "lib/@components/block-clicks"
import { isEnabled } from "lib/@components/feature"

import { FEATURE_FLAG_ASSET_MAPPING } from "routes/asset-register/feature-flag-asset-mapping"
import { Bound } from "lib/@components/binding/Bound"
import { StyledToolTip } from "lib/@components/styled-tool-tip"

ScheduleItemMainExtra.plug(<Summary if={isEnabled(FEATURE_FLAG_ASSET_MAPPING)} priority={500} />)
AssetAvailableSchedule.plug(<Summary if={isEnabled(FEATURE_FLAG_ASSET_MAPPING)} priority={190} />)

export function ScheduleSummary({ id, ...props }) {
    return (
        <Bound schedule={{ _id: id }}>
            <Summary {...props} />
        </Bound>
    )
}

function Summary({ placement = "right" }) {
    const { schedule } = useBoundContext()
    const summary = getScheduleSummary.useResults(schedule._id)

    if (summary) {
        return (
            <StyledToolTip
                arrow
                placement={placement}
                enterNextDelay={300}
                title={
                    <Typography sx={{ lineHeight: 1.1 }} variant="body2">
                        {summary}
                    </Typography>
                }
                enterDelay={300}
            >
                <BlockClicks>
                    <Box sx={{ ml: 1, color: "primary.main", lineHeight: 0, fontSize: 18 }}>
                        <Iconify icon="material-symbols:info-outline" />
                    </Box>
                </BlockClicks>
            </StyledToolTip>
        )
    }
    return null
}
