import React from "react"

export function Frag({ children, ...props }) {
    if (Array.isArray(children))
        return children.map((child, index) => (
            <Frag key={child.key ?? index} {...props}>
                {child}
            </Frag>
        ))
    if (!children) return null
    if (!children.type) return children

    return <children.type {...children.props} {...props} />
}
