import { busyWhile } from "lib/@components/busy"
import { gql } from "@apollo/client"
import { convertFolderName } from "routes/schedule/folder-manager/folder-management"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { mutate } from "lib/graphql/mutate"
import { errorSnackbar } from "lib/snackbar/error-snackbar"

export async function createSubFolder(parentItem, name, data = {}) {
    if (typeof name === "object") {
        Object.assign(data, name)
    }
    const { image } = name
    const { icon } = name
    name = name.name ?? name
    if (image || icon) {
        data = { type: "simple", ...data, image, icon }
    }

    try {
        return await busyWhile(
            async () =>
                mutate(
                    gql`
                        mutation addFolderX($tree: String!, $group: String!, $label: String!, $data: JSONObject) {
                            addGroupToTree(group: $group, tree: $tree, label: $label, data: $data)
                        }
                    `,
                    {
                        tree: getTreeIdFromItem(parentItem),
                        group: convertFolderName(parentItem.id),
                        label: name,
                        data,
                    },
                    {
                        returns: "addGroupToTree",
                    }
                ),
            "Adding Group"
        )
    } catch (e) {
        errorSnackbar(e.message)
        return e
    }
}
