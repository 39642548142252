import { addTempBusy } from "lib/@components/busy"
import { appRoute } from "routes/app/home.runtime"
import { Recycler } from "routes/schedule/plugins/recycle-bin/components/recycler"
import { captureNow } from "FrozenRouter"
import { hasDemand } from "lib/authorization/has-demand"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import DemandGuard from "minimals-template/components/guards/DemandGuard"

// HeaderBarRight.plug(<RecyclerButton priority={10} />)
function canManage() {
    return hasDemand("manageRegime") || hasDemand("scheduleEditor")
}

registerConditionalPageNavigation(
    () => hasDemand("user") && hasDemand("!sharing") && hasDemand("schedules") && canManage(),
    "/app/recycler",
    "Recycle Bin",
    "fluent-mdl2:remove-from-trash",
    {
        group: "More",
        onClick: captureNow(addTempBusy),
        priority: 580,
    }
)

// function RecyclerButton() {
//     RefreshNavBar.useRefresh()
//     return (
//         <Tooltip title="Recycle Bin">
//             <IconButtonAnimate
//                 data-testid="RecycleButton"
//                 onClick={go}
//                 color={window.location.pathname.endsWith("recycler") ? "primary" : undefined}
//             >
//                 <Iconify sx={{ position: "relative", top: -2 }} icon="fluent-mdl2:remove-from-trash" />
//             </IconButtonAnimate>
//         </Tooltip>
//     )
//
//     function go() {
//         navigate(`/app/recycler`)
//     }
// }

appRoute.register(
    "recycler",
    <DemandGuard demands={["manageRegime", "scheduleEditor"]}>
        <Recycler />
    </DemandGuard>
)
