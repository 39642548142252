import { Button, Menu, Portal } from "@mui/material"
import { useRef, useState } from "react"

export function PopupButton({ content, children, ...props }) {
    const [anchor, setAnchor] = useState(null)
    const ref = useRef()
    return (
        <>
            <Button
                {...props}
                sx={{
                    "& .MuiButton-endIcon": {
                        transform: anchor ? "rotate(180deg)" : undefined,
                    },
                }}
                ref={ref}
                onClick={open}
            >
                {content}
            </Button>
            {!!anchor && (
                <Portal>
                    <Menu onClick={close} open={!!anchor} anchorEl={anchor} onClose={close}>
                        {children}
                    </Menu>
                </Portal>
            )}
        </>
    )

    function close() {
        console.log("close")
        setAnchor(null)
    }

    function open() {
        setAnchor(ref.current)
    }
}
