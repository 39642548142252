import { useUserMetadata } from "lib/store/user"
import { Box } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { setFavourites } from "plugins/user/controller/set-favourites"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

export function Silence() {
    const [actionId] = useBoundValue("_id")
    const id = `-${actionId}`
    const { favourites = [] } = useUserMetadata()
    const isSilent = favourites.includes(id)
    const icon = !isSilent ? "mdi:bell" : "mdi:bell-off"
    return (
        <Box
            className="silence-action"
            sx={{ fontSize: 18, cursor: "pointer", lineHeight: 0, color: isSilent ? "error.dark" : "primary.main" }}
            role="button"
            aria-label="Silence"
            onClick={toggle}
        >
            <Iconify icon={icon} />
        </Box>
    )

    async function toggle() {
        if (isSilent) {
            await setFavourites(favourites.filter((f) => f !== id))
        } else {
            await setFavourites([...favourites, id])
        }
    }
}
