import { useCallback, useEffect, useRef } from "react"
import noop from "lib/noop"
import { Box } from "@mui/material"

const scrolls = {}

/**
 * Custom hook for restoring scroll position based on a unique key. The hook returns a callback function
 * that should be set on the JSX element's ref attribute to manage scroll restoration.
 *
 * @param {string} [key=window.location.href] - A unique key to identify the scroll position, defaults to current URL.
 * @param {number} [timeout=500] - A timeout after which the scroll will not be restored, defaults to 1/2 a second.
 * @returns {Function} A callback function to set as the `ref` on a scrollable JSX element.
 *
 * @example
 * const scrollRef = useScrollRestoration();
 * return <div ref={scrollRef}>Your Content Here</div>;
 */ export function useScrollRestoration(key = window.location.href, timeout = 1500) {
    const handler = useRef(noop)
    const cleanUp = useRef(noop)

    useEffect(() => disconnect, [])
    return useCallback(connect, [key, timeout])

    function connect(ref) {
        disconnect()
        if (ref) {
            ref.addEventListener("scroll", store)
            handler.current = () => ref.removeEventListener("scroll", store)
            const scrollInfo = scrolls[key]
            if (scrollInfo) {
                ref.scrollTop = scrollInfo.top

                ref.scrollLeft = scrollInfo.left
                const resizeObserver = new ResizeObserver(() => {
                    if (ref.scrollHeight >= scrollInfo.top && ref.scrollWidth >= scrollInfo.left) {
                        ref.scrollTop = scrollInfo.top
                        ref.scrollLeft = scrollInfo.left
                        setTimeout(() => {
                            ref.scrollTop = scrollInfo.top
                            ref.scrollLeft = scrollInfo.left
                        }, 50)
                        setTimeout(() => {
                            ref.scrollTop = scrollInfo.top
                            ref.scrollLeft = scrollInfo.left
                        }, 100)
                        setTimeout(() => {
                            ref.scrollTop = scrollInfo.top
                            ref.scrollLeft = scrollInfo.left
                        }, 350)
                        cleanUp.current()
                    }
                })
                setTimeout(() => cleanUp.current(), timeout)

                resizeObserver.observe(ref)
                cleanUp.current = () => {
                    resizeObserver.unobserve(ref)
                    cleanUp.current = noop
                }
            } else {
                ref.scrollTop = 0
                ref.scrollLeft = 0
            }
        }

        function store() {
            scrolls[key] = { top: ref.scrollTop, left: ref.scrollLeft }
        }
    }

    function disconnect() {
        handler.current()
        cleanUp.current()
    }
}

export function RestoreScrollBox({ children, scrollKey, sx, ...props }) {
    const scroller = useScrollRestoration(scrollKey)
    return (
        <Box ref={scroller} {...props} sx={{ overflow: "auto", sx }}>
            {children}
        </Box>
    )
}
