import { useParams } from "react-router-dom"
import { getTreeId } from "routes/schedule/lib/getTreeId"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import useSettings from "minimals-template/components/@hooks/useSettings"
import { ScheduleItemCode } from "slot-definitions"
import { ScheduleCode } from "routes/regime/schedule-code"
import Schedules from "routes/schedule/schedules"
import { Bound } from "lib/@components/binding/Bound"
import Page from "minimals-template/components/Page"
import { Box, Container, Grid } from "@mui/material"
import { UpBar } from "routes/schedule/components/up-bar"
import { AdaptItemForFacilities } from "event-definitions"
import { moveScheduleToGroup } from "routes/schedule/lib/move-schedule-to-group"
import { Details } from "routes/facilities/details"
import { navigate } from "lib/routes/navigate"
import { FacilityCompleteCard } from "routes/schedule/my-schedules/my-group-types/facility/facilityCompleteCard"
import { OneCard } from "lib/@components/one-card"
import { useFacilities } from "./lib/use-facilities"
import { NoResults } from "lib/@components/no-results/no-results"
import LoadingScreen from "minimals-template/components/LoadingScreen"

export function Facility() {
    const { id } = useParams()
    const treeId = getTreeId(id)
    const tree = useTreeItem(treeId)
    const { themeStretch } = useSettings()

    const facilities = useFacilities(tree)
    const facility = facilities?.find((f) => f.id === id)
    const parentItem = useTreeItem(id)
    ScheduleItemCode.usePlug(<ScheduleCode />)

    if (!tree?.isLoaded) return <LoadingScreen />

    return facility ? (
        <Schedules>
            <Bound
                parentItem={parentItem}
                onClickSchedule={open}
                transformScheduleItem={adaptChild}
                target={facility}
                facility={facility}
            >
                <Page title={facility.label}>
                    <Container
                        maxWidth={themeStretch ? false : "xl"}
                        sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
                    >
                        <Container maxWidth="lg">
                            <UpBar id={id} />
                            <Box mb={1} />
                        </Container>
                        <Details />
                    </Container>
                </Page>
            </Bound>
        </Schedules>
    ) : (
        <NoResults />
    )

    function adaptChild(child, item) {
        const { output } = AdaptItemForFacilities(item?.data?.type ?? "default").call({
            child,
            item,
            output: (
                <Grid item xs={12}>
                    {child}
                </Grid>
            ),
        })

        return output
    }

    function open(item) {
        navigate(
            `/app/facilities/schedule/${moveScheduleToGroup(item.id, id)}${item.version ? `?version=${item.version}` : ""}`
        )
    }
}

export function FacilityPDF() {
    const { id } = useParams()
    const treeId = getTreeId(id)
    const tree = useTreeItem(treeId)

    const facilities = useFacilities(tree)
    const facility = facilities?.find((f) => f.id === id)
    const parentItem = useTreeItem(id)

    if (!facility || !parentItem) return null

    return (
        <Bound parentItem={parentItem} facility={facility}>
            <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                <OneCard>
                    <FacilityCompleteCard facility={facility.data} hideMenu={true} />
                </OneCard>
                {facility?.data?.description && (
                    <Box mt={4} dangerouslySetInnerHTML={{ __html: facility.data.description }} />
                )}
            </Container>
        </Bound>
    )
}
