import { HomePageTile } from "slot-definitions"
import AppWelcome from "minimals-template/pages/sections/@dashboard/general/app/AppWelcome"
import { Box, Button, Grid, Typography } from "@mui/material"
import { useReferenceState } from "lib/@hooks/use-reference"
import { hasDemand } from "lib/authorization/has-demand"

import { ReactComponent as FacilitiesLogo } from "routes/@assets/Icon - Facilities-iQ.svg"
import { ListItemBox } from "lib/@components/ListItemBox"

HomePageTile.plug(<Welcome if={() => hasDemand("$!sharing")} priority={10} />)

function Welcome() {
    const [hide, setHide] = useReferenceState("hideWelcomeBars", false)
    return !hide ? (
        <Grid item xs={12}>
            <AppWelcome
                title={
                    <Typography variant="h4" component="h1">
                        Welcome to SFG20 Facilities-iQ
                    </Typography>
                }
                img={
                    <Box position="relative" flexGrow={1} width={150} height={160}>
                        <Box position="absolute" right={20} width={150} zIndex={200} opacity={0.5}>
                            <FacilitiesLogo />
                        </Box>
                    </Box>
                }
                action={
                    <Button onClick={() => setHide(true)} variant="outlined">
                        Hide this message
                    </Button>
                }
                description={
                    <>
                        <Box>
                            The Facilities-iQ software lets you craft the perfect maintenance plan for your business.
                            You can use our industry schedules, tailor them for specific situations, or create and
                            manage your own.
                        </Box>
                        <Box mt={1}>
                            Our software lets you understand how up-to-date you are with your plans and instantly assess
                            the compliance of your buildings.
                        </Box>
                    </>
                }
            />
        </Grid>
    ) : (
        <Grid item xs={12}>
            <ListItemBox spacing={2} onClick={() => setHide(false)} role="button">
                <Typography variant="h6" component="h1">
                    Welcome to SFG20 Facilities-iQ
                </Typography>
                <Box flex={1} />
                <Box width={40} sx={{ lineHeight: 0 }}>
                    <FacilitiesLogo />
                </Box>
            </ListItemBox>
        </Grid>
    )
}
