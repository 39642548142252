import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material"
import { SearchBox } from "routes/schedule/plugins/welcome/search-box"
import { HomePageTile } from "slot-definitions"
import { hasDemand } from "lib/authorization/has-demand"
import { navigate } from "lib/routes/navigate"

HomePageTile.plug(<SearchTile />)

function SearchTile() {
    return (
        hasDemand("$!sharing") && (
            <Grid item xs={12}>
                <Card>
                    <ListItemBox>
                        <Iconify
                            sx={{ fontSize: 96, ml: 5, mr: 2, color: "primary.main" }}
                            icon="material-symbols:search"
                        />
                        <Box p={2} flex={1} pr={4}>
                            <Stack sx={{ width: 1, my: 5 }} spacing={1}>
                                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                    Search the schedule library
                                </Typography>
                                <SearchBox />
                            </Stack>
                        </Box>
                        <Button
                            sx={{
                                cursor: "pointer",
                                bgcolor: "primary.main",
                                alignSelf: "stretch",
                                display: "flex",
                                alignItems: "center",
                                color: "primary.contrastText",
                                fontSize: 20,
                                px: 1,
                                pl: 3,
                                "&:hover": {
                                    bgcolor: "primary.dark",
                                },
                            }}
                            onClick={browseClickHandler}
                            aria-label="Browse the schedule library"
                            //href is ok on <button> in Mui as it converts it to an anchor.
                            href="/app/schedules?id=schedules"
                            data-cy="go-to-schedule-library"
                        >
                            <Stack sx={{ position: "relative", top: 0 }}>
                                <Typography variant="caption" component="div">
                                    Browse
                                </Typography>
                                SFG20
                                <Typography
                                    variant="caption"
                                    sx={{ fontWeight: "bold", position: "relative" }}
                                    component="div"
                                >
                                    Schedules
                                </Typography>
                            </Stack>
                            <Iconify
                                sx={{ fontSize: 40, color: "primary.contrastText" }}
                                icon="material-symbols:chevron-right"
                            />
                        </Button>
                    </ListItemBox>
                </Card>
            </Grid>
        )
    )

    function browseClickHandler(e) {
        e.preventDefault()
        navigate("/app/schedules?id=schedules")
    }
}
