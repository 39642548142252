import { gql } from "@apollo/client"
import { ClientTreeUpdated, HasInvalidated } from "event-definitions"
import useAsync from "lib/@hooks/useAsync"
import { query } from "lib/graphql/query"
import { convertFolderName } from "routes/schedule/folder-manager/folder-management"

export function modelStats(group) {
    return query(
        gql`
            query modelStats($group: String) {
                getModelStats(group: $group) {
                    stats {
                        name
                        schedules {
                            id
                            count
                            units
                            annualMinutes
                            total {
                                classification
                                count
                                minutes
                                annualMinutes
                            }
                            skills {
                                skill
                            }
                            periods {
                                interval
                                period
                                frequency
                                count
                                minutes
                                annualMinutes
                                measurements {
                                    classification
                                }
                            }
                        }
                        children {
                            id
                            count
                            annualMinutes
                            allPeriods {
                                interval
                                period
                                frequency
                                count
                                minutes
                                annualMinutes
                                measurements {
                                    classification
                                    count
                                    minutes
                                    annualMinutes
                                }
                            }
                            total {
                                classification
                                count
                                minutes
                                annualMinutes
                            }
                            totalCount
                            totalAnnualMinutes
                        }
                    }
                    annualMinutes
                    count
                    profile {
                        annualMinutes
                        count
                        total {
                            classification
                            count
                            annualMinutes
                        }
                        id
                        name
                    }
                    periods {
                        count
                        annualMinutes
                        minutes
                        period
                        interval
                        frequency
                    }
                    skills {
                        skill
                        count
                        annualMinutes
                    }
                    total {
                        classification
                        count
                        annualMinutes
                    }
                }
            }
        `,
        { group: convertFolderName(group) },
        { returns: "getModelStats" }
    )
}

export function useModelStats(group, deps = []) {
    const { id } = ClientTreeUpdated.useRefresh()
    const { id: otherId } = HasInvalidated("getModelStats").useRefresh()
    return useAsync(
        async () => {
            try {
                return await modelStats(group)
            } catch (e) {
                console.error(e)
                return new Error("No stats")
            }
        },
        null,
        [id, otherId, group, ...deps]
    )
}
