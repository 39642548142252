import { Button } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { addTempBusy } from "lib/@components/busy"
import { useReferenceState } from "lib/@hooks/use-reference"
import Iconify from "minimals-template/components/Iconify"

export function CollapseTreeButton() {
    const { parentItem } = useBoundContext()
    const [treeVisible, setTreeVisible] = useReferenceState("treeVisible", false)

    if (!parentItem) return null

    return (
        <Button
            data-cy="collapse-tree-button"
            sx={{ mx: 1 }}
            startIcon={<Iconify icon={"ic:round-account-tree"} />}
            onClick={handleSetTreeVisible}
            size="small"
        >
            {treeVisible ? "Close" : "Open"} Tree
        </Button>
    )

    function handleSetTreeVisible() {
        addTempBusy()
        setTreeVisible((visible) => !visible)
    }
}
