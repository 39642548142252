import { useRefresh } from "lib/@hooks/useRefresh"
import { useCallback } from "react"
import { debounce } from "lib/debounce"
import { HasInvalidated } from "event-definitions"

export function useRefreshOnInvalidation(...names) {
    const refresh = useRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounced = useCallback(debounce(refresh, 300), [refresh])
    HasInvalidated.useEvent((query) => {
        if (names.includes(query)) {
            debounced()
        }
    })
    return refresh
}
