import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const assetDefinition = api(async function asset(id) {
    return query(
        gql`
            query GetAsset($id: String!) {
                asset(id: $id) {
                    id
                    picture
                    tag
                    description
                    system
                    modified
                    model
                    name
                    type
                    manufacturer
                    nrm
                    uniclass
                    location
                    floor
                    facility
                    space
                    category
                    assetRegister
                }
            }
        `,
        {
            id,
        },
        {
            returns: "asset",
            transform: (i) => ({
                ...i,
                picture: i.picture ? { documents: i.picture.map((o, i) => ({ href: o, id: i })) } : undefined,
            }),
        }
    )
})
