import { useScheduleGroup } from "routes/facilities/lib/use-schedule-group"
import { Bound } from "lib/@components/binding/Bound"
import { usePayloadTask } from "routes/facilities/lib/use-payload-task"
import { SummaryDataChanged } from "event-definitions"
import { Payload } from "routes/schedule/controller/payloads"
import { Box, Tooltip } from "@mui/material"
import { BoundCheckBox } from "lib/@components/standard-checkbox"
import { assessStatus } from "routes/schedule/extendedSchedule"
import { confirm } from "lib/@dialogs/confirm"
import { useRefresh } from "lib/@hooks/useRefresh"
import { NotOnMobile, OnMobile } from "lib/@components/mobile"
import { BlockClicks } from "lib/@components/block-clicks"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function Included() {
    const name = useScheduleGroup()
    const { target, payload, schedule } = useBoundContext()
    const task = usePayloadTask()
    task.included ??= true
    const refresh = useRefresh()
    SummaryDataChanged.raiseOnce()
    return (
        <Payload>
            <BlockClicks>
                <Bound onChange={() => assessStatus(payload, schedule)} target={task}>
                    <Tooltip title={`Include task for "${name}"`}>
                        <Box position="relative">
                            <NotOnMobile>
                                <BoundCheckBox
                                    onChanged={warn}
                                    sx={{ mt: 0.3, ml: -4, mr: 0 }}
                                    // disabled={!["Amber", "Green"].includes(target.classification)}
                                    field="included"
                                    label=""
                                />
                            </NotOnMobile>
                            <OnMobile>
                                <BoundCheckBox
                                    onChanged={warn}
                                    sx={{ position: "absolute", left: -46, top: 20 }}
                                    // disabled={!["Amber", "Green"].includes(target.classification)}
                                    field="included"
                                    label=""
                                />
                            </OnMobile>
                        </Box>
                    </Tooltip>
                </Bound>
            </BlockClicks>
        </Payload>
    )

    async function warn() {
        if (target.classification === "Red" && task.included === false) {
            if (
                !(await confirm(
                    "You have tried to disable a Red task.  Red tasks are required for legal compliance and should rarely be excluded, the only justification for disabling a Red task is that it relates to a non-existent part or feature for your specific use case.  Would you like to continue and disable the task?",
                    "Attention Required"
                ))
            ) {
                task.included = true
                refresh()
            }
        }
    }
}
