import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import useAsync from "lib/@hooks/useAsync"

const getScheduleForEditing = api(async function GetScheduleForEditing(code, shouldCreate) {
    return query(
        gql`
            query EditSchedule($code: String!, $shouldCreate: Boolean) {
                editSchedule(id: $code, shouldCreate: $shouldCreate)
            }
        `,
        { code, shouldCreate },
        { returns: "editSchedule", fetchPolicy: "network-only" }
    )
})

export function useScheduleForEditing(code, shouldCreate, deps = "standard") {
    return useAsync(() => getScheduleForEditing(code, shouldCreate), undefined, deps)
}
