import { Box, Button, Menu, MenuItem, Portal, Stack } from "@mui/material"
import { useReferenceState } from "lib/@hooks/use-reference"
import Iconify from "minimals-template/components/Iconify"
import { useRef, useState } from "react"

const filterStates = {
    None: { label: "None", value: "" },
    Tailored: { label: "Tailored", value: "tailored" },
    Schedule: { label: "Schedule", value: "schedule" },
    Regime: { label: "Regime", value: "plan" },
}
const filterStateKeys = Object.keys(filterStates)

export function ActionFilter() {
    const [actionFilter, save] = useReferenceState("actionFilter", filterStates.None)
    const [anchor, setAnchor] = useState(null)
    const ref = useRef()

    return (
        <>
            <Stack
                component={Button}
                className="action-filter"
                alignItems="center"
                onClick={open}
                sx={{ cursor: "pointer", fontSize: "85%", color: "text.primary" }}
                role="menu"
                direction="row"
                spacing={1}
            >
                <Box sx={{ fontWeight: "bold" }}>Filter:</Box>
                <Box sx={{ fontWeight: "normal" }} ref={ref}>
                    {actionFilter.label}
                </Box>
                <Box sx={{ fontSize: 24, lineHeight: 0 }}>
                    <Iconify icon="mdi:arrow-down-drop" />
                </Box>
            </Stack>
            <Portal>
                <Menu
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    sx={{ zIndex: 3000 }}
                    onClose={close}
                    open={Boolean(anchor ? ref.current : null)}
                    anchorEl={anchor ? ref.current : null}
                >
                    {filterStateKeys.map((state) => (
                        <MenuItem
                            key={state}
                            selected={actionFilter.value === filterStates[state].value}
                            onClick={() => {
                                setFilter(state)
                            }}
                        >
                            <Stack direction="row" spacing={1}>
                                <Box>{filterStates[state].label}</Box>
                            </Stack>
                        </MenuItem>
                    ))}
                </Menu>
            </Portal>
        </>
    )
    function setFilter(state) {
        save(filterStates[state])
        close()
    }
    function open() {
        setAnchor(ref.current)
    }

    function close() {
        setAnchor(null)
    }
}
