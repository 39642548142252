import { useDialog } from "lib/@hooks/useDialog"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { busyWhile } from "lib/@components/busy"
import { gql } from "@apollo/client"
import { convertFolderName } from "routes/schedule/folder-manager/folder-management"
import { FolderContextMenu } from "routes/schedule/folder-manager/lib/folder-context-menu"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { mutate } from "lib/graphql/mutate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { RenameDialog } from "routes/schedule/folder-manager/rename-dialog"
import { isInPublishedRegime } from "routes/facilities/lib/when-parent-item"

FolderContextMenu.plug(({ context }) => !isInPublishedRegime(context) && <RenameFolder />)

export function RenameFolder() {
    const { parentItem } = useBoundContext()
    const type = parentItem?.[PlanRoot] ? "Regime" : parentItem?.data?.subType?.titleize() ?? "Group"
    const rename = useDialog(
        <RenameDialog
            title={`Edit ${type}`}
            image={!parentItem?.[PlanRoot]}
            type={type}
            currentName={parentItem?.label}
            currentImage={parentItem.data?.image}
        />
    )
    return (
        (!parentItem?.data?.type || parentItem.data.type === "simple") && (
            <ContextMenuItem icon="eva:edit-fill" label="Edit" onClick={go} />
        )
    )

    async function go() {
        let newName = await rename()
        if (newName) {
            const { image } = newName
            newName = newName.name ?? newName
            await busyWhile(async () =>
                mutate(
                    gql`
                        mutation updateName($tree: String!, $group: String!, $label: String!, $data: JSONObject) {
                            setGroup(group: $group, tree: $tree, label: $label, data: $data)
                        }
                    `,
                    {
                        group: convertFolderName(parentItem.id),
                        label: newName,
                        tree: getTreeIdFromItem(parentItem),
                        data: image ? { image, type: "simple" } : undefined,
                    }
                )
            )
        }
    }
}
