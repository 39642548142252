import { getActions } from "routes/actions/controller/get-actions"
import { useUserMetadata } from "lib/store/user"
import { Box, Grid, Link, List, Paper, Stack, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { ActionEntry } from "routes/actions/action-entry"
import { NoResults } from "lib/@components/no-results/no-results"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { RouterLink } from "lib/routes/router-link"
import useIsXsScreen from "minimals-template/components/@hooks/useIsXsScreen"

export function ActionsTile() {
    const actions = getActions.useResults({ complete: false })
    const { favourites = [] } = useUserMetadata()
    const list = actions?.actions
        .filter((c) => !favourites.includes(`-${c._id}`))
        .map((c) => {
            c.dueDate ??= new Date(c.created).addDays(45)
            return c
        })
        .sortBy((c) => c.dueDate)
        .slice(0, 5)

    const isXsScreen = useIsXsScreen()

    return (
        !!list?.length && (
            <Grid item xs={12} sx={{ mt: 3 }}>
                <Stack spacing={1} sx={{ my: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Actions
                    </Typography>

                    <Paper sx={{ overflow: isXsScreen ? undefined : "hidden" }} elevation={1}>
                        <List
                            sx={{
                                flex: 1,
                                py: 0,

                                "& .MuiButton-root": {
                                    display: "block",
                                    minWidth: "max-content !important",
                                    flexBasis: "max-content",
                                },
                                "& .MuiListItemText-root": {
                                    flexBasis: 0,
                                    mr: 3,
                                },
                            }}
                        >
                            <ListItemBox sx={{ width: 1, py: 0.3, bgcolor: "grey.50016" }}>
                                <Box ml={6}>
                                    <Typography variant="caption" color="text.secondary">
                                        Action
                                    </Typography>
                                </Box>
                                <Box flex={1} />
                                <Box mr={3}>
                                    <Typography variant="caption" color="text.secondary">
                                        Created
                                    </Typography>
                                </Box>
                                <Box mr={7}>
                                    <Typography variant="caption" color="text.secondary">
                                        Due
                                    </Typography>
                                </Box>
                            </ListItemBox>
                            {!!list?.length && (
                                <PagedRepeat pageSize={10} list={list} pass="action" item={<ActionEntry />} />
                            )}
                            {!list?.length && <NoResults width={150} mt={3} />}
                            {actions?.actions.length > list?.length && (
                                <ListItemBox sx={{ width: 1, py: 0.3, pr: 2, bgcolor: "grey.50016" }}>
                                    <Box ml={6}>
                                        <Typography variant="caption" color="text.secondary">
                                            + {actions?.actions.length - list?.length} more
                                        </Typography>
                                    </Box>
                                    <Box flex={1} />
                                    <Typography variant="caption">
                                        <Link component={RouterLink} to="/app/actions">
                                            More...
                                        </Link>
                                    </Typography>
                                </ListItemBox>
                            )}
                        </List>
                    </Paper>
                </Stack>
            </Grid>
        )
    )
}
