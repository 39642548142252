import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export const duplicateRegime = api(function duplicateRegime(tree) {
    return mutate(
        gql`
            mutation duplicate($tree: String!) {
                duplicateTree(tree: $tree)
            }
        `,
        { tree },
        { returns: "duplicateTree" }
    )
})
