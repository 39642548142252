import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getSteps = api((title, task, context, notes) =>
    query(
        gql`
            query GetSteps($task: String!, $context: String, $title: String!, $notes: String) {
                getSteps(title: $title, task: $task, context: $context, notes: $notes)
            }
        `,
        { task, context, title, notes },
        { returns: "getSteps" }
    )
)
