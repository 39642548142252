import { FolderContextMenu } from "routes/schedule/folder-manager/lib/folder-context-menu"
import { Bound } from "lib/@components/binding/Bound"
import { useDialog } from "lib/@hooks/useDialog"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { parentTreeItem } from "library/tree"
import { busyWhile } from "lib/@components/busy"
import { waitForScheduleRefresh } from "routes/schedule/lib/wait-for-schedule-refresh"
import { gql } from "@apollo/client"
import { convertFolderName } from "routes/schedule/folder-manager/folder-management"
import { ScheduleFolderRight } from "slot-definitions"
import { ContextMenuBar } from "lib/@components/context-menu-bar"
import { makeLazy } from "lib/make-lazy"
import { Settings } from "lib/@components/settings"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { Secure } from "lib/authorization/secure"
import { mutate } from "lib/graphql/mutate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { Box } from "@mui/material"
import { isInPublishedRegime } from "routes/facilities/lib/when-parent-item"

const ChooseFolder = makeLazy(() => import("routes/schedule/folder-manager/choose-folder"), "ChooseFolder")
FolderContextMenu.plug(
    ({ context }) =>
        context.parentItem && !context.parentItem[PlanRoot] && !isInPublishedRegime(context) && <MoveFolder />
)

export function MoveFolder() {
    const { parentItem } = useBoundContext()
    const chooseFolder = useDialog(<ChooseFolder source={parentItem} />)

    return (
        <ContextMenuItem
            icon="carbon:folder-move-to"
            label={`Move "${parentItem.label}"`}
            onClick={moveFolder}
            noTitleize
        />
    )

    async function moveFolder() {
        const folder = await chooseFolder()
        if (folder) {
            await busyWhile(
                async () => {
                    await mutate(
                        gql`
                            mutation move(
                                $tree: String!
                                $toTree: String
                                $fromGroup: String!
                                $toGroup: String!
                                $ids: [String]!
                            ) {
                                moveReferencesToGroup(
                                    tree: $tree
                                    fromGroup: $fromGroup
                                    toGroup: $toGroup
                                    ids: $ids
                                    toTree: $toTree
                                )
                            }
                        `,
                        {
                            tree: getTreeIdFromItem(parentItem),
                            toTree: getTreeIdFromItem(folder),
                            fromGroup: convertFolderName(parentItem[parentTreeItem].id),
                            toGroup: convertFolderName(folder.id),
                            ids: [parentItem.id],
                        }
                    )
                },
                "Moving",
                waitForScheduleRefresh
            )
        }
    }
}

ScheduleFolderRight.plug(
    ({ context }) =>
        !context.inSelector &&
        context.target?.folderHandling &&
        !context.inTree && (
            <Settings priority={300}>
                <Bound parentItem={context.target}>
                    <Secure hide demand="manageRegime">
                        <>
                            <Box width={32} />
                            <ContextMenuBar type="folder" />
                        </>
                    </Secure>
                </Bound>
            </Settings>
        )
)
