import { fShortenNumber } from "minimals-template/components/utils/formatNumber"

export function formatTime(timeInMinutes) {
    if (timeInMinutes > 240) {
        if (timeInMinutes > 60 * 24 * 2) {
            return `${fShortenNumber(timeInMinutes / (60 * 8))} w/day${
                +fShortenNumber(timeInMinutes / (60 * 8)) !== 1 ? "s" : ""
            }`
        }
        return `${fShortenNumber(timeInMinutes / 60)} hour${+fShortenNumber(timeInMinutes / 60) !== 1 ? "s" : ""}`
    }
    return `${fShortenNumber(timeInMinutes)} min${+fShortenNumber(timeInMinutes) !== 1 ? "s" : ""}`
}

export function formatTimeShort(timeInMinutes) {
    if (timeInMinutes > 240) {
        if (timeInMinutes > 60 * 24 * 2) {
            return `${fShortenNumber(timeInMinutes / (60 * 8))} w/day${
                +fShortenNumber(timeInMinutes / (60 * 8)) !== 1 ? "s" : ""
            }`
        }
        return `${fShortenNumber(timeInMinutes / 60)}h`
    }
    return `${fShortenNumber(timeInMinutes)}m`
}
