import { useEffect, useState } from "react"
import { WatchChanged } from "event-definitions"
import { addWatch } from "lib/system/server-events/controller/add-watch"
import { removeWatch } from "lib/system/server-events/controller/remove-watch"
import { Bound } from "lib/@components/binding/Bound"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ensureArray } from "lib/ensure-array"

export function useWatch(_id, path, defaultValue) {
    const [value, setValue] = useState(defaultValue)
    WatchChanged.useEvent(
        (new_id, new_path, value) => {
            if (new_id === _id && new_path === path) {
                setValue(value)
            }
        },
        [_id, path]
    )
    useEffect(() => {
        addWatch(_id, path).catch(console.error)
        return () => removeWatch(_id, path)
    }, [_id, path])
    return value
}

export function watcher(_id) {
    return function useWatchPath(path, defaultValue) {
        return useWatch(_id, path, defaultValue)
    }
}

export function Watcher({ id, children }) {
    id = id && Object.isObject(id) ? id._id : id
    return <Bound useWatcher={watcher(id)}>{children}</Bound>
}

export function useWatchPath(path, defaultValue) {
    const realPath = ensureArray(path)
        .map((s) => `[${s}]`)
        .join("")

    const { useWatcher } = useBoundContext()
    if (!useWatcher) throw new Error("No watcher defined")
    return useWatcher(realPath, defaultValue)
}
