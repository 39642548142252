import { useEffect, useRef, useState } from "react"

export function useAnimatedOpen(isOpen) {
    const [exist, setExist] = useState(isOpen)
    const timer = useRef()
    useEffect(() => {
        clearTimeout(timer.current)
        if (isOpen) {
            setExist(true)
        } else if (exist) {
            timer.current = setTimeout(() => setExist(false), 500)
        }
    }, [isOpen, exist])
    return [exist, !!isOpen]
}
