import { gql } from "@apollo/client"
import { CurrentClientChanged, HasInvalidated, RefreshPayload } from "event-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { clone } from "lib/clone"
import { ensureArray } from "lib/ensure-array"
import useAsync from "lib/@hooks/useAsync"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { getClient } from "minimals-template/components/contexts/NognitoContext"
import { useRef } from "react"
import { assessStatus } from "routes/schedule/extendedSchedule"
import { getGroupPayload } from "routes/schedule/controller/get-group-payload"
import { query } from "lib/graphql/query"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import noop from "lib/noop"
import { debounce } from "lib/debounce"
import { normalizedStringify } from "library/normalized-stringify"

let nextId = 1
export const PayloadId = Symbol("PayloadId")
export async function getUniqueCodes(codes) {
    codes = ensureArray(codes)
    return clone(
        (
            await query(
                gql`
                    query getUniqueCodes($ids: [String]!) {
                        getUniqueCodes(ids: $ids)
                    }
                `,
                {
                    ids: codes,
                }
            )
        ).getUniqueCodes ?? []
    )
}

export function usePayload(group, key, tree) {
    const refresh = CurrentClientChanged.useRefresh(noChange)

    return useAsync(async () => getGroupPayload(group, key, tree), null, [group, key, refresh.id])
}

export function usePayloadAndRefresh(group, key, tree, threshold = 400) {
    const refresh = CurrentClientChanged.useRefresh(noChange)
    const data = useAsync(
        async () => {
            const result = await getGroupPayload(group, key, tree)
            if (result) {
                result[PayloadId] = nextId++
            }
            return result
        },
        null,
        [group, key, tree, getClient(), refresh.id],
        () => true
    )
    const dataRef = useRef()
    dataRef.current = data
    HasInvalidated("getGroupPayload").useEvent(debounce(refresh, threshold))

    return [data, refresh.id]
}

export function Payload({ children }) {
    const { storePayload = noop, currentId, payload, schedule, target } = useBoundContext()

    const currentPayload = useRef()
    currentPayload.current = payload
    const stored = useRef(normalizedStringify(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refresh = useRefresh(noChange)

    return (
        payload && (
            <Bound onChange={checkAndSave} target={payload} refresh={refresh} payloadTarget={target} payload={payload}>
                {children}
            </Bound>
        )
    )

    async function checkAndSave() {
        const payload = currentPayload.current
        const newPayload = normalizedStringify(payload)
        if (stored.current !== newPayload) {
            assessStatus(payload, schedule)
            RefreshPayload.raise()
            if (payload.status && (!payload.uniqueCode || payload.uniqueParent !== currentId)) {
                payload.uniqueCode = (await getUniqueCodes(currentId))[0]
                payload.uniqueParent = currentId
            } else if (!payload.status && payload.uniqueCode) {
                delete payload.uniqueParent
                delete payload.uniqueCode
            }
            stored.current = newPayload
            storePayload()
        }
    }
}
