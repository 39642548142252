import { BoundTypography } from "lib/@components/binding/bound-components"
import { DATE_FORMATS } from "lib/@constants/date-format"

export function Created() {
    return (
        <BoundTypography
            sx={{ width: 68, textAlign: "right" }}
            component="div"
            variant="caption"
            color="text.secondary"
            field="created"
            transformIn={(v) => new Date(v).format(DATE_FORMATS.medium)}
        />
    )
}
