import { asset } from "routes/asset-register/controller/asset"
import { Box, IconButton, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"
import { useDialog } from "lib/@hooks/useDialog"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { DisassociateDialog } from "routes/regime/assets/disassociate-dialog"
import { range } from "lib/range"
import { MdClear } from "@react-icons/all-files/md/MdClear"
import { BlockClicks } from "lib/@components/block-clicks"
import { navigate } from "lib/routes/navigate"
import { uniqueId } from "library/database/split-id"

export function AssociatedAsset({ item: id }) {
    const { assetField, refresh, target, uom, assetIndex, readOnly } = useBoundContext()
    const [, setAssetId] = useBoundValue(assetField)
    const item = asset.useResults(id)
    const disassociateDialog = useDialog(<DisassociateDialog />)
    return item ? (
        <ListItemButton onClick={go}>
            <ListItemBox spacing={1}>
                <ListItemIcon>
                    <Iconify icon="carbon:asset" />
                </ListItemIcon>
                <ListItemText primary={item.description ?? item.tag} secondary={item.description ? item.tag : ""} />
                <Box flex={1} />
                <Typography variant="caption">{item.system}</Typography>
                <Box pl={2} />
                {!readOnly && (
                    <BlockClicks>
                        <IconButton onClick={disassociate} size="small" color="secondary">
                            <MdClear />
                        </IconButton>
                    </BlockClicks>
                )}
            </ListItemBox>
        </ListItemButton>
    ) : (
        <Box height={62} p={2}>
            <Skeleton variant="rectangular" sx={{ height: 48, borderRadius: 1.5 }} />
        </Box>
    )

    function go() {
        navigate(`/app/asset/${uniqueId(id)}`)
    }

    async function disassociate() {
        switch (await disassociateDialog()) {
            case "delete": {
                const count = +target[uom]
                const assets = range(count).map((i) => target[`${uom}_${i + 1}`])
                assets.splice(assetIndex, 1)
                range(count).forEach((i) => (target[`${uom}_${i + 1}`] = assets[i]))
                target[uom] -= 1
                refresh()

                break
            }
            case "disassociate":
                setAssetId("")
                refresh()
                break
            default:
                break
        }
    }
}
