import { useStoreState } from "lib/store"
import { getMyDerivedSchedulesCount } from "routes/schedule/my-schedules/my-custom-schedules/controller/get-my-derived-schedules-count"
import { Box, Button, Stack, Tooltip } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { hasDemand } from "lib/authorization/has-demand"
import { useEffect } from "react"
import { BlockClicks } from "lib/@components/block-clicks"
import { OutlinedBadge } from "lib/@components/outlined-badge"

export function DerivedSchedules({ schedule }) {
    const [open, setOpen] = useStoreState(`details_${schedule?._id}`, false)
    const count = getMyDerivedSchedulesCount.useResults(schedule?._id, hasDemand("scheduleEditor"))
    useEffect(() => {
        if (!count && open) setOpen(false)
    }, [count, open, setOpen])
    return (
        !!schedule &&
        !!count && (
            <BlockClicks>
                <Tooltip title="Schedules you have derived from this one" arrow>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        component={Button}
                        role="button"
                        aria-label="Derived documents"
                        sx={{ cursor: "pointer", color: "text.primary" }}
                        onClick={toggle}
                        className="derived-schedules"
                    >
                        <Iconify icon="fluent:document-copy-24-regular" />
                        <OutlinedBadge content={count} />
                        <Box
                            sx={{
                                fontSize: 24,
                                lineHeight: 0,
                                transition: "transform linear 0.3s",
                                transform: `rotate(${open ? -180 : 0}deg)`,
                            }}
                        >
                            <Iconify icon="mdi:arrow-down-drop" />
                        </Box>
                    </Stack>
                </Tooltip>
            </BlockClicks>
        )
    )

    function toggle() {
        setOpen((o) => !o)
    }
}
