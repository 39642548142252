import { Box, Table, TableBody } from "@mui/material"
import { TaskSummaries } from "slot-definitions"

export function FilterSummaries({ filter, type = "task" }) {
    return (
        <Box flex={1} sx={{ width: "30%", maxWidth: 260 }}>
            <Table
                size="small"
                sx={{
                    width: 1,

                    "& td": {
                        whiteSpace: "nowrap",
                        pl: 0.5,
                        pr: 0,
                        py: 0.2,
                    },
                    "& td:first-of-type(3)": { whiteSpace: "normal" },
                    "& td:first-of-type": { pl: 0, pr: 0 },
                }}
            >
                <TableBody>
                    <TaskSummaries.Slot type={type} filter={filter} />
                </TableBody>
            </Table>
        </Box>
    )
}
