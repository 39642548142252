import { useMeasurement } from "lib/@hooks/useMeasurement"
import { TableCell } from "@mui/material"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

export function MemoryWidthTableCell({ children, name, pad = 32, ...props }) {
    const [minWidth, setMinWidth] = useBoundValue(`_table_cell_width_${name}`, 0)
    const [, measure] = useMeasurement(undefined, () => false, update)

    return (
        <TableCell ref={measure} {...props} sx={{ ...props.sx, minWidth }}>
            {children}
        </TableCell>
    )

    function update({ width }) {
        if (width + pad > minWidth) {
            setMinWidth(width + pad)
        }
    }
}
