import { hasDemand } from "lib/authorization/has-demand"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { appRoute } from "routes/app/home.runtime"
import { Lazy } from "lib/make-lazy"
import "routes/sfg20-admin/plugins/help/help.runtime"
import { hasAnyDemand } from "lib/authorization/has-any-demand"
import { defaultDemands } from "library/permissions/default-demands.js"

export function getAdminDemands() {
    return defaultDemands.filter((d) => d.group === "System").map((d) => d.name)
}

appRoute.register(
    "sfg20admin",
    <Lazy
        importer={() => import("routes/sfg20-admin/sfg20-admin")}
        extract="Sfg20Admin"
        demands={getAdminDemands()}
        matchAllDemands={false}
    />
)

registerConditionalPageNavigation(
    () => hasAnyDemand(getAdminDemands()) && hasDemand("!sharing") && hasDemand("schedules"),
    "/app/sfg20admin",
    "SFG20 Admin",
    "ic:round-admin-panel-settings",
    {
        group: "More",
        priority: 250,
    }
)
