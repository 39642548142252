import { Grid } from "@mui/material"
import AppWidget from "minimals-template/pages/sections/@dashboard/general/app/AppWidget"
import uniqolor from "uniqolor"

export function Summary({ item: [name, list], total, setFilter, current }) {
    return (
        <Grid item xs={12} md={6} lg={4}>
            <AppWidget
                sx={{ cursor: "pointer", opacity: !current || current === name ? 1 : 0.4 }}
                role="button"
                className={name.replace(/\W+/gi, "-")}
                aria-label={name}
                onClick={() => setFilter(name)}
                title={name.pluralize(list.length)}
                color={uniqolor(name).color}
                total={list.length}
                icon={list[0].icon}
                chartData={{
                    series: [Math.floor((list.length / (total || Infinity)) * 100)],
                }}
            />
        </Grid>
    )
}
