import noop from "lib/noop"
import { useId } from "lib/@hooks/useId"
import { useEffect, useRef, useState } from "react"
import { Backdrop, Box, Menu, Portal } from "@mui/material"
import { prevent } from "lib/prevent"
import { useAnimatedOpen } from "lib/@components/use-animated-open"

export function PopupBox({ children, hidePopup, popup = null, onOpen = noop, onClose = noop, ...props }) {
    const id = useId()
    const isOpen = useRef(false)
    const parent = useRef()
    const [anchorEl, setAnchorEl] = useState()
    const [exist, open] = useAnimatedOpen(Boolean(anchorEl))

    popup = hidePopup ? null : popup

    useEffect(() => {
        if (anchorEl && !isOpen.current) {
            isOpen.current = true
            onOpen()
        }
        if (!anchorEl && isOpen.current) {
            isOpen.current = false
            onClose()
        }
    }, [anchorEl, onOpen, onClose])

    return (
        <Box
            {...props}
            aria-haspopup={!!popup}
            sx={{ opacity: popup ? 1 : 0.5, ...props.sx, cursor: popup ? "pointer" : undefined }}
            role={popup ? "button" : undefined}
            id={id}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
        >
            {children}
            <Box ref={parent}>
                {exist && (
                    <Portal>
                        <Backdrop
                            onClick={prevent(close)}
                            onMouseDown={prevent(noop)}
                            onTouchStart={prevent(noop)}
                            sx={{ zIndex: 2000 }}
                            open={open}
                        >
                            <Menu
                                sx={{ zIndex: 2201 }}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                            >
                                {popup}
                            </Menu>
                        </Backdrop>
                    </Portal>
                )}
            </Box>
            <Box display="none">{popup}</Box>
        </Box>
    )

    function close() {
        setAnchorEl(null)
    }

    function openPopup() {
        if (popup) setAnchorEl(parent.current)
    }

    function handleClick() {
        openPopup()
    }

    function handleKeyDown(event) {
        if (event.key === "Enter") {
            openPopup()
        }
    }
}
