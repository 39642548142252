import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Alert, IconButton, Typography } from "@mui/material"
import { ScheduleTopmost } from "routes/schedule/scheduleIntroduction"
import { useState } from "react"
import { MdOutlineArrowCircleUp } from "@react-icons/all-files/md/MdOutlineArrowCircleUp"
import { MdOutlineArrowCircleDown } from "@react-icons/all-files/md/MdOutlineArrowCircleDown"

export function Rejection() {
    const { schedule } = useBoundContext()

    return (
        !!schedule.rejectionReason && (
            <ScheduleTopmost.Plug>
                <RejectionInner />
            </ScheduleTopmost.Plug>
        )
    )
}

function RejectionInner() {
    const [open, setOpen] = useState(true)
    const { schedule } = useBoundContext()
    return (
        !!schedule.rejectionReason && (
            <Alert
                data-cy="rejection-alert"
                severity="warning"
                sx={{ my: 2 }}
                action={
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <MdOutlineArrowCircleUp /> : <MdOutlineArrowCircleDown />}
                    </IconButton>
                }
            >
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Publishing Request was Rejected
                </Typography>
                {!!open && <Typography variant="body1">{schedule.rejectionReason}</Typography>}
            </Alert>
        )
    )
}
