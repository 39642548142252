import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const uniqueValues = api(async function getUniqueValues(register) {
    if (!register) return undefined
    return query(
        gql`
            query GetUniqueValuesForRegister($register: String!) {
                assetRegister(id: $register) {
                    id
                    systems
                    locations
                    manufacturers
                    types
                    facilities
                    floors
                    spaces
                    categories
                }
            }
        `,
        { register },
        { returns: "assetRegister" }
    )
})
