import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Button, IconButton, InputAdornment, List, ListSubheader, Typography } from "@mui/material"
import { FilterLabel } from "routes/asset-register/filter-label"
import { ListItemBox } from "lib/@components/ListItemBox"
import { MdClear } from "@react-icons/all-files/md/MdClear"
import { useMemo } from "react"
import { DebouncedTextField } from "lib/@components/debounced-text-field"
import { matches } from "library/matches"
import { setFromEvent } from "lib/setFromEvent"
import { uniqueValues } from "routes/asset-register/controller/uniqueValues"
import { VirtualWindow } from "lib/@components/virtual-window/VirtualWindow"
import { useSearchParam } from "lib/@hooks/use-search-param"

const empty = {}

export function FilterList() {
    const { target, filters, setFilters } = useBoundContext()
    const [search, setSearch] = useSearchParam("asset-filters-search", "")
    const lists = uniqueValues.useResults(target.id) ?? empty
    const output = useMemo(() => {
        const facilities = lists.facilities?.filter((s) => matches(s, search))
        const floors = lists.floors?.filter((s) => matches(s, search))
        const systems = lists.systems?.filter((s) => matches(s, search))
        const categories = lists.categories?.filter((s) => matches(s, search))
        const manufacturers = lists.manufacturers?.filter((s) => matches(s, search))
        const spaces = lists.spaces?.filter((s) => matches(s, search))
        const output = []
        addOutput("facilities", facilities)
        addOutput("floors", floors)
        addOutput("systems", systems)
        addOutput("categories", categories)
        addOutput("spaces", spaces)
        addOutput("manufacturers", manufacturers)
        return output

        function addOutput(name, list) {
            if (list?.length) {
                output.push(
                    <ListSubheader key={name}>
                        <Typography variant="overline">{name.titleize()}</Typography>
                    </ListSubheader>
                )

                output.push(...list.map((f) => <FilterLabel key={`${name}_${f}`} item={f} type={name} />))
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, lists])

    return (
        <>
            <ListItemBox spacing={0.5} sx={{ mt: 0.3 }}>
                <DebouncedTextField
                    sx={{ "& *": { fontSize: 13 } }}
                    value={search}
                    onChange={setFromEvent(setSearch)}
                    placeholder="Filter..."
                    variant="standard"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={() => setSearch("")}>
                                    <MdClear />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button size="small" disabled={Object.isEmpty(filters)} sx={{ color: "primary.main" }} onClick={clear}>
                    All
                </Button>
            </ListItemBox>
            <List
                dense
                sx={{
                    py: 0,
                    height: "50vh",
                    display: "flex",
                    flexDirection: "column",
                    "& .MuiListSubheader-root": {
                        top: 0,
                        my: 0,
                        py: 0,
                        bgcolor: "background.default",
                    },
                }}
            >
                <VirtualWindow>{output}</VirtualWindow>
            </List>
        </>
    )

    function clear() {
        setFilters({})
    }
}
