import { registerTab } from "lib/@components/tabs"
import { Grid, InputLabel } from "@mui/material"
import { tasksWithTimings } from "routes/schedule/plugins/task-summary/annual-timing"
import { SummaryClassification, SummarySkilling, SummaryTiming } from "slot-definitions"
import { SummaryDataChanged } from "event-definitions"
import { noChange } from "lib/@hooks/useRefresh"
import { useState } from "react"
import { ToBeSaved } from "lib/@components/save-indicator/save-indicator"

registerTab({
    tab: "ScheduleRightBar",
    id: "Summary",
    content: <Summary priority={20} />,
    predicate: (context) => !!context?.target?.tasks && tasksWithTimings(context.target.tasks).length,
    refresher: <TaskBasedRefresher />,
})

function TaskBasedRefresher({ refresh }) {
    ToBeSaved("**").useEvent(refresh)
    return null
}

function CollapseItem({ children, ...props }) {
    const [show, setShow] = useState(false)
    return children ? (
        <Grid ref={attach} item {...props} sx={{ ...props.sx, display: show ? "block" : "none" }}>
            {children}
        </Grid>
    ) : null

    function attach(container) {
        if (container) {
            if (container.innerHTML) {
                setShow(true)
            }
        }
    }
}

function Summary() {
    SummaryDataChanged.useRefresh(noChange)
    return (
        <Grid className="summary-schedule" container spacing={1}>
            <Grid item>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <InputLabel shrink>Classification</InputLabel>
                    </Grid>
                    <SummaryClassification.Slot Container={<CollapseItem />} />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <InputLabel shrink>Timing</InputLabel>
                    </Grid>
                    <SummaryTiming.Slot Container={<CollapseItem />} />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <InputLabel shrink>Skill</InputLabel>
                    </Grid>
                    <SummarySkilling.Slot Container={<CollapseItem />} />
                </Grid>
            </Grid>
        </Grid>
    )
}
