import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getFlagsForId = api(function getFlagsForId(id) {
    if (!id) return null
    return query(
        gql`
            query GetFlagsForId($id: String!) {
                getFlagsForId(id: $id) {
                    Name
                    Content
                    IconFileName
                    Category
                }
            }
        `,
        { id },
        { returns: "getFlagsForId" }
    )
})
