import { MdArrowDropDown } from "@react-icons/all-files/md/MdArrowDropDown"
import { useRef, useState } from "react"
import { Button, Menu, MenuItem, Portal, Typography } from "@mui/material"
import { bind } from "lib/@components/binding/bind"

export const BoundStandardSelector = bind(<StandardSelector />, {
    extract: (v) => v,
})

export function StandardSelector({
    error,
    helperText,
    options,
    value,
    endIcon = <MdArrowDropDown />,
    onChange,
    ...props
}) {
    const ref = useRef()
    const [anchor, setAnchor] = useState()
    return (
        <>
            <Button ref={ref} onClick={() => setAnchor(ref.current)} endIcon={endIcon} {...props}>
                {value !== undefined ? options.find((o) => o.value === value)?.label ?? value?.label ?? value : "N/A"}
            </Button>
            {error && (
                <Typography variant="caption" color="red">
                    {helperText}
                </Typography>
            )}
            <Portal>
                <Menu sx={{ zIndex: 3000 }} open={!!anchor} anchorEl={anchor} onClose={() => setAnchor(null)}>
                    {options.filter(Boolean).map(createOption)}
                </Menu>
            </Portal>
        </>
    )

    function createOption(option, index) {
        if (typeof option === "string") {
            return (
                <MenuItem selected={option === value} key={index} onClick={() => choose(option)}>
                    {option}
                </MenuItem>
            )
        }

        if (Array.isArray(option)) {
            return (
                <MenuItem selected={option[0] === value} key={index} onClick={() => choose(option[0])}>
                    {option[1] ?? option[0]}
                </MenuItem>
            )
        }

        return (
            <MenuItem selected={option.value === value} key={index} onClick={() => choose(option.value)}>
                {option.label}
            </MenuItem>
        )
    }

    function choose(value) {
        onChange(value)
        setAnchor(null)
    }
}
