import { useState } from "react"
import { Table, TableBody, TableContainer, TablePagination, Box } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { RegimeAccessTableFilters } from "./regime-access-table-filters"
import { filterStates } from "../regime-access-config"
import { useReferenceState } from "lib/@hooks/use-reference"
import { Bound } from "lib/@components/binding/Bound"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { TableNoData } from "minimals-template/components/table"
import { RegimeAccessTableHeader } from "./regime-access-table-header"
import { RegimeAccessTableRow } from "./regime-access-table-row"

export function RegimeAccessTable() {
    const { regimeUsers: rows } = useBoundContext()
    const [search, setSearch] = useState("")
    const [selected, setSelected] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [accessFilter] = useReferenceState("accessFilter", filterStates.All)

    const filteredRows = rows?.filter(
        (row) =>
            (row.displayName.toLowerCase().includes(search.toLowerCase()) ||
                row.userName.toLowerCase().includes(search.toLowerCase())) &&
            (accessFilter.value === "enabled"
                ? row.hasAccess
                : accessFilter.value === "disabled"
                  ? !row.hasAccess
                  : true)
    )

    const refresh = useRefresh(noChange)

    return (
        <Bound refresh={refresh}>
            <RegimeAccessTableFilters search={search} onSetSearch={handleSearchChange} />

            <TableContainer>
                <Table>
                    <RegimeAccessTableHeader
                        selected={selected}
                        handleSelectAllClick={handleSelectAllClick}
                        rows={rows}
                        isSelected={isSelected}
                        showSelectAllToggle={!!filteredRows?.length}
                    />
                    <TableBody>
                        {filteredRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const isItemSelected = isSelected(row)
                            return (
                                <RegimeAccessTableRow
                                    row={row}
                                    isItemSelected={isItemSelected}
                                    key={row.userId}
                                    handleClick={handleClick}
                                />
                            )
                        })}
                        <TableNoData isNotFound={!filteredRows?.length} title="No Results" />
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component={Box}
                count={filteredRows?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Bound>
    )

    function isSelected(user) {
        return selected.indexOf(user) !== -1
    }

    function handleSearchChange(value) {
        setSearch(value)
        setPage(0)
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = filteredRows.map((row) => row)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    function handleClick(user) {
        const selectedIndex = selected.indexOf(user)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, user)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        setSelected(newSelected)
    }

    function handleChangePage(event, newPage) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
}
