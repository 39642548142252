import { LIVE_TREE, PUBLISHED_TREE, READY_TREE } from "library/constants"
import { Repeat } from "lib/@components/repeat"
import { Bound } from "lib/@components/binding/Bound"
import { Alert, Box, Grid, Stack } from "@mui/material"
import { FilterView } from "routes/schedule/plugins/filter/runtime-main"
import { FilterSchedule, RefreshScheduleSelector } from "event-definitions"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"
import { useMemo } from "react"
import { RegimeItem } from "routes/regime/regime-management/regime-item"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { TreeRoot } from "library/tree"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { NoResults } from "lib/@components/no-results/no-results"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import DemandGuard from "minimals-template/components/guards/DemandGuard"
import { FEATURE_FLAG_REGIME_PERMISSIONS } from "routes/regime/plugins/regime-access/regime-access.runtime"
import { getDemandForFeature } from "lib/features"

export function RegimeList() {
    const ready = useTreeItem(READY_TREE)
    const live = useTreeItem(LIVE_TREE)

    const baseTree = {
        id: PUBLISHED_TREE,
        [PlanRoot]: true,
        [TreeRoot]: true,
        folderHandling: false,
        hasChildren: true,
        visible: false,
        children: [...(live?.children ?? []), ...(ready?.children ?? [])].unique((c) => c.id.split("|").at(-1)),
    }

    return (
        <DemandGuard
            demands={[
                "publishRegime",
                "manageRegime",
                getDemandForFeature(FEATURE_FLAG_REGIME_PERMISSIONS),
                "$sharing",
            ]}
        >
            {!!live && (
                <Bound liveTrees={baseTree}>
                    <ActiveRegimes />
                </Bound>
            )}
        </DemandGuard>
    )
}

function ActiveRegimes() {
    const { liveTrees } = useBoundContext()
    const [share] = useSearchParam("share")
    RefreshScheduleSelector.useRefresh()
    if (
        !useMemo(() => {
            if (liveTrees?.children?.length === 1) {
                navigate(`/app/facilities/${liveTrees.children[0].id}`, { replace: true })
                return false
            }
            return true
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [JSON.stringify(liveTrees)])
    ) {
        return null
    }
    return (
        <PageBreadcrumbContainer title="Maintenance Regimes">
            <Box sx={{ pt: 3, flex: 1, overflowX: "hidden", overflowY: "scroll" }}>
                <Stack spacing={2}>
                    <FilterView sx={{ width: 1 }} />
                    <Grid container spacing={3} sx={{ pr: 5 }}>
                        <Repeat
                            fallback={
                                share ? (
                                    <Alert severity="error">
                                        There are no longer any valid Maintenance Regimes available using this link.
                                        Please contact the person who supplied you with the link.
                                    </Alert>
                                ) : (
                                    <Box flex={1}>
                                        <NoResults />
                                    </Box>
                                )
                            }
                            bind
                            list={FilterSchedule.call({ items: liveTrees.children })?.items}
                            item={<RegimeItem hideMenu go={go} />}
                        />
                    </Grid>
                </Stack>
            </Box>
        </PageBreadcrumbContainer>
    )

    function go(item) {
        navigate(`/app/facilities/${item.id}`)
    }
}
