import { Box } from "@mui/material"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"
import { HelpTag, HELPTAG_RECYCLE_BIN } from "routes/help/helpTag"
import { BinItems } from "routes/schedule/plugins/recycle-bin/components/bin-items"

export function Recycler() {
    return (
        <PageBreadcrumbContainer
            sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflow: "hidden",
                height: 1,
            }}
            tightWidth="lg"
            title={`Recycle Bin`}
        >
            <HelpTag tags={[HELPTAG_RECYCLE_BIN]} />
            <Box flex={1} flexBasis={2} sx={{ overflowY: "scroll" }} pt={1} pr={2}>
                <BinItems />
            </Box>
        </PageBreadcrumbContainer>
    )
}
