import { Box, IconButton, Link, Stack, TableCell, TableRow, Tooltip } from "@mui/material"
import { ScheduleSummary } from "routes/schedule/plugins/ai-summary/ai-summary.runtime"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"
import Iconify from "minimals-template/components/Iconify"
import { formatTime } from "lib/format-time"
import { Repeat } from "lib/@components/repeat"
import { TaskAsset } from "routes/asset-register/plugins/regime-display/task-asset"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { BlockClicks } from "lib/@components/block-clicks"
import { Secure } from "lib/authorization/secure"
import { MdEdit } from "@react-icons/all-files/md/MdEdit"
import { useDialog } from "lib/@hooks/useDialog"
import { TaskEditor } from "routes/asset-register/plugins/regime-display/task-editor"

export function TaskEntry({ item }) {
    const { parentItem } = useBoundContext()
    const planRoot = parentFind(parentItem, (c) => c[PlanRoot])
    const [group, scheduleId] = item.schedule.id.split("!")
    const identifier = `${item.where.split(" › ").slice(-2).join(" › ")}: ${item.id
        .split("!")
        .at(-1)
        .split(".")
        .unique()
        .join(".")}`
    const editDialog = useDialog(
        <TaskEditor identifier={identifier} scheduleId={`${group}~${planRoot.id}!${scheduleId}`} task={item.id} />,
        {
            maxWidth: "lg",
        }
    )

    return (
        !!item && (
            <TableRow>
                <TableCell
                    sx={{
                        minWidth: 200,
                        position: "sticky",
                        left: 0,
                        bgcolor: "background.default",
                        zIndex: 100,
                        boxShadow: "-1px 0px 0px 0px rgba(0,0,0,0.1) inset",
                    }}
                >
                    <Stack direction="row" spacing={0.5}>
                        <Box display="inline-block" mr={1} color={COLOR_FROM_CLASSIFICATION[item.classification]}>
                            <Iconify icon={ICON_FROM_CLASSIFICATION[item.classification]} />
                        </Box>

                        <Link onClick={openSchedule}>{identifier}</Link>
                    </Stack>
                </TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>
                    <Stack direction="row" spacing={1}>
                        <Box>{item.schedule?.title && `${item.schedule.title} #${item.schedule.code}`}</Box>
                        <ScheduleSummary id={item.schedule._id} />
                    </Stack>
                </TableCell>
                <TableCell>{item.skill}</TableCell>
                <TableCell align="right">{item.frequency.label}</TableCell>
                <TableCell align="right">{formatTime(item.minutes)}</TableCell>
                <TableCell>{item.classification}</TableCell>
                <TableCell align="right">
                    <Stack direction="row" spacing={0.5} sx={{ flexWrap: "nowrap", justifyContent: "flex-end" }}>
                        {item.assets.length < 4 && <Repeat list={item.assets} item={<TaskAsset />} />}
                        {item.assets.length >= 4 && `${item.assets.length} assets`}
                    </Stack>
                </TableCell>
                <TableCell
                    sx={{
                        position: "sticky",
                        right: 0,
                        zIndex: 100,
                        bgcolor: "background.default",
                        boxShadow: "1px 0px 0px 0px rgba(0,0,0,0.1) inset",
                    }}
                >
                    <BlockClicks>
                        <Secure demand="manageRegime">
                            <Stack direction="row" spacing={1}>
                                <Tooltip
                                    placement="top"
                                    title="Tailor task"
                                    arrow
                                    enterDelay={500}
                                    enterNextDelay={600}
                                >
                                    <IconButton size="small" color="primary" onClick={edit}>
                                        <MdEdit />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    title="Tailor schedule"
                                    arrow
                                    enterDelay={500}
                                    enterNextDelay={600}
                                >
                                    <IconButton size="small" color="primary" onClick={editDocument}>
                                        <Iconify icon="solar:document-add-bold" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Secure>
                    </BlockClicks>
                </TableCell>
            </TableRow>
        )
    )

    function openSchedule() {
        const [group, scheduleId] = item.schedule.id.split("!")
        navigate(`/app/schedules/${group}~${planRoot.id}!${scheduleId}?task=${item.id}`)
    }

    function editDocument() {
        const [group, scheduleId] = item.schedule.id.split("!")
        navigate(`/app/schedules/${group}~${planRoot.id}!${scheduleId}?task=${item.id}&editEx=true`)
    }

    async function edit() {
        await editDialog()
    }
}
