import { useReferenceState } from "lib/@hooks/use-reference"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { useEffect, useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import {
    Box,
    FormControlLabel,
    MenuItem,
    Select,
    Stack,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { SearchBoxUsingSearchParam } from "lib/@components/searchBoxUsingSearchParam"
import { setFromEvent } from "lib/setFromEvent"
import { defaultNumberOfRecords } from "routes/asset-register/default-number-of-records"
import { regimeTasks } from "routes/asset-register/plugins/regime-display/controller/regime-task-summary"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { RequestPageRepeat } from "lib/@components/request-page-repeat"
import { NoResults } from "lib/@components/no-results/no-results"
import { useDarkMode } from "lib/use-dark-mode"
import { TaskEntry } from "routes/asset-register/plugins/regime-display/task-entry"
import { useScrollRestoration } from "lib/use-scroll-restoration"
import { applyChangesToMany } from "routes/asset-register/plugins/regime-display/controller/apply-changes-to-many"
import { SortOn } from "routes/asset-register/plugins/regime-display/sort-on"
import { TasksBodyAndActionRow } from "routes/asset-register/plugins/regime-display/tasks-body-and-action-row"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

const loadingTasks = []
const loading = { items: loadingTasks, totalCount: 0 }

export function TaskView() {
    const [state] = useState({})
    const isDark = useDarkMode()
    const theme = useTheme()
    const { parentItem } = useBoundContext({ target: state })
    const [filters, setFilters] = useBoundValue("task_filters", [])
    const regimeId = parentFind(parentItem, (c) => c[PlanRoot])?.id
    const group = parentItem.id?.split("!").at(-1)
    const [take, setTake] = useReferenceState("task-page-size", 10, "task-page")
    const [skip, setSkip] = useSearchParam("skip", 0, (v) => +v)
    const [search] = useSearchParam("search", "")
    const [portal, setPortal] = useState()
    const scrollContainer = useScrollRestoration(undefined, 5000)
    const [sort] = useReferenceState("task-sort", [], "task-sort")

    const { items: tasks, total: totalCount } =
        regimeTasks.useResults({
            skip,
            take,
            regimeId,
            group,
            search,
            filters,
            sort,
        }) ?? loading

    useEffect(() => {
        if (tasks !== loadingTasks && tasks?.length === 0 && filters.length !== 0) {
            setFilters([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks, filters])

    return (
        <Bound
            target={state}
            updateAll={updateAll}
            paginationPortal={portal}
            regimeId={regimeId}
            group={group}
            totalCount={totalCount}
        >
            <Stack sx={{ width: 1, flexShrink: 1 }}>
                <ListItemBox spacing={1}>
                    <Box width="70%" flex={1} display="flex" flexDirection="column" alignSelf="stretch">
                        <ListItemBox spacing={2} sx={{ pt: 0.1 }}>
                            <SearchBoxUsingSearchParam
                                placeholder="Search..."
                                autoFocus
                                sx={{ flex: 1, mb: 1, mt: 1, py: 0, "& *": { fontSize: 13 } }}
                            />

                            <FormControlLabel
                                label={<Typography variant="body2">Rows per page: </Typography>}
                                labelPlacement="start"
                                sx={{ pb: 1 }}
                                control={
                                    <Select
                                        sx={{ mr: 2, ml: 2, fontSize: 13 }}
                                        variant="standard"
                                        labelId="rows-per-page-label"
                                        value={take}
                                        onChange={setFromEvent(setTake)}
                                    >
                                        {defaultNumberOfRecords.map((rowCountValue) => (
                                            <MenuItem key={rowCountValue} value={rowCountValue}>
                                                {rowCountValue}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                            />
                            <Box ref={setPortal} />
                        </ListItemBox>

                        <Box
                            width={1}
                            ref={scrollContainer}
                            sx={{
                                width: 1,
                                flex: 1,
                                maxHeight: "55vh",
                                overflow: "scroll",
                                "& .MuiTableCell-root": {
                                    fontSize: 13,
                                    whiteSpace: "nowrap",
                                    py: 0.1,
                                    borderRadius: "0 !important",
                                },
                                "& .MuiTableRow-root": {
                                    "&": {
                                        borderBottom: "1px solid " + theme.palette.action.hover,
                                    },
                                    "&:last-of-type": {
                                        borderBottom: "none",
                                    },
                                },
                            }}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            position: "sticky",
                                            top: 0,
                                            borderRadius: 1,
                                            bgcolor: "background.paper",
                                            zIndex: 202,
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                borderRadius: 0,
                                                position: "sticky",
                                                left: 0,
                                                top: 0,
                                                bgcolor: isDark ? "primary.darker" : "primary.lighter",
                                                zIndex: 201,
                                                borderRight: "1px solid",
                                                borderRightColor: "action.hover",
                                            }}
                                        >
                                            <SortOn field="fullPath">Id</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="title">Task</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="scheduleTitle">Schedule</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="skill">Skill</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="frequency.label">Frequency</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="minutes">Minutes</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="classification">Criticality</SortOn>
                                        </TableCell>
                                        <TableCell>Assets</TableCell>
                                        <TableCell
                                            sx={{
                                                borderRadius: 0,
                                                position: "sticky",
                                                right: 0,
                                                top: 0,
                                                zIndex: 201,

                                                bgcolor: isDark ? "primary.darker" : "primary.lighter",
                                            }}
                                        ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <RequestPageRepeat
                                    showTop
                                    pageSize={take}
                                    showBottom={false}
                                    fallback={
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <NoResults />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    refreshKey={search}
                                    setSkip={setSkip}
                                    Component={TasksBodyAndActionRow}
                                    list={tasks}
                                    item={<TaskEntry />}
                                    total={totalCount}
                                />
                            </Table>
                        </Box>
                    </Box>
                </ListItemBox>
            </Stack>
        </Bound>
    )

    function updateAll(source) {
        return applyChangesToMany({ source, search, filters, group, regimeId })
    }
}
