import { registerTab } from "lib/@components/tabs"

import { makeLazy } from "lib/make-lazy"
import rics from "./rics.png"
import uniclass from "./uniclass.png"

const Codes = makeLazy(() => import("routes/schedule/plugins/codes-tab/codes"), "Codes")

export const imageForClass = {
    rics,
    uniclass,
}

registerTab({
    tab: "ScheduleRightBar",
    id: "Codes",
    predicate(context) {
        return context.target.nrm?.length || context.target.uniclass?.length
    },
    content: <Codes priority={60} />,
})
