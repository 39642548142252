import Iconify from "minimals-template/components/Iconify"
import { appRoute } from "routes/app/home.runtime"
import { lazy } from "react"
import { ExtendedSchedule } from "routes/schedule/extendedSchedule"
import { isActiveWhen } from "routes/schedule/components/is-active-when"
import { hasDemand } from "lib/authorization/has-demand"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { HeaderBarRight } from "slot-definitions"
import { Selections } from "routes/schedule/components/selections"
import { getPredicate } from "routes/schedule/get-predicate"
import { and } from "lib/logic"
import { hasPermission } from "library/authorization"
import DemandGuard from "minimals-template/components/guards/DemandGuard"

HeaderBarRight.plug(
    <Selections
        if={and(hasPermission("schedules"), hasPermission("$!sharing"), ({ companionApp }) => !companionApp)}
        always="true"
        priority={2}
    />
)

const Schedules = lazy(() => import("routes/schedule/schedules"))
const Schedule = lazy(() => import("routes/schedule/schedule"))
const ScheduleHome = lazy(() => import("routes/schedule/scheduleHome"))

appRoute.register(
    "schedules",

    <DemandGuard demands={["user", "!sharing", "schedules"]} and>
        <Schedules>
            <ScheduleHome />
        </Schedules>
    </DemandGuard>
)
appRoute.register(
    "schedules/:id",
    <DemandGuard demands={["user", "!sharing", "schedules"]} and>
        <Schedules>
            <ExtendedSchedule>
                <Schedule />
            </ExtendedSchedule>
        </Schedules>
    </DemandGuard>
)

registerConditionalPageNavigation(
    () => hasDemand("user") && hasDemand("!sharing") && hasDemand("schedules"),
    "/app/schedules?id=schedules",
    "Schedule Library",
    <Iconify icon="ic:round-library-books" />,
    {
        group: "Schedules",
        isActive: isActiveWhen(getPredicate),
    }
)
