import { api } from "lib/graphql/api"
import { hasDemand } from "lib/authorization/has-demand"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getRelatedTreeCount = api(async function getRelatedTrees(id) {
    if (!hasDemand("manageRegime")) return 0
    return !id
        ? 0
        : query(
              gql`
                  query GetRelatedTreesCount($id: String!) {
                      getRelatedTrees(id: $id, take: 0) {
                          count
                      }
                  }
              `,
              { id },
              { returns: "getRelatedTrees.count" }
          )
})
