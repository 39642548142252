import { ActionItem } from "slot-definitions"
import { Avatar, Button, ListItemAvatar, Stack } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { uniqueId } from "library/database/split-id"
import { navigate } from "lib/routes/navigate"
import { confirm } from "lib/@dialogs/confirm"
import { updateScheduleInTree } from "routes/actions/plugins/tailored-schedule-out-of-date/controller/update-schedule-in-tree"
import { getGroupId } from "routes/schedule/lib/getGroupId"
import { getScheduleRecordId } from "library/get-unique-id-from-tree"
import { TruncatedListItemText } from "lib/@components/truncatedListItemText"

ActionItem("tailored-schedule-out-of-date").plug(<ReviewTreeSchedule />)

function ReviewTreeSchedule({ action }) {
    return (
        <>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: "myStuff.main", color: "myStuff.contrastText" }}>
                    <Iconify
                        icon={
                            action.payload.tailored
                                ? "fluent:document-arrow-up-16-filled"
                                : "fluent:document-arrow-up-16-regular"
                        }
                    />
                </Avatar>
            </ListItemAvatar>
            <TruncatedListItemText
                primary={`${action.payload.regime}: ${action.payload.where} › ${action.payload.scheduleTitle}`}
                secondary={`A schedule ${
                    action.payload.tailored ? "tailored " : "used "
                } in a Maintenance Regime has been updated.`}
            />
            <Stack direction="row" sx={{ flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                <Button sx={{ m: 0.5 }} onClick={approve} color="primary" variant="contained" size="small">
                    Confirm
                </Button>
                <Button sx={{ m: 0.5 }} variant="outlined" onClick={go} color="primary" size="small">
                    Schedule
                </Button>
                {action.payload.tailored && (
                    <Button sx={{ m: 0.5 }} variant="outlined" onClick={goRegime} color="primary" size="small">
                        Tailored Schedule
                    </Button>
                )}
            </Stack>
        </>
    )

    async function approve() {
        if (
            await confirm(
                "Are you sure you want to update the schedule in the maintenance regime to the latest version?",
                "Update Regime"
            )
        ) {
            await updateScheduleInTree(uniqueId(action.referenceId), action.payload.id, action.payload.tailored)
        }
    }

    function go() {
        navigate(`/app/schedules/${uniqueId(action.payload.schedule)}`)
    }

    function goRegime() {
        navigate(
            `/app/schedules/${getGroupId(action.payload.id)}~${uniqueId(action.referenceId)}!${getScheduleRecordId(
                action.payload.id
            )}`
        )
    }
}
