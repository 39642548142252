import { RegimeToolbar } from "slot-definitions"
import { Button, Menu, MenuItem, Portal } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Secure } from "lib/authorization/secure"
import { Frag } from "lib/@components/slot/frag"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { useRef, useState } from "react"
import { PlanRoot } from "routes/plan/plan-config/constants"

RegimeToolbar.plug(<ShareRegime if={({ parentItem }) => parentItem?.[PlanRoot]} priority={1} />)

function ShareRegime() {
    const { closeNow } = useBoundContext()
    const { parentItem } = useBoundContext()
    const baseId = parentItem.id.split("|").at(-1)
    const hasReady = useTreeItem(`ready|${baseId}`)
    const hasLive = useTreeItem(`live|${baseId}`)
    const hasBoth = hasReady && hasLive
    const [anchor, setAnchor] = useState(null)
    const ref = useRef()
    const disabled = !hasReady && !hasLive
    if (hasBoth) {
        return (
            <Secure sx={{ flex: 0 }} hide demand="canMakeShare" fallback={<Frag />}>
                <Button
                    onClick={() => setAnchor(ref.current)}
                    ref={ref}
                    startIcon={<Iconify icon="material-symbols:share" />}
                    color="primary"
                    endIcon={<Iconify icon="mdi:arrow-down-drop" />}
                >
                    Share
                </Button>
                <Portal>
                    <Menu sx={{ zIndex: 2100 }} open={!!anchor} anchorEl={anchor} onClose={() => setAnchor(null)}>
                        <MenuItem onClick={shareReady}>Share Candidate</MenuItem>
                        <MenuItem onClick={shareLive}>Share Live</MenuItem>
                    </Menu>
                </Portal>
            </Secure>
        )
    }
    return (
        <Secure sx={{ flex: 0 }} hide demand="canMakeShare" fallback={<Frag />}>
            <Button
                onClick={go}
                startIcon={<Iconify icon="material-symbols:share" />}
                color="primary"
                disabled={disabled}
            >
                Share {disabled ? "" : hasReady ? "Candidate" : "Live"}
            </Button>
        </Secure>
    )

    function shareReady() {
        closeNow()
        navigate(`/app/sharing?regime=ready|${baseId}`)
    }

    function shareLive() {
        closeNow()
        navigate(`/app/sharing?regime=live|${baseId}`)
    }

    function go() {
        if (hasReady) {
            shareReady()
        } else {
            shareLive()
        }
    }
}
