import { appRoute } from "routes/app/home.runtime"
import Schedules from "routes/schedule/schedules"
import { Plan } from "routes/plan/plan"
import { PlanToolbar, RegimeToolbar, TreeToolbar } from "slot-definitions"
import { ViewPlanButton } from "routes/plan/view-plan-button"
import { TaskActions } from "routes/plan/taskActions"
import { isInRegime } from "routes/facilities/lib/when-parent-item"
import { Stack } from "@mui/material"
import { If } from "lib/@components/switch"
import { registerFeature } from "lib/features"

const featureFlagDesc = `Show planning and conformity screens.`
export const FEATURE_FLAG_PLANNING = registerFeature({
    numericalId: 5,
    name: "Planning",
    id: "plan",
    description: featureFlagDesc,
})

TreeToolbar("*").plug(
    <If
        if={isInRegime}
        field="inMenu"
        then={<RegimeToolbar.Slot />}
        else={
            <Stack direction="row" priority={2000} ml={2}>
                <RegimeToolbar.Slot />
            </Stack>
        }
    />
)
RegimeToolbar.plug(<ViewPlanButton priority={40} if={isInRegime} />)
PlanToolbar.plug(<TaskActions priority={40} />)

appRoute.register(
    "plan/:id",
    <Schedules>
        <Plan />
    </Schedules>
)
