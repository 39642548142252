import { useState } from "react"
import { getRelatedTrees } from "./controller/get-related-trees"
import { TreeListComponent } from "lib/@components/tree-list/tree-list-component"

export function TreeList({ schedule }) {
    const [skip, setSkip] = useState(0)
    const derived = getRelatedTrees.useResults({
        id: schedule?._id,
        skip,
        take: 10,
    })

    return <TreeListComponent title={"Maintenance Regimes"} setSkip={setSkip} items={derived} />
}
