import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getFlagsForId } from "routes/schedule/plugins/flags/controller/flag-controller"
import { useTab } from "lib/@components/tabs"
import { Flags } from "routes/schedule/plugins/flags/components/flags"

export function FlagChecker() {
    const { schedule } = useBoundContext()
    const flags = getFlagsForId.useResults(schedule._id) ?? []
    useTab({ tab: "ScheduleRightBar", id: !!flags.length && "Flags", content: <Flags priority={120} /> })
    return null
}
