import { Button } from "@mui/material"
import { MdDownload } from "@react-icons/all-files/md/MdDownload"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useState } from "react"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import Iconify from "minimals-template/components/Iconify"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { useDialog } from "lib/@hooks/useDialog"
import { XmlOutputOptionsDialog } from "./xml-output-options-dialog"
import { downloadRegimeAsXml } from "./controller/download-regime-as-xml"

export function DownloadRegimeAsXml() {
    const [downloading, setDownloading] = useState(false)
    const { shareLink } = useBoundContext()
    const outputOptions = useDialog(<XmlOutputOptionsDialog />)
    return (
        !!shareLink?.canDownloadXml && (
            <Button
                fullWidth
                data-cy="download-xml-button"
                disabled={downloading}
                size="small"
                endIcon={downloading && <Iconify icon="ic:round-hourglass-empty" className="iconSpin" />}
                startIcon={<MdDownload />}
                sx={{ alignSelf: "center" }}
                onClick={download}
            >
                Download XML
            </Button>
        )
    )

    async function download() {
        const options = await outputOptions()
        if (options) {
            try {
                setDownloading(true)
                const xmlContent = await downloadRegimeAsXml(
                    shareLink._id,
                    options?.contentType,
                    options?.scheduleGroup
                )
                if (xmlContent) {
                    const blob = new Blob([xmlContent], { type: "application/xml" })
                    const link = document.createElement("a")
                    link.href = window.URL.createObjectURL(blob)
                    link.setAttribute("download", `${shareLink.name}.xml`)
                    link.click()
                    successSnackbar(`${shareLink.name}.xml created`)
                } else {
                    errorSnackbar("Could not find the data to download")
                }
            } catch (e) {
                errorSnackbar("Failed to download XML")
            } finally {
                setDownloading(false)
            }
        }
    }
}
