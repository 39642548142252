import { useMobile } from "lib/@components/mobile"
import { useParams } from "react-router-dom"
import { ScheduleBreadcrumbs } from "routes/schedule/components/schedule-breadcrumbs"
import { useStack } from "routes/schedule/lib/useStack"
import { UpDirectory } from "routes/schedule/tree/schedules/upDirectory"
import { ToolbarBox } from "lib/@components/toolbarBox"
import { TopBox } from "lib/@components/topBox"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ScheduleTopBox } from "slot-definitions"
import { navigate } from "lib/routes/navigate"

export function UpBar({ id, inContainer, url }) {
    const { hideUpBar, Crumbs = ScheduleBreadcrumbs, Up = UpDirectory } = useBoundContext()
    const { id: paramId } = useParams()
    id = id ?? paramId
    const isMobile = useMobile()
    const stack = useStack(id)
    return (
        !hideUpBar && (
            <ToolbarBox>
                <ScheduleTopBox.Slot inContainer={inContainer}>
                    <TopBox inContainer={inContainer}>
                        <Crumbs key={id} sx={{ ml: isMobile ? 0 : 1, mt: 0, mb: 1 }} current={id} />

                        <Up
                            sx={{
                                flex: 0,
                                lineHeight: 0,
                                mx: isMobile ? -2 : -1,
                                mt: 1,
                            }}
                            itemId={stack?.[0]}
                            onClick={pop}
                        />
                    </TopBox>
                </ScheduleTopBox.Slot>
            </ToolbarBox>
        )
    )

    function pop() {
        if (url) {
            navigate(url)
        } else {
            // navigate(getFromStack() ?? `/app/schedules?id=${stack[0]}`)
            window.history.back()
        }
    }
}
