import { registerTab } from "lib/@components/tabs"
import { ASSET_TAB_ID } from "routes/asset-register/asset-register"
import { Lazy } from "lib/make-lazy"
import { createEvent } from "library/local-events"

registerTab({
    tab: ASSET_TAB_ID,
    id: "types",
    title: "Type Mapping",
    content: (
        <Lazy importer={() => import("routes/asset-register/plugins/asset-mapping/mapping.js")} extract="Mapping" />
    ),
})

export const MappingUpdated = createEvent("MappingUpdated")
