import { Bound } from "lib/@components/binding/Bound"
import { useScheduleGroup } from "routes/facilities/lib/use-schedule-group"
import { Box, Card, CardContent, List, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ReadonlyUOM } from "routes/regime/assets/readonly-uom"
import { isEnabled } from "lib/@components/feature"

import { FEATURE_FLAG_ASSET_MAPPING } from "routes/asset-register/feature-flag-asset-mapping"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { AssociatedAsset } from "routes/regime/assets/associated-asset"

export function DisplayInventory() {
    const { schedule, payload } = useBoundContext()
    const name = useScheduleGroup()
    const unitsOfMeasurement = Object.keys(schedule.tasks.filter((t) => !t.isHeader).groupBy("unitOfMeasure")).filter(
        (c) => c !== "undefined"
    )

    if (!payload) return null
    payload.units ??= {}
    if (payload.units.Nr && !unitsOfMeasurement.includes("Nr")) {
        unitsOfMeasurement.push("Nr")
    }

    const display = isEnabled(FEATURE_FLAG_ASSET_MAPPING) || Object.values(payload.units).some((u) => u > 1 || !!u?.id)

    return (
        display && (
            <>
                {!payload.assets?.length && (
                    <Bound target={payload.units}>
                        <Box my={2}>
                            <Card>
                                <ListItemBox sx={{ bgcolor: "action.selected", pl: 2, pt: 1, pb: 1 }}>
                                    <Box>
                                        <Typography variant="subtitle2">{name}</Typography>
                                    </Box>
                                </ListItemBox>
                                <CardContent>
                                    {unitsOfMeasurement.map((uom) => (
                                        <ReadonlyUOM key={uom} uom={uom} />
                                    ))}
                                </CardContent>
                            </Card>
                        </Box>
                    </Bound>
                )}
                {!!payload.assets?.length && (
                    <Bound readOnly>
                        <Typography sx={{ mt: 1 }} variant="subtitle1" component="div" gutterBottom>
                            System Assets
                        </Typography>
                        <List>
                            <PagedRepeat
                                pageSize={5}
                                item={<AssociatedAsset />}
                                list={(payload.assets ?? []).map("id")}
                            />
                        </List>
                    </Bound>
                )}
            </>
        )
    )
}
