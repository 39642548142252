const { specialDemands } = require("library/permissions/special-demands")
const { DemandAudit } = require("library/server-event-definitions")

let defaultDemands = [
    {
        name: "scheduleEditor",
        group: "Schedules",
        productDemand: true,
        purpose: "Manage custom schedules",
        description: "User can access all schedules purchased and created by themselves and others in the company.",
    },
    {
        name: "publishSchedule",
        group: "Schedules",
        purpose: "Review and publish schedules",
        description: "Enables a user to review and publish schedules.",
    },
    {
        name: "advancedTailoring",
        group: "Regimes",
        isSpecial: true,
        purpose: "Advanced tailoring",
        description: "Gives user full schedule tailoring permissions.",
    },
    {
        name: "manageRegime",
        group: "Regimes",
        purpose: "Manage maintenance regimes",
        description: "User can create and edit maintenance regimes and can tailor schedules within them.",
        icon: "ic:round-edit",
    },
    {
        name: "multipleRegimes",
        group: "Regimes",
        isSpecial: true,
        purpose: "Manage multiple regimes",
        description: "User can create and edit multiple maintenance regimes.",
    },

    {
        name: "canMakeShare",
        group: "Regimes",
        purpose: "Create sharing links",
        description: "Enables a user to create and manage sharing links.",
        icon: "ic:round-share",
    },
    {
        name: "downloadPdf",
        group: "Regimes",
        isSpecial: true,
        purpose: "Enable PDF downloads",
        description: "Enable this user to download PDFs from share links.",
    },
    {
        name: "publishRegime",
        group: "Regimes",
        purpose: "Review and publish maintenance regimes",
        description: "Enables a user to review and publish maintenance regimes.",
        icon: "ic:round-manage-search",
    },
    {
        name: "clientAdmin",
        group: "Administrator",
        purpose: "Administration account",
        description:
            "Administration account - User can add users, create and edit Role permissions, update company Logo and edit custom skills.",
    },
    {
        name: "manageAccess",
        group: "Administrator",
        purpose: "Manage access settings",
        description: "Enables a user to grant access to items they themselves have access to.",
        icon: "ic:round-manage-accounts",
        feature: "regimePermissions",
    },
    {
        name: "accessAllRegimes",
        group: "Administrator",
        purpose: "Access all regimes",
        description:
            "Enables a user to have access to all regimes at all times, cannot be removed via the access screen.",
        icon: "ic:round-admin-panel-settings",
        feature: "regimePermissions",
        //grant: true,
    },
    {
        name: "technicalAuthor",
        group: "Super Admin",
        isSpecial: true,
        purpose: "Publish and edit technical updates",
        description: "Enables a user to be a Technical Author.",
    },
    {
        name: "authoringAdmin-exportSchedule",
        group: "Super Admin",
        isSpecial: true,
        purpose: "Export a schedule",
        description: "Enables a user to search and download a schedule.",
    },
    {
        name: "authoringAdmin-bulletinEditor",
        group: "Super Admin",
        isSpecial: true,
        purpose: "Create, edit or delete a technical / schedule update",
        description: "Enables a user to create, edit or delete a technical or schedule update.",
    },
]

if (process.env.ALL_DEMANDS) {
    defaultDemands.push({
        name: "staff",
        group: "SFG20 Admin",
        purpose: "SFG20 Staff Member",
        description: "This grants access to the SFG20-admin panel",
    })
}

defaultDemands = defaultDemands.concat(specialDemands)

DemandAudit.handle((props) => {
    props.demands = props.demands.filter((demand) => {
        const demandObject = defaultDemands.find((d) => d.name === demand)
        if (demandObject && demandObject.productDemand) {
            return props.productDemands.includes(demand)
        }
        return true
    })
})

exports.defaultDemands = defaultDemands

exports.defaultDemandLookup = defaultDemands.reduce((a, c) => ({ ...a, [c.name]: c }), {})
