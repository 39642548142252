import { useBoundContext } from "lib/@components/binding/use-bound-context"
import {
    Box,
    Button,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"
import { confirm } from "lib/@dialogs/confirm"
import {
    deleteRecyclerItem,
    restoreRecyclerItem,
} from "routes/schedule/plugins/recycle-bin/controller/recycle-bin-controller"
import { DATE_FORMATS } from "lib/@constants/date-format"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"

export function BinItem() {
    const { target } = useBoundContext()
    const daysLeft = Math.max(0, Math.floor(30 - new Date().daysSince(target.date)))
    return (
        <ListItem className="recycler-item">
            <ListItemBox>
                <ListItemIcon>
                    <Iconify icon={target.icon} />
                </ListItemIcon>
                <ListItemText
                    sx={{ flex: 1, wordBreak: "break-word" }}
                    primary={target.name}
                    secondary={Date.create(target.date).format(DATE_FORMATS.medium)}
                />
                <Box mr={1}>
                    <Typography variant="overline" color="text.primary">
                        {target.viewType}
                    </Typography>
                </Box>
                <Box mr={1} sx={{ minWidth: { xs: undefined, md: 110 } }}>
                    <Typography variant="caption" color="text.secondary">
                        Expires in {daysLeft} {"day".pluralize(daysLeft)}
                    </Typography>
                </Box>
                <Box>
                    <Stack direction="row" spacing={1}>
                        <Button onClick={remove} size="small" color="secondary" variant="outlined">
                            Delete
                        </Button>
                        <Tooltip title="Restore">
                            <IconButton className="restore" color="primary" onClick={restore}>
                                <Iconify icon="fluent-mdl2:remove-from-trash" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Box>
            </ListItemBox>
        </ListItem>
    )

    async function remove() {
        if (!(await confirm("Are you sure you want to permanently remove this item?", "Permanent Delete"))) return
        try {
            await deleteRecyclerItem(target._id)
            successSnackbar("Item successfully deleted", { key: target._id })
        } catch {
            errorSnackbar("Item could not be deleted")
        }
    }

    async function restore() {
        try {
            await restoreRecyclerItem(target._id)
            successSnackbar("Item successfully restored", { key: target._id })
        } catch (e) {
            if (e.message !== "Not found") {
                errorSnackbar("Item could not be restored")
            }
        }
    }
}
