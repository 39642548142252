import { useState } from "react"
import { assetsFromManyRegisters } from "routes/asset-register/controller/assets-from-many-registers"
import { useDialog } from "lib/@hooks/useDialog"
import { EditAssetDialog } from "routes/regime/assets/edit-asset-dialog"
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material"
import { BoundHTMLTypography } from "lib/@components/binding/bound-components"
import { ListItemBox } from "lib/@components/ListItemBox"
import { StandardSelector } from "lib/@components/combos/standard-selector"
import { MdSettings } from "@react-icons/all-files/md/MdSettings"
import { RequestPageRepeat } from "lib/@components/request-page-repeat"
import { AssetEntry } from "routes/asset-register/asset-entry"
import { Secure } from "lib/authorization/secure"
import { MdAdd } from "@react-icons/all-files/md/MdAdd"
import { busyWhile } from "lib/@components/busy"
import { updateAssetRegister } from "routes/asset-register/controller/update-asset-register"
import { assetSortOptions } from "routes/asset-register/asset-register"
import { useParams } from "react-router-dom"
import { CreateRegisterDialog } from "routes/regime/assets/create-register-dialog"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useRefresh } from "lib/@hooks/useRefresh"
import { SearchBoxUsingSearchParam } from "lib/@components/searchBoxUsingSearchParam"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { Bound } from "lib/@components/binding/Bound"
import { FilterList } from "routes/asset-register/filter-list"
import { setFromEvent } from "lib/setFromEvent"
import { useReferenceState } from "lib/@hooks/use-reference"
import { AssetActions } from "slot-definitions"
import { NoResults } from "lib/@components/no-results/no-results"
import { useDarkMode } from "lib/use-dark-mode"
import { defaultNumberOfRecords } from "routes/asset-register/default-number-of-records"

export function AssetList() {
    const { id } = useParams()
    const { target, refreshRegister } = useBoundContext()
    const [take, setTake] = useReferenceState("asset-page-size", 10)
    const refresh = useRefresh()
    const theme = useTheme()
    const [search] = useSearchParam("search")
    const [skip, setSkip] = useState(0)
    const [filters, setFilters] = useReferenceState("asset-filters" + id, {})
    const [sort, setSort] = useState(assetSortOptions[0].value)
    const { items = [], totalCount = 0 } =
        assetsFromManyRegisters.useResults({ registers: [id], skip, take, search, ...sort, ...filters }) ?? {}
    const createAssetDialog = useDialog(<EditAssetDialog register={id} />, { maxWidth: "md" })
    const editRegisterDialog = useDialog(<CreateRegisterDialog source={target} />)
    const [portal, setPortal] = useState()
    const isDark = useDarkMode()

    return (
        <Bound setFilters={setFilters} filters={filters} paginationPortal={portal}>
            <Stack spacing={2} sx={{ mt: 2 }}>
                <BoundHTMLTypography field="description" />
                <ListItemBox spacing={1}>
                    <Box flex={1} />
                    <StandardSelector options={assetSortOptions} value={sort} onChange={setSort} />
                    <IconButton color="primary" onClick={editAsset}>
                        <MdSettings />
                    </IconButton>
                </ListItemBox>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={2} sx={{ overflow: "hidden" }}>
                        <FilterList />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <ListItemBox spacing={2}>
                            <SearchBoxUsingSearchParam
                                placeholder="Search..."
                                autoFocus
                                sx={{ flex: 1, mb: 1, py: 0, "& *": { fontSize: 13 } }}
                            />

                            <FormControlLabel
                                label={<Typography variant="body2">Rows per page: </Typography>}
                                labelPlacement="start"
                                sx={{ pb: 1 }}
                                control={
                                    <Select
                                        sx={{ mr: 2, ml: 2, fontSize: 13 }}
                                        variant="standard"
                                        labelId="rows-per-page-label"
                                        value={take}
                                        onChange={setFromEvent(setTake)}
                                    >
                                        {defaultNumberOfRecords.map((rowCountValue) => (
                                            <MenuItem key={rowCountValue} value={rowCountValue}>
                                                {rowCountValue}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                            />
                            <Box ref={setPortal} />
                        </ListItemBox>
                        <TableContainer
                            sx={{
                                maxHeight: "55vh",
                                overflowX: "scroll",
                                "& .MuiTableCell-root": {
                                    whiteSpace: "nowrap",
                                    py: 0.1,
                                    borderRadius: "0 !important",
                                },
                            }}
                        >
                            <Table
                                size="small"
                                sx={{
                                    "& .MuiTableRow-root": {
                                        "&": {
                                            borderBottom: "1px solid " + theme.palette.action.hover,
                                        },
                                        "&:last-of-type": {
                                            borderBottom: "none",
                                        },
                                    },
                                }}
                            >
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            position: "sticky",
                                            top: 0,
                                            borderRadius: 1,
                                            bgcolor: "background.paper",
                                            zIndex: 202,
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                borderRadius: 0,
                                                position: "sticky",
                                                left: 0,
                                                top: 0,
                                                bgcolor: isDark ? "primary.darker" : "primary.lighter",
                                                zIndex: 201,
                                                borderRight: "1px solid",
                                                borderRightColor: "action.hover",
                                            }}
                                        >
                                            Tag
                                        </TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Facility</TableCell>
                                        <TableCell>Floor</TableCell>
                                        <TableCell>Space</TableCell>
                                        <TableCell>System</TableCell>
                                        <TableCell
                                            sx={{
                                                borderRadius: 0,
                                                position: "sticky",
                                                right: 0,
                                                top: 0,
                                                zIndex: 201,

                                                bgcolor: isDark ? "primary.darker" : "primary.lighter",
                                            }}
                                        ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <RequestPageRepeat
                                    showTop
                                    pageSize={take}
                                    showBottom={false}
                                    fallback={
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <NoResults />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    refreshKey={search}
                                    setSkip={setSkip}
                                    Component={TableBody}
                                    list={items}
                                    item={<AssetEntry register={id} />}
                                    total={totalCount}
                                />
                            </Table>
                        </TableContainer>
                        <Secure demand="manageRegime">
                            <ListItemBox spacing={1} sx={{ mt: 1 }}>
                                <AssetActions.Slot>
                                    <Button color="primary" startIcon={<MdAdd />} onClick={add}>
                                        Add Asset
                                    </Button>
                                </AssetActions.Slot>
                            </ListItemBox>
                        </Secure>
                    </Grid>
                </Grid>
            </Stack>
        </Bound>
    )

    async function editAsset() {
        const updated = await editRegisterDialog()
        if (updated) {
            await busyWhile(
                async () =>
                    updateAssetRegister({ registerId: id, name: updated.name, description: updated.description }),
                "Adding asset"
            )
            refreshRegister()
        }
    }

    async function add() {
        const asset = await createAssetDialog()
        if (asset) {
            await busyWhile(async () => updateAssetRegister({ registerId: id, updateAssets: [asset] }), "Adding asset")
            refresh()
        }
    }
}
