import { ListItem, ListItemIcon, ListItemText } from "@mui/material"
import { MdCircle } from "@react-icons/all-files/md/MdCircle"

export function Step({ item }) {
    return (
        <ListItem>
            <ListItemIcon>
                <MdCircle />
            </ListItemIcon>
            <ListItemText primary={item} />
        </ListItem>
    )
}
