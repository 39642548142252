import { Button } from "@mui/material"
import { TreeRootsUpdated } from "event-definitions"
import { PropertyBox } from "lib/@components/property-box"
import { NameDialog } from "lib/@dialogs/nameDialog"
import { useDialog } from "lib/@hooks/useDialog"
import { Secure } from "lib/authorization/secure"
import { navigate } from "lib/routes/navigate"
import Iconify from "minimals-template/components/Iconify"
import { createTree } from "routes/schedule/tree/schedules/controller/create-tree"
import { busyWhile } from "lib/@components/busy"
import { Frag } from "lib/@components/slot/frag"
import { useState } from "react"

export function AddRegime() {
    const getName = useDialog(<NameDialog title="Regime Name" placeholder="Enter a regime name..." />)
    const [disabled, setDisabled] = useState(false)
    return (
        <Secure demand="manageRegime" hide fallback={<Frag />}>
            <PropertyBox>
                <Button
                    onClick={addRegime}
                    variant="contained"
                    startIcon={<Iconify icon={"ic:round-add"} />}
                    disabled={disabled}
                    data-cy="add-regime-button"
                >
                    Add Regime
                </Button>
            </PropertyBox>
        </Secure>
    )
    async function addRegime() {
        const name = await getName()
        if (name) {
            setDisabled(true)
            try {
                const newTree = await busyWhile(
                    async () => createTree(name),
                    "Creating regime",
                    () => TreeRootsUpdated.wait(10000)
                )
                navigate(`/app/schedules?id=${newTree}`)
            } catch (e) {
                setDisabled(false)
            }
        }
    }
}
