import { AvatarGroup, useTheme } from "@mui/material"
import { UserAvatar } from "minimals-template/components/user-avatar"

export function UserAccessGroup({ max = 5, users = [], height = 24, width = 24, fontSize = "12px" }) {
    const theme = useTheme()

    if (users.length < 1) return null

    return (
        <AvatarGroup
            data-cy="user-access-group"
            max={max}
            slotProps={{
                additionalAvatar: {
                    sx: {
                        width,
                        height,
                        fontSize: `${fontSize} !important`,
                        backgroundColor: `${theme.palette.primary.light} !important`,
                    },
                },
            }}
        >
            {users.map((user) => (
                <UserAvatar
                    key={user.id}
                    user={user}
                    sx={{
                        width,
                        height,
                        "& .MuiTypography-root": {
                            fontSize,
                        },
                    }}
                />
            ))}
        </AvatarGroup>
    )
}
