import { Button } from "@mui/material"
import { MdAdd } from "@react-icons/all-files/md/MdAdd"
import { createConformityDocument } from "routes/conformity/controller/create-conformity-document"
import { uniqueId } from "library/database/split-id"
import { Secure } from "lib/authorization/secure"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Frag } from "lib/@components/slot/frag"

export function AddConformityButton() {
    const { parentItem } = useBoundContext()
    return (
        <Secure hide demand="create-conformity" fallback={<Frag />}>
            <Button size="small" variant="contained" startIcon={<MdAdd />} onClick={createConformity}>
                Add Conformity Document
            </Button>
        </Secure>
    )

    async function createConformity() {
        const id = await createConformityDocument(parentItem.id)
        if (id) {
            navigate(`/app/conformity/${uniqueId(id)}`)
        }
    }
}
