import { TaskNotes } from "slot-definitions"
import { TaskSteps } from "routes/schedule/plugins/ai-steps/task-steps"
import { registerFeature } from "lib/features"
import { Feature } from "lib/@components/feature"

export const CHECKLIST_FEATURE = registerFeature({
    numericalId: 8,
    name: "Check Lists",
    id: "check-list",
    description: `Show the checklist summaries of tasks in the schedule display.`,
    readyForBeta: true,
})

TaskNotes.plug(
    <Feature priority={200} feature={CHECKLIST_FEATURE}>
        <TaskSteps />
    </Feature>
)
