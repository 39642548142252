import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Alert, Stack, Typography } from "@mui/material"
import { ChooseAsset } from "routes/regime/assets/choose-asset"
import { getAssetRegistriesForRegime } from "routes/regime/assets/controller/get-asset-registries.controller"
import { Bound } from "lib/@components/binding/Bound"
import { AssociatedAsset } from "routes/regime/assets/associated-asset"

export function UomAsset({ index }) {
    const { assetId, currentId } = useBoundContext()
    const regimeId = currentId?.split("!")[0].split("~").at(-1)
    const assetRegisters = getAssetRegistriesForRegime.useResults(regimeId) ?? []
    if (!assetRegisters?.length) {
        if (!index) {
            return (
                <Alert severity="info">
                    Assign one or more asset registers to the maintenance regime in order to enter asset details
                </Alert>
            )
        }
        return null
    }

    return (
        <Bound assetRegisters={assetRegisters} regimeId={regimeId}>
            <Stack ml={2} my={1} direction="row" spacing={1} alignItems="center">
                <Typography variant="caption">{index + 1}.</Typography>
                {!assetId && <ChooseAsset />}
                {assetId && <AssociatedAsset item={assetId} />}
            </Stack>
        </Bound>
    )
}
