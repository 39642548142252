import { Backdrop, Box, Button, IconButton, Menu, Portal, Stack } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"
import { NotOnMobile, OnMobile } from "lib/@components/mobile"
import { prevent } from "lib/prevent"
import noop from "lib/noop"
import { useRef, useState } from "react"
import { BelowTreeToolbar, TreeToolbar, TreeToolbarLeft } from "slot-definitions"
import { ContextMenuBar } from "lib/@components/context-menu-bar"
import { ToolbarBox } from "lib/@components/toolbarBox"
import { Bound } from "lib/@components/binding/Bound"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { RenderIfHasContent } from "lib/render-as-html"

TreeToolbar("*").plug(<ContextMenuBar type="tree" priority={2200} />)

const popStack = []

export function addToStack(url) {
    url = url || window.location.pathname
    popStack.push(url)
}

export function getFromStack() {
    if (!popStack.length) return undefined
    return popStack.pop()
}

const disable = { disabled: true }

function isBackButtonDisabled() {
    return window.history.state?.disabled
}

function disableBackButton() {
    window.history.pushState(disable, "", window.location.href)
}

if (window.referrer) {
    disableBackButton()
}

export function BackButton({ onClick }) {
    return (
        !isBackButtonDisabled() && (
            <Box>
                <Button
                    size="small"
                    color="primary"
                    className="back-button"
                    onClick={onClick ?? (() => window.history.back())}
                    startIcon={<Iconify icon="ic:round-keyboard-backspace" />}
                    variant="contained"
                >
                    Back
                </Button>
            </Box>
        )
    )
}

export function UpDirectory({ sx, onClick }) {
    const [id] = useSearchParam("id", "")
    const [anchor, setAnchor] = useState(null)
    const ref = useRef()

    const treeToolBarItems = [...TreeToolbar.useItems(), ...TreeToolbar.useTypedItems(id)]
        .unique((k) => k.key + k.type?.name)
        .filter((s) => !s.props.always)
        .map((item, index) => ({ ...item, key: index }))

    return (
        <ToolbarBox sx={sx}>
            <ListItemBox height={44} pt={0.25}>
                <Box>
                    <BackButton onClick={onClick} />
                </Box>
                <TreeToolbarLeft.Slot type={id} />
                <Box flex={1} />
                <NotOnMobile>
                    <Stack>
                        <Stack
                            onClick={prevent(noop)}
                            onMouseDown={prevent(noop)}
                            onTouchStart={prevent(noop)}
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <TreeToolbar.Slot type={id} />
                        </Stack>
                        <BelowTreeToolbar.Slot type={id} />
                    </Stack>
                </NotOnMobile>
                <OnMobile>
                    <Box flex={1} />
                    <Stack>
                        <TreeToolbar.Slot type={id} predicate={(i) => i.props.always} />
                        <BelowTreeToolbar.Slot type={id} />
                    </Stack>
                    <RenderIfHasContent content={<TreeToolbar.Slot type={id} predicate={(c) => !c.props.always} />}>
                        <IconButton
                            onTouchStart={prevent(noop)}
                            size="small"
                            ref={ref}
                            onClick={prevent(show)}
                            data-cy="toolbar-menu-button"
                        >
                            <Iconify icon="prime:ellipsis-v" />
                        </IconButton>
                    </RenderIfHasContent>
                    <Bound
                        inMenu
                        autoExpandMenu
                        close={(fn) => () => {
                            console.log("Calling Close")
                            fn()
                            close()
                        }}
                        closeNow={close}
                    >
                        {!!anchor && (
                            <Portal>
                                <Backdrop
                                    onClick={prevent(close)}
                                    onMouseDown={prevent(noop)}
                                    onTouchStart={prevent(noop)}
                                    sx={{ zIndex: 2000 }}
                                    open={Boolean(anchor)}
                                >
                                    <Menu
                                        onClose={prevent(close)}
                                        open={Boolean(anchor)}
                                        sx={{
                                            zIndex: 2001,
                                            width: "fit-content(50em)",
                                            "& *": {
                                                fontWeight: "500 !important",
                                                fontSize: "14px !important",
                                            },
                                            "& button": {
                                                color: "text.primary",
                                                ml: "10px !important",
                                            },
                                            "& .menu-pad": {
                                                color: "text.primary",
                                                ml: "16px !important",
                                            },
                                        }}
                                        anchorEl={anchor}
                                    >
                                        <Stack
                                            onClick={prevent(noop)}
                                            onMouseDown={prevent(noop)}
                                            onMouseUp={prevent(noop)}
                                            spacing={1}
                                            sx={{ py: 0.5, px: 2 }}
                                            alignItems="flex-start"
                                        >
                                            {treeToolBarItems}
                                        </Stack>
                                    </Menu>
                                </Backdrop>
                            </Portal>
                        )}
                    </Bound>
                </OnMobile>
            </ListItemBox>
        </ToolbarBox>
    )

    function close() {
        setAnchor(null)
    }

    function show() {
        setAnchor(ref.current)
    }
}
