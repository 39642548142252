import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"
import { HelpTag, HELPTAG_ASSET_REGISTER } from "routes/help/helpTag"
import { Box, Link } from "@mui/material"
import { useParams } from "react-router-dom"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import { assetRegister } from "routes/asset-register/controller/asset-register"
import { navigate } from "lib/routes/navigate"
import { Feature } from "lib/@components/feature"
import { registerTab, Tabs } from "lib/@components/tabs"
import { AssetList } from "routes/asset-register/asset-list"
import { Watcher } from "lib/system/server-events/use-watch"
import { FEATURE_FLAG_ASSET_MAPPING } from "routes/asset-register/feature-flag-asset-mapping"

export const ASSET_TAB_ID = "asset-tabs"

export const assetSortOptions = [
    { value: { sort: "description", descending: false }, label: "Description" },
    { value: { sort: "description", descending: true }, label: "Description (desc)" },
    { value: { sort: "tag", descending: false }, label: "Tag" },
    { value: { sort: "tag", descending: true }, label: "Tag (desc)" },
    { value: { sort: "category", descending: false }, label: "Category" },
    { value: { sort: "category", descending: true }, label: "Category (desc)" },
    { value: { sort: "space", descending: false }, label: "Space" },
    { value: { sort: "space", descending: true }, label: "Space (desc)" },
    { value: { sort: "modified", descending: false }, label: "Modified" },
    { value: { sort: "modified", descending: true }, label: "Modified (desc)" },
    { value: { sort: "system", descending: false }, label: "System" },
    { value: { sort: "system", descending: true }, label: "System (desc)" },
    { value: { sort: "manufacturer", descending: false }, label: "Manufacturer" },
    { value: { sort: "manufacturer", descending: true }, label: "Manufacturer (desc)" },
]

export function AssetRegister() {
    const { id } = useParams()
    const [register, refreshRegister] = assetRegister.useResultsOnce.withRefetch(id) ?? []
    const refresh = useRefresh(noChange)
    return (
        <Watcher id={register}>
            <Bound refresh={refresh} target={register} register={register} refreshRegister={refreshRegister}>
                <PageBreadcrumbContainer
                    tightWidth="xl"
                    parentCrumbs={
                        <Link sx={{ cursor: "pointer" }} onClick={go}>
                            Asset Registers
                        </Link>
                    }
                    title={register?.name}
                    sx={{ height: 1 }}
                >
                    <HelpTag tags={[HELPTAG_ASSET_REGISTER]} />
                    <Feature feature={FEATURE_FLAG_ASSET_MAPPING}>
                        <Tabs id={ASSET_TAB_ID} panelHolder={<Box flex={1} />} />
                    </Feature>
                </PageBreadcrumbContainer>
            </Bound>
        </Watcher>
    )

    function go() {
        navigate("/app/assets")
    }
}

registerTab({
    tab: ASSET_TAB_ID,
    id: "asset list",
    title: "Assets",
    content: <AssetList />,
})
