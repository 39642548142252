import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { BoundTextField } from "lib/@components/binding/bound-components"
import { selectAll } from "lib/SelectAll"
import { SummaryDataChanged } from "event-definitions"
import { convertToMinMaxNumber } from "lib/number"
import { UOM_TO_NAME } from "routes/schedule/lib/uom-to-input"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { Asset } from "routes/regime/assets/asset"
import { Bound } from "lib/@components/binding/Bound"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getAssetRegistriesForRegime } from "routes/regime/assets/controller/get-asset-registries.controller"
import { Button } from "@mui/material"
import { useDialog } from "lib/@hooks/useDialog"
import { AssetDialog } from "routes/regime/assets/asset-dialog"
import { MdAdd } from "@react-icons/all-files/md/MdAdd"
import { Feature, isEnabled } from "lib/@components/feature"

import { FEATURE_FLAG_ASSET_MAPPING } from "routes/asset-register/feature-flag-asset-mapping"

export function UOM({ uom }) {
    const MAX_UNIT = 1000 // see WIN-1269
    const [number] = useBoundValue(uom, 1)
    const items = Array.from({ length: number }, (_, i) => i)
    const { currentId } = useBoundContext()
    const regimeId = currentId?.split("!")[0]?.split("~").at(-1)
    const assetRegisters = isEnabled(FEATURE_FLAG_ASSET_MAPPING)
        ? getAssetRegistriesForRegime.useResults(regimeId) ?? []
        : []

    return (
        <Bound uom={uom} assetRegisters={assetRegisters}>
            <BoundTextField
                autoFocus
                onFocus={selectAll}
                onChanged={() => SummaryDataChanged.raiseOnce()}
                key={uom}
                sx={{ my: 0.5 }}
                transformOut={convertToMinMaxNumber(0, MAX_UNIT)}
                field={uom}
                label={UOM_TO_NAME[uom]}
                default={1}
                data-cy="no-of-assets"
            />
            <Feature feature={FEATURE_FLAG_ASSET_MAPPING}>
                {["Nr", "System"].includes(uom) && (
                    <>
                        {!!assetRegisters.length && (
                            <PagedRepeat sx={{ mt: 2 }} pageSize={5} list={items} item={<Asset uom={uom} />} />
                        )}
                        {!!assetRegisters.length && <AddAsset />}
                    </>
                )}
            </Feature>
        </Bound>
    )
}

function AddAsset() {
    const { uom, assetRegisters, refresh, target } = useBoundContext()
    const addAsset = useDialog(<AssetDialog assetRegisters={assetRegisters} />)
    return (
        <Button startIcon={<MdAdd />} onClick={add}>
            Add Asset
        </Button>
    )

    async function add() {
        const asset = await addAsset()
        if (asset) {
            target[uom] += 1
            target[`${uom}_${target[uom]}`] = asset
            refresh()
        }
    }
}
