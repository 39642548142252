import { gql } from "@apollo/client"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { getScheduleRecordId } from "routes/schedule/lib/get-schedule-record-id"
import { mutate } from "lib/graphql/mutate"

export async function setGroupPayload(group, key, payload) {
    return mutate(
        gql`
            mutation setPayloadData($tree: String!, $group: String!, $key: String!, $payload: JSONObject!) {
                setGroupPayload(payload: $payload, tree: $tree, group: $group, key: $key)
            }
        `,
        {
            tree: getTreeIdFromItem(group),
            group: getScheduleRecordId(group.id),
            payload,
            key,
        }
    )
}
