import { Button } from "@mui/material"
import { MdClear } from "@react-icons/all-files/md/MdClear"
import Iconify from "minimals-template/components/Iconify"
import { AcceptAll, AcceptedChanged, CanSaveApprovedDocument, IsAccepted } from "event-definitions"
import { splitId } from "library/database/split-id"
import { useEffect, useState } from "react"
import { omit } from "lib/omit"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function SaveButton({ bottom }) {
    const {
        canSave,
        target,
        comparisons,
        save,
        saveFunction,
        commitFunctions,
        saveButtonCaption = "Save",
    } = useBoundContext()
    const [accept, setAccept] = useState({})
    const [registered, setRegistered] = useState({})
    AcceptedChanged.useEvent(({ id, accepted = false, uniqueId }) => {
        if (!id) return
        setAccept((accept) => {
            if (accepted === undefined) {
                if (registered[id] === uniqueId) {
                    return omit(accept, id)
                }
                return accept
            }
            setRegistered((r) => ({ ...r, [id]: uniqueId }))
            return { ...accept, [id]: accepted }
        })
    })
    IsAccepted.useEvent(
        (props) => {
            props.isAccepted = accept[props.id]
        },
        [accept]
    )
    const isAccepted = Object.values(accept).every(Boolean)
    useEffect(() => {
        CanSaveApprovedDocument.raise(isAccepted)
    })
    const someAccepted = Object.values(accept).some(Boolean)
    const saveFn = saveFunction ?? doSave
    return (
        (!bottom || comparisons.length > 4) &&
        !!canSave && (
            <>
                <Button
                    size="small"
                    disabled={!someAccepted}
                    onClick={rejectAll}
                    variant="outlined"
                    color="error"
                    startIcon={<MdClear />}
                >
                    Unapprove All
                </Button>
                <Button
                    size="small"
                    disabled={isAccepted}
                    onClick={acceptAll}
                    variant="outlined"
                    color="success"
                    startIcon={<Iconify icon="akar-icons:double-check" />}
                >
                    Approve All
                </Button>
                <Button size="small" onClick={saveFn} variant="contained" color="secondary" disabled={!isAccepted}>
                    {saveButtonCaption}
                </Button>
            </>
        )
    )

    async function doSave() {
        for (const fn of commitFunctions.values()) {
            fn(target)
        }
        await save(target)
        navigate(`/app/schedules/${splitId(target._id).id}/edit`, { replace: true })
    }

    function acceptAll() {
        AcceptAll.raise(true)
    }

    function rejectAll() {
        AcceptAll.raise(false)
    }
}
