const stringSimilarity = require("string-similarity")

exports.compareStrings = (target, search, match = 0.95) => {
    if (!search) return true
    target = target
        .toLowerCase()
        .trim()
        .replace(/(\s|\W)+/g, " ")
        .replace(/z/g, "s")
        .replace(/y/g, "i")
        .replace(/[aeiou]/g, "a")
        .replace(/[^\w\s]|(.)(?=\1)/gi, "")
    search = search
        .toLowerCase()
        .trim()
        .replace(/(\s|\W)+/g, " ")
        .replace(/z/g, "s")
        .replace(/y/g, "i")
        .replace(/[aeiou]/g, "a")
        .replace(/[^\w\s]|(.)(?=\1)/gi, "")

    const set1 = target.split(" ")
    return search
        .split(" ")
        .every((c) => set1.some((s) => stringSimilarity.compareTwoStrings(c, s.slice(0, c.length)) >= match))
}
