import { ListItemBox } from "lib/@components/ListItemBox"
import { Box, Checkbox, useTheme } from "@mui/material"
import { createSelectionFromItem } from "routes/asset-register/plugins/regime-display/create-selection-from-item"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Iconify from "minimals-template/components/Iconify"
import Color from "color"

export function ActionItem({ item, index, filters, setFilters }) {
    const { currentFilters, selectionColor, transformValue = (v) => v } = useBoundContext()
    const selection = createSelectionFromItem(item)
    const theme = useTheme()
    const selected = currentFilters.has(selection)

    return (
        <ListItemBox spacing={2}>
            <Checkbox size="small" checked={selected} onClick={change} />
            <Box flex={1}>{transformValue(item.value[0]) || "Empty"}</Box>
            <Box minWidth={60} textAlign="right" sx={{ opacity: selected ? 1 : 0.4 }}>
                {item.count}
            </Box>
            <Box
                sx={{
                    color: item.onChart
                        ? selectionColor(selected, index, item.value[0])
                        : Color(theme.palette.background.default).darken(0.1).hex(),
                }}
            >
                <Iconify icon="fluent:square-12-filled" />
            </Box>
        </ListItemBox>
    )

    function change() {
        if (selected) {
            setFilters(filters.filter((f) => !Object.isEqual(f, selection)))
        } else {
            setFilters((filters) => [...filters, selection])
        }
    }
}
