import { IconButton, Tooltip } from "@mui/material"
import { MdHome } from "@react-icons/all-files/md/MdHome"
import { RefreshNavBar } from "event-definitions"
import { navigate } from "lib/routes/navigate"
import { IconButtonAnimate } from "minimals-template/components/animate"
import { debugInfo } from "minimals-template/components/debug-component/temp-debug-window"
import Iconify from "minimals-template/components/Iconify"

import { AuthTemplateHeader, HeaderBarRight } from "slot-definitions"
import { plug } from "lib/@components/slot/plug"
import { useSearchParam } from "lib/@hooks/use-search-param"

plug("Header.Left", <Home />)
HeaderBarRight.plug(<DebugButton priority={-1000} />)
AuthTemplateHeader.plug(<DebugButton priority={-1000} />)
function Home() {
    return (
        <IconButton sx={{ color: "primary.contrastText" }} onClick={() => navigate("/")}>
            <MdHome />
        </IconButton>
    )
}

function DebugButton() {
    const [debug] = useSearchParam("debug", false)
    const showDebug = process.env.REACT_APP_DEBUG || localStorage.getItem("sfg20-DebugWindow") || debug

    RefreshNavBar.useRefresh()
    if (!showDebug) return null
    return (
        <Tooltip title="Debug Information">
            <IconButtonAnimate onClick={debugInfo} color={"secondary"}>
                <Iconify icon="carbon:debug" />
            </IconButtonAnimate>
        </Tooltip>
    )
}
