import React from "react"

let lastRenderTime = Date.now()
let lastStack = ""

if (window.Cypress) {
    const { useState, useRef } = React

    const originalRender = React.Component.prototype.render

    // Define a new render method
    React.Component.prototype.render = function () {
        lastRenderTime = Date.now()
        // Call the original render method
        return originalRender.apply(this, arguments)
    }

    React.useRef = (...params) => {
        lastRenderTime = Date.now()
        return useRef(...params)
    }

    React.useState = (...params) => {
        lastRenderTime = Date.now()
        return useState(...params)
    }
}

export function resetRenderTime() {
    lastRenderTime = Date.now()
}

export function getLastRenderTime() {
    return lastRenderTime
}

export function getLastStack() {
    return lastStack
}

export function updateStack() {
    const error = new Error()
    lastStack = error.stack
    return lastStack
}
