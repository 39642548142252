import { Box } from "@mui/material"
import { useMobile } from "lib/@components/mobile"
import { HelpTag, HELPTAG_SCHEDULE_LIBRARY } from "routes/help/helpTag"
import { NotInSelector } from "routes/schedule/my-schedules/my-custom-schedules/select-schedule"
import { SearchBox } from "routes/schedule/plugins/welcome/search-box"
import { Summaries } from "routes/schedule/plugins/welcome/summaries"
import { ScheduleSelectorBeforeList } from "slot-definitions"

ScheduleSelectorBeforeList.subType("schedules").plug(<Welcome />)
ScheduleSelectorBeforeList.subType("schedules").plug(<HelpTag tags={[HELPTAG_SCHEDULE_LIBRARY]} />)

function Welcome() {
    const isMobile = useMobile()
    const padding = isMobile ? 2 : 8
    return (
        <NotInSelector>
            <Box mb={3} pl={padding} pr={padding} data-cy="welcome">
                <Box maxWidth={800} mx="auto">
                    <Summaries />
                    <SearchBox />
                </Box>
            </Box>
        </NotInSelector>
    )
}
