import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { maintenanceReports } from "routes/regime/maintenance-reports/controller/maintenance-reports"
import { Bound } from "lib/@components/binding/Bound"
import { useState } from "react"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { StatsTabs } from "slot-definitions"
import { Tab } from "@mui/material"
import { isEnabled } from "lib/@components/feature"
import { FEATURE_FLAG_COMPANION_TASKS } from "routes/sharing/sharing.runtime"

export function CheckForReports({ children }) {
    const { parentItem } = useBoundContext()
    const { id: regime } = parentFind(parentItem, (p) => p[PlanRoot])
    const [, group] = parentItem.id.split("!")
    const [take, setTake] = useState(10)
    const [skip, setSkip] = useState(0)
    const [done, setDone] = useState(false)
    const [notDone, setNotDone] = useState(false)
    const [search] = useSearchParam("search")
    const reports = maintenanceReports.useResults({ regime, group, skip, take, search, done, notDone })
    StatsTabs.usePlug(
        <Tab
            priority={2601}
            value="MaintenanceReports"
            label="Problem Reports"
            if={() => (isEnabled(FEATURE_FLAG_COMPANION_TASKS) && reports?.total > 0) || search}
        />,
        [reports]
    )

    return (
        <Bound
            regime={regime}
            folder={group}
            setTake={setTake}
            setSkip={setSkip}
            reports={reports}
            done={done}
            notDone={notDone}
            setNotDone={setNotDone}
            setDone={setDone}
        >
            {children}
        </Bound>
    )
}
