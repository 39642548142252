const { uniqueIdFromTreeId } = require("library/unique-id-from-tree-id.js")

function wrapParagraph(item) {
    if (!item) return ""
    if (item.startsWith("<p>")) return item
    return `<p>${item}</p>`
}

const validCountUnits = ["Nr"]

exports.extendSchedule = (schedule, payload, doNotRemoveTasks, key) => {
    if (!payload || !schedule) return
    if (payload.uniqueCode && !schedule.$code) {
        schedule.code = `${schedule.code}-${`${payload.uniqueCode}`.padLeft(4, "0")}`
        schedule.$code = true
    }
    if (schedule.intro) {
        schedule.intro.content = `${wrapParagraph(payload.introduction)}${wrapParagraph(schedule.intro.content)}`
        schedule.intro.note = `${wrapParagraph(schedule.intro.note)}${wrapParagraph(payload.notes)}`
    }
    Object.entries(payload.units ?? {})
        .filter(isAssetInfo)
        .forEach(decorateSchedule)

    if (!schedule.tasks) {
        schedule.tasks = []
    }

    const units = schedule.tasks
        .map("unitOfMeasure")
        .unique()
        .filter((f) => validCountUnits.includes(f))
    schedule.assets = units
        .map((unit) => {
            const number = +(payload.units?.[unit] || 1)
            const output = []
            for (let i = 0; i < number; i++) {
                const asset = payload.units?.[`${unit}_${i + 1}`]
                const validAsset = asset && typeof asset === "string" ? asset : undefined
                output.push(validAsset || `*${key}.${i + 1}`)
            }
            return output
        })
        .flat(Infinity)
    schedule.units = payload.units
    schedule.systemAssets = payload.assets
    schedule.tasks = schedule.tasks
        .map((t) => ({ ...t }))
        .map((task) => {
            if (!task) return null
            const payloadTask = payload?.tasks?.[task.id]
            if (!payloadTask) return task
            if (payloadTask.included === false && !doNotRemoveTasks) {
                return null
            }
            task.classification = payloadTask.classification ?? task.classification
            task.unitOfMeasure = payloadTask.unitOfMeasure ?? task.unitOfMeasure
            const originalMinutes = task.minutes
            task.minutes = (payloadTask?.minutes ?? task.minutes ?? 0) * +(payload.units?.[task.unitOfMeasure] ?? 1)
            if ((payloadTask?.minutes ?? originalMinutes ?? 0) !== originalMinutes) {
                task.$showMinutes = true
            }
            task.count = validCountUnits.includes(task.unitOfMeasure) ? payload.units?.[task.unitOfMeasure] ?? 1 : 1
            task._minutes = originalMinutes
            task.frequency = payloadTask.frequency ?? task.frequency
            task.content = `${wrapParagraph(payloadTask.introduction)}${wrapParagraph(task.content)}`
            task.note = `${wrapParagraph(task.note)}${wrapParagraph(payloadTask.notes)}`
            task.skillSet = payloadTask.skillSet ?? task.skillSet
            return task
        })
        .filter(Boolean)

    function decorateSchedule([key, value]) {
        schedule[key] = value
    }
}

function isAssetInfo([key]) {
    return key.startsWith("Nr_") || key.startsWith("System_")
}

exports.payloadKey = (tree, group, key = "default") => `${uniqueIdFromTreeId(group)}!${uniqueIdFromTreeId(key)}`
