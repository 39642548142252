import { registerTab } from "lib/@components/tabs"

function Permissions() {
    return <div>Permissions</div>
}

//eslint-disable-next-line
registerTab({
    tab: "AdminTabContainer",
    id: "Permissions",
    predicate() {
        return true
    },
    content: <Permissions priority={30} />,
})
