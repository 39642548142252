import { ActionMenu, ScheduleSelectorBeforeList, ScheduleSelectorToolbar } from "slot-definitions"
import "routes/schedule/folder-manager/add-folder"
import "routes/schedule/folder-manager/copy-folder"
import "routes/schedule/folder-manager/move-folder"
import "routes/schedule/folder-manager/rename-folder"

import { makeLazy } from "lib/make-lazy"
import { EDIT_TREE, LIVE_TREE } from "library/constants"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { createSlot } from "lib/@components/slot/create-slot"

const FolderManager = makeLazy(() => import("routes/schedule/folder-manager/folder-manager"), "FolderManager")
const FolderWelcome = makeLazy(() => import("routes/schedule/folder-manager/folder-welcome"), "FolderWelcome")

ScheduleSelectorToolbar("*").plug(<FolderManagementSystem />)

ScheduleSelectorBeforeList("*").plug(<FolderWelcome />)

export const RegimeWelcome = createSlot("RegimeWelcome")

function FolderManagementSystem() {
    const { parentItem } = useBoundContext()
    return parentItem?.folderHandling && !parentItem[PlanRoot] ? (
        <ActionMenu.Plug>
            <FolderManager />
        </ActionMenu.Plug>
    ) : null
}

export function convertFolderName(id) {
    const parts = id.split("!")

    if (parts.length === 1) {
        if (id === LIVE_TREE) return "_root"
        if (id === EDIT_TREE) return "_root"
        if (id === "_root") return EDIT_TREE
        return id
    }
    if (parts[0] === EDIT_TREE) parts[0] = "_root"
    if (parts[0] === "_root") parts[0] = EDIT_TREE
    return parts.join("!")
}
