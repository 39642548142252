import { Box, IconButton, InputAdornment } from "@mui/material"
import { MdClear } from "@react-icons/all-files/md/MdClear"
import Iconify from "minimals-template/components/Iconify"
import { DebouncedTextField } from "../debounced-text-field"
import { Frag } from "../slot/frag"
import { TabledRepeatSearchRight } from "slot-definitions"
import { useEffect } from "react"

export function TabledRepeatSearchBar({ search, setSearch, searchKeys = [], setPage }) {
    useEffect(() => {
        setPage(1)
    }, [search, setPage])

    if (searchKeys.length === 0) return <Frag />
    return (
        <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
            <SearchBox search={search} setSearch={setSearch} searchKeys={searchKeys} />
            <TabledRepeatSearchRight.Slot />
        </Box>
    )
}

function SearchBox({ search, setSearch, searchKeys }) {
    const searchKeyTitles = searchKeys.map((sk) => sk.title)
    let searchByString = ""
    if (searchKeys.length === 1) searchByString = searchKeyTitles[0]
    if (searchKeys.length === 2) searchByString = searchKeyTitles.join(" or ")
    if (searchKeys.length > 2)
        searchByString = searchKeyTitles.slice(0, -1).join(", ") + " or " + searchKeyTitles.slice(-1)

    return (
        <DebouncedTextField
            variant="outlined"
            fullWidth
            value={search}
            InputProps={{
                endAdornment: search !== "" && (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setSearch("")}
                            size="small"
                            data-cy="tabled-repeat-search-box-clear-button"
                        >
                            <MdClear />
                        </IconButton>
                    </InputAdornment>
                ),
                startAdornment: (
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" />
                    </InputAdornment>
                ),
            }}
            placeholder={`Search by ${searchByString}`}
            onChange={handleSearch}
            data-cy="tabled-repeat-search-box"
        />
    )
    function handleSearch(e) {
        setSearch(e.target.value)
    }
}

export function searchItems(search, items, searchKeys) {
    return items.filter((i) => buildSearchString(i).includes(search.toLowerCase()))

    function buildSearchString(item) {
        let string = ""
        searchKeys?.map((sk) => {
            string += item[sk.key]?.toString() + " "
        })
        return string.toLowerCase()
    }
}
