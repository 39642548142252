import { TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { ActionFor } from "routes/asset-register/plugins/regime-display/action-for"
import { summariseTasks } from "routes/asset-register/plugins/regime-display/controller/summarise-tasks"
import { COLOR_FROM_CLASSIFICATION } from "library/SFG20/constants"
import { MemoryWidthTableCell } from "routes/asset-register/plugins/regime-display/memory-width-table-cell"

export function TasksBodyAndActionRow({ children }) {
    return (
        <TableBody>
            <TableRow>
                <MemoryWidthTableCell
                    name="id"
                    align="right"
                    sx={{
                        minWidth: 200,
                        position: "sticky",
                        left: 0,
                        bgcolor: "background.default",
                        zIndex: 100,
                        boxShadow: "-1px 0px 0px 0px rgba(0,0,0,0.1) inset",
                    }}
                >
                    <Typography variant="caption" color="primary.dark">
                        Filters
                    </Typography>
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="title">
                    <ActionFor field="title" method={summariseTasks} />{" "}
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="schedule">
                    <ActionFor field="schedule.title" method={summariseTasks} />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="skill">
                    <ActionFor field="skill" method={summariseTasks} />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell align="right" name="frequency">
                    <ActionFor field="frequency.label" method={summariseTasks} />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell align="right" name="minutes">
                    <ActionFor field="minutes" method={summariseTasks} />
                </MemoryWidthTableCell>
                <MemoryWidthTableCell name="classification">
                    <ActionFor field="classification" method={summariseTasks} color={classificationColors} />
                </MemoryWidthTableCell>
                <TableCell></TableCell>
                <TableCell
                    align="center"
                    sx={{
                        position: "sticky",
                        right: 0,
                        zIndex: 100,
                        bgcolor: "background.default",
                        boxShadow: "1px 0px 0px 0px rgba(0,0,0,0.1) inset",
                    }}
                />
            </TableRow>
            {children}
        </TableBody>
    )
}

function classificationColors(selected, index, classification) {
    return COLOR_FROM_CLASSIFICATION[classification]
}
