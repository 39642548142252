import { gql, useQuery } from "@apollo/client"
import { LoadingButton } from "@mui/lab"
import {
    Alert,
    Box,
    Checkbox,
    Container,
    FormControlLabel,
    IconButton,
    InputAdornment,
    List,
    ListItemButton,
    TextField,
    Typography,
} from "@mui/material"
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight"
import { MdClear } from "@react-icons/all-files/md/MdClear"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Loader } from "lib/@components/Loader"
import { useMobile } from "lib/@components/mobile"
import { setFromEvent } from "lib/setFromEvent"
import { If } from "lib/@components/switch"
import { useReference } from "lib/@hooks/use-reference"
import { useState } from "react"
import uniqolor from "uniqolor"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { navigate } from "lib/routes/navigate"
import { plug } from "lib/@components/slot/plug"

plug("HomePage.Item", <SearchBox priority={50} xs={12} />)

const GET_SCHEDULES = gql`
    query Schedules($query: String!, $type: SearchType) {
        scheduleSearch(query: $query, limit: 250, type: $type) {
            count
            schedules {
                _id
                id
                version
                code
                title
                notLicensed
                coreTagDescriptions
            }
        }
    }
`

function keyByCode(item) {
    return item?.code
}

function SearchBox() {
    const [reference, save] = useReference({}, "search")
    const [search, setSearch] = useState(reference.search ?? "")
    const [query, setQuery] = useState(reference.query ?? "")
    const [titleOnly, setTitleOnly] = useState(reference.titleOnly ?? true)
    const [available, setAvailable] = useState(reference.available ?? false)
    const [page, setPage] = useState(reference.page ?? 1)
    const isMobile = useMobile()
    reference.query = query
    reference.search = search
    reference.titleOnly = titleOnly
    reference.available = available
    reference.page = page

    save()

    const { loading, error, data } = useQuery(GET_SCHEDULES, {
        variables: {
            query,
            type: "TitleAndContent",
        },
    })
    return (
        <Container>
            <Box
                width={isMobile ? "calc(100% + 16px)" : 1}
                ml={isMobile ? -1 : 0}
                mr={isMobile ? -1 : 0}
                pr={isMobile ? 0 : 2}
                pl={isMobile ? 0 : 2}
            >
                <Typography variant="overline" component="div">
                    Search
                </Typography>
                <TextField
                    onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                            runSearch()
                            ev.preventDefault()
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Box display="flex" alignItems="center">
                                    <If truthy={data && available}>
                                        <Box mr={1}>
                                            <Typography variant="caption">
                                                {data?.scheduleSearch?.schedules?.length ?? 0} schedules
                                            </Typography>
                                        </Box>
                                    </If>
                                    <If truthy={search.length}>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                setAvailable(false)
                                                setSearch("")
                                                setQuery("")
                                            }}
                                        >
                                            <MdClear />
                                        </IconButton>
                                    </If>
                                    <LoadingButton
                                        variant="outlined"
                                        loading={loading}
                                        onClick={runSearch}
                                        color="primary"
                                    >
                                        Search
                                    </LoadingButton>
                                </Box>
                            </InputAdornment>
                        ),
                    }}
                    value={search}
                    onChange={setFromEvent((v) => {
                        setSearch(v)
                        setAvailable(false)
                    })}
                    placeholder="Search..."
                    fullWidth
                />
                <ListItemBox>
                    <Box flex={1} />
                    <FormControlLabel
                        control={<Checkbox checked={!!titleOnly} onClick={() => setTitleOnly((v) => !v)} />}
                        label="Search title only"
                    />
                </ListItemBox>
                <If
                    truthy={loading}
                    then={<Loader />}
                    else={
                        <If
                            truthy={error}
                            then={
                                <Box mt={1} mb={1}>
                                    <Alert severity="error">{error?.toString()}</Alert>
                                </Box>
                            }
                            else={
                                <If truthy={data && available}>
                                    <Box sx={{ mt: 1 }}>
                                        <List dense>
                                            <PagedRepeat
                                                onPageChange={setPage}
                                                page={page}
                                                pageSize={5}
                                                keyFn={keyByCode}
                                                list={data?.scheduleSearch.schedules.unique("code")}
                                                item={<ScheduleTitle />}
                                            />
                                        </List>
                                    </Box>
                                </If>
                            }
                        />
                    }
                />
            </Box>
        </Container>
    )

    function runSearch() {
        setAvailable(true)
        setQuery(search)
    }
}

function extractType(code) {
    const [result] = [...code.matchAll(/([0-9a-zA-Z]+)-([0-9a-zA-Z]+)/g)]
    return result?.[1] ?? ""
}

function ScheduleTitle({ item }) {
    return (
        <ListItemButton sx={{ mr: 0, pr: 0 }} onClick={() => navigate(`/schedule/${item.id}`)} divider>
            <ListItemBox ml={-1} mr={-1} width="calc(100% + 24px)">
                <Box
                    sx={{
                        mr: 1,
                        ml: -2,
                        mt: -0.2,
                        borderRadius: 1,
                        mb: -0.2,
                        alignSelf: "stretch",
                        width: 16,
                        backgroundColor: uniqolor(+extractType(item.code) * 21269, {
                            saturation: [75, 99],
                            lightness: [50, 80],
                        }).color,
                    }}
                />
                <Box mr={1} width={80}>
                    {item.code}
                </Box>
                <Box flex={1}>{item.title}</Box>
                <Box>
                    <IconButton color="primary">
                        <MdChevronRight />
                    </IconButton>
                </Box>
            </ListItemBox>
        </ListItemButton>
    )
}
