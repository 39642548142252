import { Alert, Box, Button } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { NotEditMode } from "lib/@components/edit-mode"
import { InPdf } from "lib/@components/pdf/in-pdf"
import { NotInPdf } from "lib/@components/pdf/not-in-pdf"
import { useReferenceState } from "lib/@hooks/use-reference"
import Iconify from "minimals-template/components/Iconify"
import { ScheduleIntroductionContentAfter } from "slot-definitions"
import { OverarchingIntroductionText } from "./overarching-introduction-text"
import { OVERARCHING_INTRODUCTION } from "library/SFG20/constants"

ScheduleIntroductionContentAfter.plug(<OverarchingIntroduction />)

function OverarchingIntroduction() {
    const { code } = useBoundContext()
    const [showInfo, setShowInfo] = useReferenceState("showOverarchingIntroductionInfo", true)

    const isOverarchingSchedule = +code === +OVERARCHING_INTRODUCTION

    return (
        !isOverarchingSchedule && (
            <NotEditMode>
                <NotInPdf>
                    {showInfo ? (
                        <Alert
                            severity="info"
                            onClose={handleClose}
                            data-cy="overarching-introduction-info"
                            sx={{ mt: 2 }}
                        >
                            <OverarchingIntroductionText
                                overarchingIntroductionId={OVERARCHING_INTRODUCTION}
                                variant="body2"
                            />
                        </Alert>
                    ) : (
                        <Button
                            variant="outlined"
                            endIcon={<Iconify icon="ic:round-chevron-right" />}
                            onClick={handleShowInfo}
                            sx={{ mt: 2 }}
                        >
                            Overarching Introduction Information
                        </Button>
                    )}
                </NotInPdf>
                <InPdf>
                    <Box sx={{ mt: 2 }}>
                        <OverarchingIntroductionText overarchingIntroductionId={OVERARCHING_INTRODUCTION} />
                    </Box>
                </InPdf>
            </NotEditMode>
        )
    )

    function handleClose() {
        setShowInfo(false)
    }

    function handleShowInfo() {
        setShowInfo(true)
    }
}
