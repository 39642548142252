import { useSchedule } from "routes/schedule/lib/useSchedule"
import { Bound } from "lib/@components/binding/Bound"

export function ScheduleHolder({ scheduleId, children, task }) {
    const { schedule } = useSchedule(scheduleId)
    const taskImpl = schedule?.tasks?.find((t) => task.endsWith(t.id))
    return (
        !!schedule && (
            <Bound task={taskImpl} schedule={schedule}>
                {children}
            </Bound>
        )
    )
}
