import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function setFavourites(favourites) {
    return mutate(
        gql`
            mutation SetFavourites($favourites: [String]!) {
                setFavourites(favourites: $favourites)
            }
        `,
        { favourites },
        { returns: "setFavourites" }
    )
}
