import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getActions = api(async function getActions({ search, take = 50, skip, complete }) {
    return query(
        gql`
            query GetActions($search: String, $take: Int, $skip: Int, $complete: Boolean) {
                getActions(skip: $skip, search: $search, take: $take, complete: $complete) {
                    total
                    actions {
                        _id
                        type
                        title
                        dueDate
                        payload
                        created
                        referenceId
                    }
                }
            }
        `,
        { search, take, skip, complete },
        { returns: "getActions" }
    )
})
