import { Bound } from "lib/@components/binding/Bound"
import { usePayloadTask } from "routes/facilities/lib/use-payload-task"
import { Payload } from "routes/schedule/controller/payloads"
import { BlockClicks } from "lib/@components/block-clicks"
import { Different } from "routes/regime/components/different"
import { UnitLabel } from "routes/schedule/tasks/unitLabel"
import { assessStatus } from "routes/schedule/extendedSchedule"
import { SummaryDataChanged } from "event-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function EditUOM() {
    const { target, schedule, payload, hasAdvancedTailoring } = useBoundContext()

    const task = usePayloadTask()

    return (
        <Payload>
            <BlockClicks>
                <Bound onChange={validate} editMode={hasAdvancedTailoring} target={task}>
                    <Different field="unitOfMeasure" comparedTo={target}>
                        <UnitLabel defaultUnitOfMeasure={target.unitOfMeasure} />
                    </Different>
                </Bound>
            </BlockClicks>
        </Payload>
    )

    function validate() {
        assessStatus(payload, schedule)
        SummaryDataChanged.raiseOnce()
    }
}
