import { Box, Stack, Typography } from "@mui/material"
import { SearchBoxUsingSearchParam } from "lib/@components/searchBoxUsingSearchParam"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"
import { ActionList } from "routes/actions/action-list"
import { HelpTag, HELPTAG_ACTIONS } from "routes/help/helpTag"
import { ActionFilter } from "./action-filter"

export function ActionsPage() {
    return (
        <PageBreadcrumbContainer title="Actions" sx={{ height: 1 }}>
            <HelpTag tags={[HELPTAG_ACTIONS]} />
            <Box display={"flex"} flexDirection={"row"} sx={{ mt: 1 }}>
                <SearchBoxUsingSearchParam placeholder={"Search by action or schedule title"} sx={{ flexGrow: 1 }} />
                <ActionFilter />
            </Box>
            <Stack spacing={2} sx={{ mt: 2, flex: 1 }}>
                <Typography variant="subtitle1">Actions</Typography>
                <ActionList sx={{ mt: 2, p: 0 }} />
            </Stack>
        </PageBreadcrumbContainer>
    )
}
