import { ScheduleBottom, ScheduleIntroductionContents, ScheduleTopmost } from "routes/schedule/scheduleIntroduction"
import { Payload } from "routes/schedule/controller/payloads"
import { AdditionalInfo } from "routes/regime/components/additional-info"
import { AdditionalNotes } from "routes/regime/components/additional-notes"
import { Inventory } from "routes/regime/assets/inventory"
import { Pictures } from "routes/regime/extension-edit/pictures"
import {
    ClassificationArea,
    ScheduleSummaryAdaptor,
    SkillSetArea,
    TaskContent,
    TaskNotes,
    TaskRowStart,
    TimeAndFrequency,
    UOMArea,
} from "slot-definitions"
import { Included } from "routes/regime/components/included"
import { EditTimings } from "routes/regime/components/edit-timings"
import { EditSkills } from "routes/regime/components/edit-skills"
import { EditClassification } from "routes/regime/components/edit-classification"
import { ZeroU } from "routes/regime/extension-edit/zero-u"
import { PayloadTask } from "routes/regime/components/payload-task"
import { ContextMenu } from "lib/@components/context-menu-bar"
import { SCHEDULE } from "library/constants"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { RefreshRouter } from "event-definitions"
import { cleanObject } from "lib/clean-object"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { ExtendSummarySchedule } from "routes/regime/extension-edit/extend-summary-schedule"
import { EditUOM } from "../components/edit-u-o-m"

export function ExtensionEdit({ children }) {
    ScheduleIntroductionContents.usePlug(
        <Payload priority={10}>
            <AdditionalInfo />
        </Payload>
    )
    ScheduleIntroductionContents.usePlug(
        <Payload priority={200}>
            <AdditionalNotes />
        </Payload>
    )
    ScheduleSummaryAdaptor.usePlug(<ExtendSummarySchedule />)
    ScheduleTopmost.usePlug(
        <Payload priority={1}>
            <Inventory />
        </Payload>
    )
    ScheduleBottom.usePlug(
        <Payload priority={10}>
            <Pictures />
        </Payload>
    )
    TaskRowStart.usePlug(({ context }) => !context.target.isHeader && <Included priority={1} />)
    TimeAndFrequency.usePlug(replaceAllWith(<EditTimings />))
    SkillSetArea.usePlug(replaceAllWith(<EditSkills />))
    UOMArea.usePlug(replaceAllWith(<EditUOM />))
    ClassificationArea.usePlug(replaceAllWith(<EditClassification />))
    TimeAndFrequency.usePlug(
        <Payload priority={10}>
            <ZeroU />
        </Payload>
    )
    TaskContent.usePlug(
        <PayloadTask priority={1}>
            <AdditionalInfo />
        </PayloadTask>
    )
    TaskNotes.usePlug(
        <PayloadTask priority={200}>
            <AdditionalNotes />
        </PayloadTask>
    )
    ContextMenu(SCHEDULE).usePlug(
        <ContextMenuItem icon="fluent:delete-12-filled" label="Remove all customisations" onClick={removeAll} />
    )
    const { payload, storePayload } = useBoundContext()

    return children

    function removeAll() {
        cleanObject(payload)
        storePayload()
        RefreshRouter.raiseOnce()
    }
}
