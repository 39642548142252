import { assetDefinition } from "routes/asset-register/controller/asset-definition"
import { BlockClicks } from "lib/@components/block-clicks"
import { Chip } from "@mui/material"
import { navigate } from "lib/routes/navigate"
import { uniqueId } from "library/database/split-id"

export function TaskAsset({ item }) {
    const definition = assetDefinition.useResults(item)

    return (
        !!definition && (
            <BlockClicks>
                <Chip
                    sx={{ fontSize: 12, px: 0.3, py: 0.25, cursor: "pointer", color: "primary.dark" }}
                    role="button"
                    onClick={go}
                    label={definition.name}
                    size="small"
                />
            </BlockClicks>
        )
    )

    function go() {
        navigate(`/app/asset/${uniqueId(item)}`)
    }
}
