// noinspection JSXUnresolvedComponent

import useAsync from "lib/@hooks/useAsync"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { Box } from "@mui/material"
import { Frag } from "lib/@components/slot/frag"
import { checkPermission } from "library/authorization"

const imported = {}

export function Lazy({
    importer,
    id,
    extract = "default",
    fallback = <Frag />,
    nullFallback = fallback,
    loadingScreen = <LoadingScreen />,
    demands,
    matchAllDemands = true,
    ...props
}) {
    const component = useAsync(
        async (update) => {
            if (!imported[importer.toString()]) {
                update(loadingScreen)
            }
            imported[importer.toString()] = true
            const module = await importer({ id, extract, fallback, ...props })
            const Component = module?.[extract || "default"]
            if (Component) {
                return <Component />
            }
            return nullFallback
        },
        fallback,
        [id, extract]
    )
    if (demands) {
        if (!checkPermission(demands, matchAllDemands)) {
            return fallback
        }
    }
    return <component.type {...component.props} {...props} />
}

export function makeLazy(importer, extract, loadingScreen) {
    return function makeLazy(props = {}) {
        return (
            <Lazy
                importer={importer}
                nullFallback={<Box>Not Loaded</Box>}
                extract={extract}
                loadingScreen={loadingScreen}
                {...props}
            />
        )
    }
}
