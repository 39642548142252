import { appRoute } from "routes/app/home.runtime"
import { PageContainer } from "lib/@components/page-container"
import { Link } from "@mui/material"

appRoute.register(
    "testing",
    <PageContainer title="Test Links">
        <Link href="sfg20://sfg20?phrase=ashen+evergreen+dainty">Open a task</Link>
    </PageContainer>
)
