import { ScheduleSelectorTop } from "slot-definitions"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { registerPublishingStep } from "routes/regime/plugins/publishing/register-publishing-step"
import { parentFind } from "library/tree"
import { getBasePlanId } from "routes/plan/controller/use-plan"
import { If } from "lib/@components/switch"
import { CreateDraft } from "routes/plan/create-draft"
import { UpdateDraft } from "routes/plan/update-draft"
import { navigate } from "lib/routes/navigate"
import { uniqueId } from "library/database/split-id"
import { createPortalPair } from "lib/portals"
import { available } from "routes/regime/plugins/publishing/available"
import { PublishingSteps } from "routes/regime/plugins/publishing/publishing-steps"
import { PublishButton } from "routes/regime/plugins/publishing/publish-button"
import { PublishArea } from "routes/regime/plugins/publishing/publish-area"

ScheduleSelectorTop("*").plug(<PublishingSteps if={(c) => !!c.parentItem?.[PlanRoot]} priority={1} />)

export const [PublishPortal, PublishClient] = createPortalPair("Publish")
export const [DraftPortal, DraftClient] = createPortalPair("Draft")
export const [LivePortal, LiveClient] = createPortalPair("Live")

registerPublishingStep({
    title: "Draft",
    icon: "ph:compass-tool-duotone",
    available: (...params) => available("")(...params) ?? true,
    isSelected() {
        return window.location.pathname.includes("/app/schedules")
    },
    onClick({ parentItem }) {
        const parent = parentFind(parentItem, (c) => c[PlanRoot])
        const id = getBasePlanId(parent)
        navigate(`/app/schedules?id=${uniqueId(id)}`, { replace: true })
    },
    content: <DraftPortal width={1} minHeight={40} />,
})

registerPublishingStep({
    title: "Review",
    icon: "carbon:test-tool",
    available: available("publish"),
    content: (
        <>
            <PublishButton />

            <If field="activeStep.props.id" equals="Draft">
                <DraftClient>
                    <If field="isAvailable" then={<UpdateDraft />} else={<CreateDraft />} />
                </DraftClient>
            </If>
            <PublishArea />
        </>
    ),
    isSelected() {
        return window.location.pathname.includes("/app/review/")
    },
    onClick({ parentItem }) {
        const parent = parentFind(parentItem, (c) => c[PlanRoot])
        const id = getBasePlanId(parent)
        navigate(`/app/review/${uniqueId(id)}`)
    },
})

registerPublishingStep({
    title: "Candidate",
    icon: "mingcute:tool-line",
    available: available("ready"),
    isSelected() {
        return window.location.href.includes("/app/schedules?id=ready")
    },
    content: <LivePortal width={1} />,
    onClick({ parentItem }) {
        const parent = parentFind(parentItem, (c) => c[PlanRoot])
        const id = getBasePlanId(parent)
        navigate(`/app/schedules?id=ready|${uniqueId(id)}`, { replace: true })
    },
})

registerPublishingStep({
    title: "Live",
    icon: "mingcute:tool-fill",
    available: available("live"),

    isSelected() {
        return window.location.href.includes("/app/schedules?id=live")
    },
    onClick({ parentItem }) {
        const parent = parentFind(parentItem, (c) => c[PlanRoot])
        const id = getBasePlanId(parent)
        navigate(`/app/schedules?id=live|${uniqueId(id)}`, { replace: true })
    },
})
