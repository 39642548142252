import { useRef, useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { DialogBox } from "lib/@components/dialogBox"
import { Button, Menu, MenuItem, Portal, Typography } from "@mui/material"
import { AssetSelector } from "routes/regime/assets/asset-selector"
import { Repeat } from "lib/@components/repeat"
import { RegisterEntry } from "routes/regime/assets/register-entry"
import { useDialog } from "lib/@hooks/useDialog"
import { updateAssetRegister } from "routes/asset-register/controller/update-asset-register"
import { EditAssetDialog } from "routes/regime/assets/edit-asset-dialog"

export function AssetDialog({ assetRegisters, ok }) {
    const [asset, setAsset] = useState()
    const [anchor, setAnchor] = useState()
    const buttonRef = useRef()
    const createAssetDialog = useDialog(<EditAssetDialog />)

    return (
        <Bound setAsset={setAsset} current={asset} assetRegisters={assetRegisters} createAsset={createAsset}>
            <DialogBox
                title="Select Asset"
                value={asset}
                actions={
                    <Button ref={buttonRef} onClick={create}>
                        Create New Asset
                    </Button>
                }
            >
                <AssetSelector />
            </DialogBox>
            <Portal>
                <Menu open={!!anchor} anchorEl={anchor} onClose={() => setAnchor(null)}>
                    <MenuItem disabled>
                        <Typography variant="caption">Choose Asset Register</Typography>
                    </MenuItem>
                    <Repeat list={assetRegisters} item={<RegisterEntry />} />
                </Menu>
            </Portal>
        </Bound>
    )

    function create() {
        if (assetRegisters.length === 0 || (assetRegisters[0].length === 1 && assetRegisters[0])) return
        if (assetRegisters.length === 1) {
            createAsset(assetRegisters[0]).catch(console.error)
        } else {
            setAnchor(buttonRef.current)
        }
    }

    async function createAsset(register) {
        setAnchor(null)
        const newAsset = await createAssetDialog({ register })
        if (newAsset) {
            await updateAssetRegister({ registerId: register, updateAssets: [newAsset] })
            ok(newAsset.id)
        }
    }
}
