import { appRoute } from "routes/app/home.runtime"
import { useParams } from "react-router-dom"
import { ScheduleHeaderToolbar } from "slot-definitions"

import Schedules from "routes/schedule/schedules"
import { useScheduleByMethodId } from "routes/schedule/compare/use-schedule-by-method-id"
import { SaveButton } from "routes/schedule/compare/save-button"
import { Lazy } from "lib/make-lazy"
import { useRefreshOnInvalidation } from "lib/graphql/use-refresh-on-invalidation"

appRoute.register(
    "schedules/compare/:destination/:source",
    <Schedules>
        <Compare />
    </Schedules>
)

ScheduleHeaderToolbar.plug(<SaveButton />)

function Compare() {
    const { destination, source } = useParams()
    const refresh = useRefreshOnInvalidation("editSchedule", "rawSchedule", "draftHistoryEntry")
    const scheduleDestination = useScheduleByMethodId(destination, refresh.id)
    const scheduleSource = useScheduleByMethodId(source, refresh.id)
    return (
        !!scheduleDestination &&
        !!scheduleSource && (
            <Lazy
                importer={() => import("routes/schedule/compare/comparisonView")}
                extract={"ComparisonView"}
                key={refresh.id}
                destination={scheduleDestination}
                source={scheduleSource}
            />
        )
    )
}
