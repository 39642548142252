import { getTreeId } from "routes/schedule/lib/getTreeId"
import { clone } from "lib/clone"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export async function getGroupPayload(group, key, tree = getTreeId(group)) {
    if (!tree) return null
    if (!key) return null
    // console.log({ group, key, tree })
    return clone(
        (
            await query(
                gql`
                    query getPayloadData($tree: String!, $group: String!, $key: String!) {
                        getGroupPayload(key: $key, tree: $tree, group: $group)
                    }
                `,
                {
                    tree,
                    group,
                    key,
                }
            )
        ).getGroupPayload ?? {}
    )
}
