import { hasDemand } from "lib/authorization/has-demand"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import DemandGuard from "minimals-template/components/guards/DemandGuard"
import { TabbedPage } from "routes/@lib/tabbed-page/tabbed-page.runtime"
import { appRoute } from "routes/app/home.runtime"
import { HELPTAG_ADMINISTRATION } from "routes/help/helpTag"

appRoute.register(
    "admin",
    <DemandGuard demands={["clientAdmin"]}>
        <ClientAdmin />
    </DemandGuard>
)

registerConditionalPageNavigation(
    () => hasDemand("clientAdmin") && hasDemand("!sharing") && hasDemand("schedules"),
    "/app/admin",
    "Admin",
    "fluent:info-shield-20-filled",
    {
        group: "More",
        priority: 580,
    }
)

function ClientAdmin() {
    return <TabbedPage helpTags={[HELPTAG_ADMINISTRATION]} tabId="clientAdmin" pageTitle="Admin" />
}
