import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { confirm } from "lib/@dialogs/confirm"
import { clearDialogs } from "lib/@dialogs/dialogOpen"
import { busyWhile } from "lib/@components/busy"
import { waitForScheduleRefresh } from "routes/schedule/lib/wait-for-schedule-refresh"
import { removeGroup } from "routes/schedule/folder-manager/controller/remove-group"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function RecycleFolder() {
    const { parentItem } = useBoundContext()
    return (
        <ContextMenuItem
            divider={true}
            icon="material-symbols:delete"
            label={`Delete "${parentItem.label}"`}
            onClick={removeFolder}
            noTitleize
        />
    )

    async function removeFolder() {
        if (await confirm("Are you sure?", "Delete")) {
            clearDialogs()
            if (window.location.href.includes(parentItem.id)) {
                window.history.back()
            }
            await busyWhile(async () => removeGroup(parentItem), "Deleting Group", waitForScheduleRefresh)
        }
    }
}
