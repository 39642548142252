import { getCurrentTheme } from "theme"
import { adjustPath } from "lib/routes/adjust-path"

const workingDiv = document.createElement("div")

export function mapLinks(html) {
    workingDiv.innerHTML = html
    for (const item of workingDiv.querySelectorAll("*")) {
        item.style.color = getCurrentTheme().palette.text.primary
    }
    for (const link of workingDiv.querySelectorAll("a")) {
        link.style.color = getCurrentTheme().palette.primary.main
        const parts = link.href.split("/")
        if (parts[5] === "schedule" && parts[6] === "view") {
            link.href = adjustPath(`/app/schedules/${parts[7]}`)
        }
    }
    return workingDiv.innerHTML
}
