import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { wordsFromTaskId } from "routes/schedule/plugins/three-words/controller/words-from-taskid-controller"
import { getSteps } from "routes/schedule/plugins/ai-steps/controller/get-steps"
import { CircularProgress, List, Typography } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { Step } from "routes/schedule/plugins/ai-steps/step"

const empty = []

export function AISteps({ schedule, task }) {
    const { schedulePath, currentId = schedulePath } = useBoundContext()
    const [first, scheduleId] = currentId.split("!")
    const [group, tree] = first.split("~")

    const words = wordsFromTaskId.useResults(`${tree}.${group}!${scheduleId}.${task.id}`) ?? ""

    const steps =
        getSteps.useResults(
            task.title,
            task.content,
            `Schedule: ${schedule.title}.\n\n${schedule.intro?.content}\n\n Notes: ${schedule.intro?.note ?? "none"}\n`,
            task.note
        ) ?? empty
    return steps === empty ? (
        <CircularProgress color="primary" />
    ) : (
        <>
            <Typography sx={{ "&,& *": { userSelect: "text" } }} variant="subtitle1">
                {task.title}
            </Typography>
            {!!words && !currentId.includes("~sfg20") && (
                <Typography sx={{ "&,& *": { userSelect: "text" } }} variant="body1">
                    Smart Words: <strong>{words.join(" ")}</strong>
                </Typography>
            )}
            <List sx={{ "& li *": { userSelect: "text" }, maxWidth: 550 }}>
                <Repeat list={steps} item={<Step />} />
            </List>
        </>
    )
}
