import { api } from "lib/graphql/api"
import { busyWhileWithDelay } from "lib/@components/busy"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const regimeAssets = api(({ regimeId, filters = [], group, search, sort, skip = 0, take = 10 }) =>
    busyWhileWithDelay(
        () =>
            query(
                gql`
                    query RegimeAssetSummary(
                        $regimeId: String!
                        $group: String
                        $search: String
                        $sort: [String]
                        $skip: Int
                        $take: Int
                        $filters: [JSONObject]
                    ) {
                        regimeAssetSummary(
                            regimeId: $regimeId
                            group: $group
                            sort: $sort
                            search: $search
                            filters: $filters
                        ) {
                            id
                            assets {
                                total
                                items(take: $take, skip: $skip) {
                                    id
                                    tag
                                    name
                                    type
                                    category
                                    facility
                                    floor
                                    space
                                    system
                                    manufacturer
                                    model
                                    red
                                    green
                                    amber
                                    pink
                                    skills
                                    frequencies
                                    minutes
                                    taskCount
                                    tasks {
                                        scheduleId
                                        scheduleTitle
                                        code
                                        title
                                        id
                                    }
                                }
                            }
                        }
                    }
                `,
                {
                    regimeId,
                    group,
                    sort,
                    skip,
                    take,
                    search,
                    filters,
                },

                { returns: "regimeAssetSummary.assets" }
            ),
        100,
        "Retrieving Assets"
    )
)
