import { DataInputDialog } from "lib/@dialogs/data-input-dialog"
import { DialogTitle, Stack } from "@mui/material"
import { BoundTextField } from "lib/@components/binding/bound-components"
import { BlockKeyboard } from "lib/@components/block-clicks"
import { BoundTextEditor } from "minimals-template/components/editor"

export function CreateRegisterDialog({ source }) {
    return (
        <DataInputDialog initialValue={source}>
            <DialogTitle sx={{ mb: 3 }}>{source ? "Edit" : "Create"} Asset Register</DialogTitle>

            <Stack spacing={2} sx={{ mx: 3 }}>
                <BoundTextField autoFocus field="name" />
                <BlockKeyboard>
                    <BoundTextEditor field="description" />
                </BlockKeyboard>
            </Stack>
        </DataInputDialog>
    )
}
