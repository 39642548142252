import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"
import { makeBusyFunction } from "lib/@components/busy"

export const importLegacyCustomSkills = makeBusyFunction(
    api((subscriptionId, selectedIds) =>
        mutate(
            gql`
                mutation ImportLegacyCustomSkills($subscriptionId: Int!, $selectedIds: [Int!]!) {
                    importLegacyCustomSkills(subscriptionId: $subscriptionId, selectedIds: $selectedIds)
                }
            `,
            { subscriptionId, selectedIds },
            { returns: "importLegacyCustomSkills" }
        )
    ),
    "Import Legacy Custom Skills"
)
