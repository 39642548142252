import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab"
import { Box, Container, IconButton, Typography } from "@mui/material"
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight"
import Page from "minimals-template/components/Page"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Repeat } from "lib/@components/repeat"
import { splitId } from "library/database/split-id"
import { useParams } from "react-router-dom"
import { appRoute } from "routes/app/home.runtime"
import { useTimelineHistory } from "routes/schedule/lib/use-timeline-history"
import Schedules from "routes/schedule/schedules"
import { ScheduleTitle } from "routes/schedule/scheduleTitle"
import { addToStack } from "routes/schedule/tree/schedules/upDirectory"
import { UpBar } from "routes/schedule/components/up-bar"
import useSettings from "minimals-template/components/@hooks/useSettings"
import { DraftWrapper } from "routes/schedule/draftWrapper"
import { getScheduleRecordId } from "routes/schedule/lib/get-schedule-record-id"
import { User } from "lib/user/user"
import { LoadingTemplate } from "lib/@components/loading-template"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { DATE_FORMATS } from "lib/@constants/date-format"
import { Bound } from "lib/@components/binding/Bound"
import { useScheduleForEditing } from "../lib/use-schedule-for-editing"
import { unlockDraftFromTimeline } from "./controller/unlock-draft"
import { useUnmounted } from "lib/lifecycle"
import { Lock } from "minimals-template/components/lock/Lock"

appRoute.register(
    "schedules/:id/timeline",
    <Schedules>
        <TimelinePage />
    </Schedules>
)

function TimelinePage() {
    const { id } = useParams()
    const code = getScheduleRecordId(id)
    const definition = useScheduleForEditing(code, true)
    const { themeStretch } = useSettings()
    useUnmounted(unMount)
    const { draftHistory = { history: [] }, ...props } = useTimelineHistory(code)
    return (
        <Lock
            id={id}
            breadcrumb={"Schedule Locked"}
            message={"This schedule is currently locked because the person indicated below is making changes to it."}
        >
            <Bound isTimeline={true} editMode={true} target={definition}>
                <LoadingTemplate {...props} target={draftHistory}>
                    <DraftWrapper id={code}>
                        <Page title="Draft timeline">
                            <Container
                                maxWidth={themeStretch ? false : "lg"}
                                sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
                            >
                                <UpBar inContainer={true} />
                                <Typography variant="h3" component="h1" gutterBottom>
                                    Draft Timeline
                                </Typography>
                                <ScheduleTitle />
                                <Timeline>
                                    <Repeat list={draftHistory.history} item={<TimelineEntry />} />
                                </Timeline>
                            </Container>
                        </Page>
                    </DraftWrapper>
                </LoadingTemplate>
            </Bound>
        </Lock>
    )

    async function unMount() {
        const url = window.location.href
        const shouldUnlock = !url.includes("/edit")
        if (!shouldUnlock) return
        await unlockDraftFromTimeline(id)
    }
}

function TimelineEntry({ item, index, last, previous }) {
    const { schedule } = useBoundContext()
    return (
        <TimelineItem
            sx={index === 0 ? { backgroundColor: "background.paper", py: 2, borderRadius: 1 } : { cursor: "pointer" }}
            role={index === 0 ? "button" : undefined}
            tabIndex={index === 0 ? -1 : 0}
            onClick={compareSchedules}
        >
            {previous?.user !== item.user && (
                <TimelineOppositeContent sx={{ display: "flex" }}>
                    <User id={item.user} sx={{ textAlign: "left" }} />
                </TimelineOppositeContent>
            )}
            <TimelineSeparator sx={{ mt: 1 }}>
                <TimelineDot color={index === 0 ? undefined : "primary"} />
                {!last && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent sx={{ mt: 0 }}>
                <ListItemBox>
                    <Box flex={1}>
                        <Typography sx={{ color: "text.secondary" }} variant="caption">
                            {new Date(item.updated).format(DATE_FORMATS.long)}
                        </Typography>
                    </Box>
                    {index !== 0 ? (
                        <Box>
                            <IconButton color="primary">
                                <MdChevronRight />
                            </IconButton>
                        </Box>
                    ) : (
                        <Box height={40} />
                    )}
                </ListItemBox>
            </TimelineContent>
        </TimelineItem>
    )

    function compareSchedules() {
        addToStack()
        navigate(`/app/schedules/compare/draft!${splitId(schedule._id).id}/history!${item.id}`)
    }
}
