// routes
import { RouteBroadcast, RouterLocation } from "routes"
// theme
import ThemeProvider from "./theme"
// components
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { AppRefresh } from "event-definitions"
import { Busy } from "lib/@components/busy"
import { Dialogs } from "lib/@dialogs/dialogs"
import MotionLazyContainer from "minimals-template/components/animate/MotionLazyContainer"
import { ChartStyle } from "minimals-template/components/chart"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { Navigator } from "minimals-template/components/Navigator"
import NotistackProvider from "minimals-template/components/NotistackProvider"
import ScrollToTop from "minimals-template/components/ScrollToTop"
import ThemeSettings from "minimals-template/components/settings"
import { Fragment, Suspense, useState } from "react"
import { TreeRoots } from "treeRoots"
import "./index.css"
import "./bullets.scss"
import { BackdropHolder } from "lib/@components/backdropHolder"
import { FrozenRouter } from "FrozenRouter"
import { ServerEvents } from "ServerEvents"
import { DashboardHeaderLeft, RootPlugs } from "slot-definitions"
import "lib/authorization"
import "./debug-window"
import Searchbar from "routes/@layouts/app/header/Searchbar"
import { Bound } from "lib/@components/binding/Bound"
import noop from "lib/noop"
import { hasPermission } from "library/authorization"

DashboardHeaderLeft.plug(<Searchbar if={hasPermission("schedules")} />)

export default function App() {
    const [id, setId] = useState(0)
    AppRefresh.useEvent(() => setId((id) => id + 1))
    return (
        <Bound closeNow={noop} close={(fn) => () => fn()}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MotionLazyContainer>
                    <ThemeProvider>
                        <ThemeSettings>
                            <ServerEvents>
                                <TreeRoots />
                                <RootPlugs.Slot />
                                <Suspense fallback={<LoadingScreen description="Suspense" />}>
                                    <NotistackProvider>
                                        <ChartStyle />
                                        <Fragment key={id}>
                                            <ScrollToTop />
                                            <Navigator />
                                            <RouterLocation />
                                            <RouteBroadcast />
                                            <FrozenRouter />
                                            <BackdropHolder />
                                            <Dialogs />
                                        </Fragment>
                                    </NotistackProvider>
                                </Suspense>
                            </ServerEvents>
                            <Busy />
                        </ThemeSettings>
                    </ThemeProvider>
                </MotionLazyContainer>
            </LocalizationProvider>
        </Bound>
    )
}
