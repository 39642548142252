import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getMyDerivedSchedules = api(async function getMyDerivedSchedules({
    derivedFrom,
    skip,
    take,
    sortMode,
    drafts,
}) {
    return derivedFrom
        ? query(
              gql`
                  query getMyDerivedSchedules(
                      $derivedFrom: String!
                      $take: Int
                      $skip: Int
                      $sortMode: MyScheduleSortMode
                      $drafts: Boolean
                  ) {
                      getMyDerivedSchedules(
                          derivedFrom: $derivedFrom
                          skip: $skip
                          take: $take
                          sortMode: $sortMode
                          drafts: $drafts
                      ) {
                          count
                          scheduleItems {
                              title
                              _id
                              itemType
                              code
                          }
                      }
                  }
              `,
              { derivedFrom, skip, take, sortMode, drafts },
              { returns: "getMyDerivedSchedules" }
          )
        : []
})
