import { TaskNotes } from "slot-definitions"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Box, Button, Stack, Typography } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { wordsFromTaskId } from "routes/schedule/plugins/three-words/controller/words-from-taskid-controller"
import { busyWhile } from "lib/@components/busy"
import { alert } from "lib/@dialogs/alert"
import { NotInPdf } from "lib/@components/pdf/not-in-pdf"
import { isEnabled } from "lib/@components/feature"
import { CHECKLIST_FEATURE } from "routes/schedule/plugins/ai-steps/ai-steps.runtime"

TaskNotes.plug(
    <ThreeWords if={({ currentId }) => currentId.includes("~") && !isEnabled(CHECKLIST_FEATURE)} priority={120} />
)

function ThreeWords() {
    const { currentId, task } = useBoundContext()
    const [first, scheduleId] = currentId.split("!")
    const [group, tree] = first.split("~")

    const enabled = ["live", "ready"].includes(tree.split("|").at(0))

    return (
        <ListItemBox>
            <Box flex={1} />
            <NotInPdf>
                <Button data-cy="smart-words-button" onClick={getWords} size="small" disabled={!enabled}>
                    {enabled ? "Smart Words" : "Smart Words (unavailable)"}
                </Button>
            </NotInPdf>
        </ListItemBox>
    )

    async function getWords() {
        const result = await busyWhile(() => wordsFromTaskId(`${tree}.${group}!${scheduleId}.${task.id}`))
        await alert(
            <Stack spacing={2} data-cy="smart-words-alert">
                <Typography color="text.secondary">The Smart Words are:</Typography>
                <Typography sx={{ userSelect: "all", "& *": { userSelect: "all" } }} variant="h6">
                    {result.join(" ")}
                </Typography>
            </Stack>
        )
    }
}
