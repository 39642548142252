import { EnsureTree } from "event-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { raiseOnce } from "library/local-events"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { lazy, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getTreeId } from "routes/schedule/lib/getTreeId"
import { getScheduleRecordId } from "routes/schedule/lib/get-schedule-record-id"
import { useSchedule } from "routes/schedule/lib/useSchedule"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { LoadingTemplate } from "lib/@components/loading-template"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { usePayload } from "./controller/payloads"
import { getGroupId } from "./lib/getGroupId"
import { NoResultsContainer } from "lib/@components/no-results/no-results"

const ScheduleDisplay = lazy(() => import("./scheduleDisplay"))

export default function Schedule({ scheduleId, version }) {
    const { id: paramId } = useParams()
    const id = scheduleId ?? paramId
    const [urlVersion] = useSearchParam("version", "no_v")
    version ??= urlVersion
    const code = getScheduleRecordId(id)
    const [editing] = useSearchParam("editEx")
    const treeId = getTreeId(id)
    const { schedule, ...props } = useSchedule(!treeId || editing ? code : id, version)
    const payload = usePayload(getGroupId(id), code, treeId)

    const error = payload instanceof Error && payload?.message.includes("Not authorized") ? payload : undefined

    if (treeId) {
        EnsureTree.raise(treeId)
    }

    useEffect(() => {
        raiseOnce("CurrentSchedule", id)
    }, [id])

    const tree = useTreeItem(treeId)

    if (error) return <NoResultsContainer message="Nothing to see here" />

    return !tree && treeId ? (
        <LoadingScreen description="Has Tree" />
    ) : (
        <Bound schedulePath={id} code={code}>
            <LoadingTemplate {...props} target={schedule} schedule={schedule}>
                <ScheduleDisplay />
            </LoadingTemplate>
        </Bound>
    )
}
