import { appRoute } from "routes/app/home.runtime"
import { useParams } from "react-router-dom"

import Schedules from "routes/schedule/schedules"
import { useScheduleByMethodId } from "routes/schedule/compare/use-schedule-by-method-id"
import { Lazy } from "lib/make-lazy"
import { useRefreshOnInvalidation } from "lib/graphql/use-refresh-on-invalidation"

appRoute.register(
    "schedules/compare/:destination/:source/:other",
    <Schedules>
        <Compare />
    </Schedules>
)

function Compare() {
    const { destination, source, other } = useParams()
    const refresh = useRefreshOnInvalidation("editSchedule", "rawSchedule", "draftHistoryEntry")
    const scheduleDestination = useScheduleByMethodId(destination, refresh.id)
    const scheduleSource = useScheduleByMethodId(source, refresh.id)
    const scheduleOther = useScheduleByMethodId(other, refresh.id)
    return (
        !!scheduleDestination &&
        !!scheduleOther &&
        !!scheduleSource && (
            <Lazy
                importer={() => import("routes/schedule/compare/comparisonView")}
                extract="ComparisonView"
                key={refresh.id}
                destination={scheduleDestination}
                source={scheduleSource}
                other={scheduleOther}
            />
        )
    )
}
