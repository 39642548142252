import { Lazy } from "lib/make-lazy"
import { AssetActions } from "slot-definitions"

AssetActions.plug(
    <Lazy
        importer={() =>
            import("routes/asset-register/plugins/asset-mapping/plugins/cobie-update-register/cobie-update-register")
        }
        extract="CobieUpdateRegister"
    />
)
