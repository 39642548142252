import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"

export const updateAssetRegister = api(async function updateAssetRegister({
    registerId,
    name,
    description,
    removeAssets,
    updateAssets,
    cobieFiles,
}) {
    updateAssets = updateAssets
        ? updateAssets.map((asset) => ({
              ...asset,
              picture: asset.picture ? asset.picture.documents.map("href") : undefined,
          }))
        : undefined
    return mutate(
        gql`
            mutation UpdateAssetRegister(
                $id: String!
                $name: String
                $description: String
                $removeAssets: [String!]
                $updateAssets: [AssetInput!]
                $cobieFiles: [String!]
            ) {
                updateAssetRegister(
                    id: $id
                    register: {
                        name: $name
                        description: $description
                        removeAssets: $removeAssets
                        updateAssets: $updateAssets
                        cobieFiles: $cobieFiles
                    }
                )
            }
        `,
        {
            id: registerId,
            name,
            description,
            removeAssets,
            updateAssets,
            cobieFiles,
        },
        {
            returns: "updateAssetRegister",
        }
    )
})
