import { Box } from "@mui/material"
import { Links } from "routes/shared-with-me/links"
import { HelpTag, HELPTAG_SHARED_WITH_ME } from "routes/help/helpTag"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"

export function SharedWithMe() {
    return (
        <PageBreadcrumbContainer title="Shared With Me">
            <HelpTag tags={[HELPTAG_SHARED_WITH_ME]} />
            <Box flex={1} sx={{ overflowY: "scroll" }} p={1}>
                <Links />
            </Box>
        </PageBreadcrumbContainer>
    )
}
