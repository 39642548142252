import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { convertFolderName } from "routes/schedule/folder-manager/folder-management"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { mutate } from "lib/graphql/mutate"
import { busyWhile } from "lib/@components/busy"

export const removeGroup = api(async function removeGroup(parentItem) {
    await busyWhile(
        () =>
            mutate(
                gql`
                    mutation RemoveGroup($tree: String!, $group: String!) {
                        removeGroup(tree: $tree, group: $group)
                    }
                `,
                { tree: getTreeIdFromItem(parentItem), group: convertFolderName(parentItem.id) }
            ),
        "Removing group..."
    )
})
