import { useReferenceState } from "lib/@hooks/use-reference"
import { useDialog } from "lib/@hooks/useDialog"
import { MoveDialog } from "routes/plan/moveDialog"
import { Box, Button, ButtonGroup, Typography } from "@mui/material"
import { keyBy } from "lib/keyBy"
import { pick } from "lib/pick"
import { MdClear } from "@react-icons/all-files/md/MdClear"
import { registerSymbol } from "library/serialize-circular"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

export const scheduleItem = registerSymbol(Symbol("ScheduleItem"))

export function TaskActions() {
    const { planStart, currentDay, selectedPlanDay } = useBoundContext()
    const [selections, setSelections] = useReferenceState("taskSelections", {})
    const moveDialog = useDialog(<MoveDialog currentDay={currentDay} />)
    const allTasks =
        selectedPlanDay?.schedules
            .map((schedule) => schedule.tasks.map((task) => ({ ...task, [scheduleItem]: schedule })))
            .flat(Infinity) ?? []
    const lookup = keyBy(allTasks, pick("id"))

    const [original, setConfigurations] = useBoundValue("configurations", {})
    const selected = Object.entries(selections).filter((v) => !!v[1] && lookup[v[0]])
    return (
        <ButtonGroup
            size="small"
            disabled={!selected.length}
            variant="contained"
            sx={{
                overflow: "hidden",
                bgcolor: !selected.length ? undefined : "primary.darker",
                color: !selected.length ? undefined : "primary.contrastText",
                mx: 1,
                "& button": {
                    transition: "color 0.5s linear, filter 0.5s linear",
                },
            }}
        >
            {!!selected.length && (
                <Box mt={0.3} px={2}>
                    <Typography variant="caption">
                        {selected.length} {"Task".pluralize(selected.length)}
                    </Typography>
                </Box>
            )}
            <ButtonGroup size="small" variant="contained" disabled={!selected.length}>
                <Button size="small" onClick={skip}>
                    Enable / Disable
                </Button>
                <Button onClick={move} size="small">
                    Move
                </Button>
                <Button aria-label="Clear selection" onClick={deselect}>
                    <MdClear />
                </Button>
            </ButtonGroup>
        </ButtonGroup>
    )

    function deselect() {
        setSelections({})
    }

    async function skip() {
        const tasks = allTasks.filter((t) => selections[t.id])
        for (const task of tasks) {
            const existing = original[task.id] ?? {
                assetIndex: task.assetIndex,
                scheduleId: task[scheduleItem].scheduleId,
                taskId: task.task.id,
                period: task.periodIndex,
            }
            existing.skipTask = !existing.skipTask
            original[task.id] = existing
        }
        setConfigurations({ ...original })
    }

    async function move() {
        const result = await moveDialog({
            planStart,
            tasks: allTasks.filter((t) => selections[t.id]),
            configurations: keyBy(
                allTasks.map((task) => original[task.id] ?? {}),
                pick("taskId")
            ),
        })
        if (result) {
            if (result.offset) {
                result.canMove.forEach((task) => {
                    const existing = original[task.id] ?? {
                        startOffset: 0,
                        assetIndex: task.assetIndex,
                        scheduleId: task[scheduleItem].scheduleId,
                        taskId: task.task.id,
                        period: task.periodIndex,
                    }
                    existing.startOffset += result.offset
                    original[task.id] = existing
                })
                setConfigurations({ ...original })
            } else {
                const days = -new Date(planStart).daysSince(new Date(result.date).addHours(11))
                result.canMove.forEach((task) => {
                    const existing = original[task.id] ?? {
                        startOffset: 0,
                        scheduleId: task[scheduleItem].scheduleId,
                        assetIndex: task.assetIndex,
                        taskId: task.task.id,
                        period: task.periodIndex,
                    }
                    existing.startOffset = days
                    original[task.id] = existing
                })
                setConfigurations({ ...original })
            }
        }
    }
}
