import { clone } from "lodash"
import { getLegislationData } from "../controller/get-legislation"
import { stringifyArray } from "lib/stringify-array"

export async function processLegislationData(legislation) {
    const legislationToString = stringifyArray(legislation)

    const legislationData = legislationToString.length && (await getLegislationData(legislationToString))
    const amendedLegislation = legislationData
        ? legislationData.map((legislation) => {
              const clonedLegislation = clone(legislation)
              delete clonedLegislation.__typename
              return clonedLegislation
          })
        : []

    return amendedLegislation
}
