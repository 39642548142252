import { useState } from "react"
import { getMyDerivedSchedules } from "routes/schedule/my-schedules/my-custom-schedules/controller/get-my-derived-schedules"
import { Box, List, Paper, Stack, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { RequestPageRepeat } from "lib/@components/request-page-repeat"
import { DerivedItem } from "routes/schedule/plugins/derived-schedules/derived-item"
import { hasDemand } from "lib/authorization/has-demand"

export function ListOfDetails({ schedule }) {
    const [skip, setSkip] = useState(0)
    const derived = getMyDerivedSchedules.useResults({
        derivedFrom: schedule._id,
        skip,
        take: 10,
        drafts: hasDemand("scheduleEditor"),
    })
    return (
        !!derived?.scheduleItems?.length && (
            <>
                <Typography variant="subtitle2" sx={{ p: 2 }}>
                    Derived Schedules
                </Typography>

                <Paper elevation={1} sx={{ overflow: "hidden", mb: 2, p: 0 }}>
                    <ListItemBox sx={{ width: 1, py: 0.3, bgcolor: "grey.50016", color: "text.secondary" }}>
                        <Typography sx={{ ml: 6 }} variant="caption">
                            Name
                        </Typography>
                        <Box flex={1} />
                        <Typography variant="caption" sx={{ mr: 3 }}>
                            Code
                        </Typography>
                    </ListItemBox>
                    <Stack spacing={2} sx={{ width: 1 }}>
                        <List sx={{ width: 1, px: 1 }}>
                            <RequestPageRepeat
                                pageSize={10}
                                list={derived?.scheduleItems}
                                setSkip={setSkip}
                                total={derived?.count}
                                item={<DerivedItem />}
                            />
                        </List>
                    </Stack>
                </Paper>
            </>
        )
    )
}
