import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const wordsFromTaskId = api((taskId) =>
    query(
        gql`
            query GetThreeWords($taskId: String!) {
                wordsFromTaskId(taskId: $taskId)
            }
        `,
        { taskId },
        { returns: "wordsFromTaskId" }
    )
)
