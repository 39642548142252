import { useReferenceState } from "lib/@hooks/use-reference"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { useEffect, useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import {
    Box,
    FormControlLabel,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { SearchBoxUsingSearchParam } from "lib/@components/searchBoxUsingSearchParam"
import { setFromEvent } from "lib/setFromEvent"
import { defaultNumberOfRecords } from "routes/asset-register/default-number-of-records"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { RequestPageRepeat } from "lib/@components/request-page-repeat"
import { NoResults } from "lib/@components/no-results/no-results"
import { useDarkMode } from "lib/use-dark-mode"
import { useScrollRestoration } from "lib/use-scroll-restoration"
import { DebouncedTextField } from "lib/@components/debounced-text-field"
import { MdClear } from "@react-icons/all-files/md/MdClear"
import { applyChangesToMany } from "routes/asset-register/plugins/regime-display/controller/apply-changes-to-many"
import { regimeAssets } from "routes/asset-register/plugins/regime-display/controller/regime-assets"
import { SortOn } from "routes/asset-register/plugins/regime-display/sort-on"
import { AssetEntry } from "./asset-entry"
import { IoMdEye } from "@react-icons/all-files/io/IoMdEye"
import { FilterSummaries } from "routes/asset-register/plugins/regime-display/filter-summaries"
import { AssetsBodyAndActionRow } from "routes/asset-register/plugins/regime-display/assets-body-and-action-row"
import { If } from "lib/@components/switch"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

const loadingTasks = []
const loading = { items: loadingTasks, totalCount: 0 }

export function AssetView() {
    const isDark = useDarkMode()
    const theme = useTheme()
    const [state] = useState({})
    const { parentItem } = useBoundContext({ target: state })
    const [filters, setFilters] = useBoundValue("regime-asset-filters", [])
    const [filter, setFilter] = useState("")
    const regimeId = parentFind(parentItem, (c) => c[PlanRoot])?.id
    const group = parentItem.id?.split("!").at(-1)
    const [take, setTake] = useReferenceState("asset-page-size", 10, "asset-page")
    const [skip, setSkip] = useSearchParam("skip", 0, (v) => +v)
    const [search] = useSearchParam("search", "")
    const [portal, setPortal] = useState(false)
    const scrollContainer = useScrollRestoration(undefined, 5000)
    const [sort] = useReferenceState("asset-sort", [], "asset-sort")

    const { items: assets, total: totalCount } =
        regimeAssets.useResults({
            skip,
            take,
            regimeId,
            group,
            search,
            filters,
            sort,
        }) ?? loading

    useEffect(() => {
        if (assets !== loadingTasks && assets?.length === 0 && filters.length !== 0) {
            setFilters([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assets, filters])

    return (
        <Bound
            target={state}
            sorter="asset-sort"
            filterKey="regime-asset-filters"
            updateAll={updateAll}
            paginationPortal={portal ? portal : false}
            regimeId={regimeId}
            group={group}
            totalCount={totalCount}
        >
            <Stack sx={{ width: 1, flexShrink: 1 }}>
                <ListItemBox spacing={1}>
                    <If truthy={false}>
                        <Box pt={1.45}>
                            <DebouncedTextField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setFilter("")
                                                    setFilters([])
                                                }}
                                            >
                                                <MdClear />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ "& input": { fontSize: 13 } }}
                                onChange={setFromEvent(setFilter)}
                                placeholder="Filter..."
                                value={filter}
                                fullWidth
                                size="small"
                                variant="standard"
                            />
                            <Box sx={{ alignSelf: "stretch", height: "55vh", overflowY: "auto", overflowX: "hidden" }}>
                                <FilterSummaries filter={filter} type="asset" />
                            </Box>
                        </Box>
                    </If>

                    <Box width="70%" flex={1} display="flex" flexDirection="column" alignSelf="stretch">
                        <ListItemBox spacing={2} sx={{ pt: 0.1 }}>
                            <SearchBoxUsingSearchParam
                                placeholder="Search..."
                                autoFocus
                                sx={{ flex: 1, mb: 1, mt: 1, py: 0, "& *": { fontSize: 13 } }}
                            />

                            <FormControlLabel
                                label={<Typography variant="body2">Rows per page: </Typography>}
                                labelPlacement="start"
                                sx={{ pb: 1 }}
                                control={
                                    <Select
                                        sx={{ mr: 2, ml: 2, fontSize: 13 }}
                                        variant="standard"
                                        labelId="rows-per-page-label"
                                        value={take}
                                        onChange={setFromEvent(setTake)}
                                    >
                                        {defaultNumberOfRecords.map((rowCountValue) => (
                                            <MenuItem key={rowCountValue} value={rowCountValue}>
                                                {rowCountValue}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                            />
                            <Box ref={setPortal} />
                        </ListItemBox>

                        <Box
                            width={1}
                            ref={scrollContainer}
                            sx={{
                                width: 1,
                                flex: 1,
                                maxHeight: "55vh",
                                overflow: "scroll",
                                "& .MuiTableCell-root": {
                                    fontSize: 13,
                                    whiteSpace: "nowrap",
                                    py: 0.1,
                                    borderRadius: "0 !important",
                                },
                                "& .MuiTableRow-root": {
                                    "&": {
                                        borderBottom: "1px solid " + theme.palette.action.hover,
                                    },
                                    "&:last-of-type": {
                                        borderBottom: "none",
                                    },
                                },
                            }}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            position: "sticky",
                                            top: 0,
                                            borderRadius: 1,
                                            bgcolor: "background.paper",
                                            zIndex: 202,
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                borderRadius: 0,
                                                position: "sticky",
                                                left: 0,
                                                top: 0,
                                                bgcolor: isDark ? "primary.darker" : "primary.lighter",
                                                zIndex: 201,
                                                borderRight: "1px solid",
                                                borderRightColor: "action.hover",
                                            }}
                                        >
                                            <SortOn field="tag">Tag</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="score">Summary</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="category">Category</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="facility">Facility</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="floor">Floor</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="space">Space</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="type">Type</SortOn>
                                        </TableCell>
                                        <TableCell>Schedules</TableCell>
                                        <TableCell>
                                            <SortOn field="system">System</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="manufacturer">Manufacturer</SortOn>
                                        </TableCell>
                                        <TableCell>
                                            <SortOn field="minutes">Minutes</SortOn>
                                        </TableCell>
                                        <TableCell>Tasks</TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                borderRadius: 0,
                                                position: "sticky",
                                                right: 0,
                                                lineHeight: 0,
                                                top: 0,
                                                zIndex: 201,
                                                color: "primary.dark",
                                                bgcolor: isDark ? "primary.darker" : "primary.lighter",
                                            }}
                                        >
                                            <IoMdEye />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <RequestPageRepeat
                                    showTop
                                    pageSize={take}
                                    showBottom={false}
                                    fallback={
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <NoResults />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    refreshKey={search}
                                    setSkip={setSkip}
                                    Component={AssetsBodyAndActionRow}
                                    list={assets}
                                    item={<AssetEntry />}
                                    total={totalCount}
                                />
                            </Table>
                        </Box>
                    </Box>
                </ListItemBox>
            </Stack>
        </Bound>
    )

    function updateAll(source) {
        return applyChangesToMany({ source, search, filters, group, regimeId })
    }
}
