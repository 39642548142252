import { ScheduleSelectorOverrideWrapper } from "slot-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { registerTab, Tabs } from "lib/@components/tabs"
import { OriginalContent } from "routes/asset-register/plugins/regime-display/original-content"
import { isInRegime } from "routes/facilities/lib/when-parent-item"
import { Feature } from "lib/@components/feature"
import { FEATURE_FLAG_ASSET_MAPPING } from "routes/asset-register/feature-flag-asset-mapping"
import { TaskView } from "routes/asset-register/plugins/regime-display/task-view"
import { AssetView } from "routes/asset-register/plugins/regime-display/asset-view"
import { Box } from "@mui/material"

ScheduleSelectorOverrideWrapper.plug(<AssetWrapper if={isInRegime} />)

export const REGIME_ASSETS_TAB = "regime_assets"

function AssetWrapper({ children }) {
    return (
        <Feature feature={FEATURE_FLAG_ASSET_MAPPING} fallback={children}>
            <Bound normalChildren={children}>
                <Tabs id={REGIME_ASSETS_TAB} panelHolder={<Box mt={1} />} />
            </Bound>
        </Feature>
    )
}

registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "original",
    title: "Structure",
    content: <OriginalContent priority={10} />,
})

registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "tasks",
    title: "Tasks",
    content: <TaskView />,
})

registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "assets",
    title: "Assets",
    content: <AssetView />,
})
