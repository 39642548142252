import { Bound } from "lib/@components/binding/Bound"
import { htmlToText } from "lib/html-to-text"
import { isGroup } from "library/constants"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { useCallback, useMemo } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { usePayload } from "routes/schedule/controller/payloads"
import { getGroup, saveGroupData } from "routes/schedule/controller/store-group-data"
import { getGroupId } from "routes/schedule/lib/getGroupId"
import { getTreeId } from "routes/schedule/lib/getTreeId"
import { getScheduleRecordId } from "routes/schedule/lib/get-schedule-record-id"
import { setGroupPayload } from "routes/schedule/controller/set-group-payload"
import { useSave } from "lib/@hooks/use-save"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { createSlot } from "lib/@components/slot/create-slot"
import { SaveIndicator } from "lib/@components/save-indicator/save-indicator"
import { useUndo } from "lib/undo"
import { Undo } from "routes/schedule/plugins/undo-redo/undo-redo.runtime"
import { UndoLive } from "routes/schedule/undo-portal"
import { Lock } from "minimals-template/components/lock/Lock"

export const ScheduleExtension = createSlot("ScheduleExtension")
export const ScheduleExtensionDisplay = createSlot("ScheduleExtensionDisplay")

export function ExtendedSchedule({ children, scheduleId, isEdit, isShare, onConfigureStorage = (v) => v }) {
    const { id: paramId } = useParams()
    const id = scheduleId ?? paramId
    const [searchParams] = useSearchParams()
    isEdit ??= !!searchParams.get("editEx")
    isShare ??= !!searchParams.get("share")
    const group = getGroupId(id)
    const payload = usePayload(getGroupId(id), getScheduleRecordId(id), getTreeId(id))
    const item = useTreeItem(group)

    const [extension, { onChange, ...props }] = useUndo({
        ref: item,
        initialTarget: payload,
        change: (item) => {
            if (!item) storePayload()
        },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const storePayload = useSave(
        {
            id: "tailor",
            save: store,
            target: extension,
        },
        [item, id, isEdit]
    )

    const updateAndStore = useCallback(() => {
        onChange()
        storePayload()
    }, [onChange, storePayload])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const finalSave = useMemo(() => onConfigureStorage(updateAndStore, store), [updateAndStore])
    const modifiedStore = useCallback(() => finalSave(extension), [finalSave, extension])

    if (isGroup(item)) {
        return !payload ? (
            <LoadingScreen description="Has Payload" />
        ) : (
            <Bound
                {...props}
                currentId={id}
                currentGroup={item}
                isExtended={true}
                payload={extension}
                editExtension={isEdit}
                showAll={isEdit}
                storePayload={modifiedStore}
                isShare={isShare}
            >
                {isEdit ? (
                    <>
                        <SaveIndicator id="tailor" />
                        <UndoLive>
                            <Bound editMode={true}>
                                <Undo />
                            </Bound>
                        </UndoLive>
                        <ScheduleExtension.Wrapper>
                            <Lock id={id}>{children}</Lock>
                        </ScheduleExtension.Wrapper>
                    </>
                ) : (
                    <ScheduleExtensionDisplay.Wrapper>{children}</ScheduleExtensionDisplay.Wrapper>
                )}
            </Bound>
        )
    }
    return (
        <Bound currentGroup={item} isShare={isShare}>
            {children}
        </Bound>
    )

    async function store(payload) {
        if (!isEdit) return
        const record = await getGroup(item.id)
        const statuses = (record.data.statuses ??= {})
        statuses[getScheduleRecordId(id)] = payload.status
        await saveGroupData(item.id, record.data, undefined, getTreeId(id))
        await setGroupPayload(item, getScheduleRecordId(id), payload)
    }
}

export function assessStatus(payload, target) {
    payload.status = { summary: "", reasons: [] }
    const { reasons } = payload.status
    if (payload.pictures?.documents?.length) {
        payload.status.summary = "cyan"
        reasons.push("Added pictures")
    }
    if (
        htmlToText(payload.introduction).length ||
        htmlToText(payload.notes).length ||
        Object.values(payload.tasks).some((t) => htmlToText(t.introduction).length || htmlToText(t.notes).length)
    ) {
        payload.status.summary = "cyan"
        reasons.push("Added text/notes")
    }
    if (Object.values(payload.tasks).some((t) => t.skillSet || t.unitOfMeasure)) {
        reasons.push("Updated skillsets or unit of measurement for 1+ tasks")
        payload.status.summary = "skyblue"
    }
    if (target.tasks.some((t) => payload.tasks?.[t.id]?.minutes !== t.minutes)) {
        reasons.push("Updated the duration of 1+ tasks")
        payload.status.summary = "skyblue"
    }
    if (
        target.tasks.some((t) => {
            const task = payload.tasks?.[t.id]
            return (
                task?.frequency &&
                t?.frequency &&
                (task.frequency?.period !== t.frequency?.period || task.frequency?.interval !== t.frequency?.interval)
            )
        })
    ) {
        payload.status.summary = "orange"
        reasons.push("Updated the frequency of 1+ tasks")
    }
    if (
        target.tasks.some(
            (t) =>
                t.classification !== "Red" &&
                !!payload.tasks?.[t.id]?.classification &&
                t.classification !== payload.tasks?.[t.id]?.classification
        )
    ) {
        payload.status.summary = "orange"
        reasons.push("Changed the criticality of 1+ tasks")
    }
    if (target.tasks.some((t) => payload.tasks?.[t.id]?.included === false)) {
        payload.status.summary = "orange"
        reasons.push("Removed 1+ tasks")
    }
    if (
        target.tasks.some(
            (t) =>
                t.classification === "Red" &&
                !!payload.tasks?.[t.id]?.classification &&
                t.classification !== payload.tasks?.[t.id]?.classification
        )
    ) {
        payload.status.summary = "red"
        reasons.push("Changed the criticality of 1+ Red tasks")
    }
    if (target.tasks.some((t) => t.classification === "Red" && payload.tasks?.[t.id]?.included === false)) {
        payload.status.summary = "red"
        reasons.push("Removed 1+ Red criticality tasks")
    }
    return payload.status
}
