import { IconButton, Link, TableCell, TableRow } from "@mui/material"
import { updateAssetRegister } from "routes/asset-register/controller/update-asset-register"
import { MdDelete } from "@react-icons/all-files/md/MdDelete"
import { confirm } from "lib/@dialogs/confirm"
import { busyWhile } from "lib/@components/busy"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { BlockClicks } from "lib/@components/block-clicks"
import { navigate } from "lib/routes/navigate"
import { Secure } from "lib/authorization/secure"

export function AssetEntry({ item, register }) {
    const { refresh } = useRefresh(noChange)

    return (
        <TableRow>
            <TableCell
                sx={{
                    minWidth: 200,
                    position: "sticky",
                    left: 0,
                    bgcolor: "background.default",
                    zIndex: 100,
                    boxShadow: "-1px 0px 0px 0px rgba(0,0,0,0.1) inset",
                }}
            >
                <Link onClick={editAsset}>{item.tag}</Link>
            </TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell>{item.type}</TableCell>
            <TableCell>{item.category}</TableCell>
            <TableCell>{item.facility}</TableCell>
            <TableCell>{item.floor}</TableCell>
            <TableCell>{item.space}</TableCell>
            <TableCell>{item.system}</TableCell>

            <TableCell
                sx={{
                    position: "sticky",
                    right: 0,
                    zIndex: 100,
                    bgcolor: "background.default",
                    boxShadow: "1px 0px 0px 0px rgba(0,0,0,0.1) inset",
                }}
            >
                <BlockClicks>
                    <Secure demand="manageRegime">
                        <IconButton color="secondary" onClick={removeAsset}>
                            <MdDelete />
                        </IconButton>
                    </Secure>
                </BlockClicks>
            </TableCell>
        </TableRow>
    )

    async function removeAsset() {
        if (
            await confirm(
                "Are you sure you want to permanently remove this asset from the register?",
                "Irreversible Action"
            )
        ) {
            await busyWhile(
                async () => updateAssetRegister({ registerId: register, removeAssets: [item.id] }),
                "Removing Asset"
            )
            refresh()
        }
    }

    async function editAsset() {
        navigate(`/app/assets/${register}/${item.id}`)
    }
}
