import { Box, Checkbox, Typography } from "@mui/material"
import { bind } from "lib/@components/binding/bind"
import noop from "lib/noop"
import { prevent } from "lib/prevent"

export const BoundCheckBox = bind(<StandardCheckBox />)

export function StandardCheckBox({
    onChange,
    labelProps = {},
    value,
    label,
    className,
    indeterminate,
    labelRight,
    color,
    onBlur = noop,
    "data-cy": cy,
    ...props
}) {
    return (
        <Box
            flexDirection="row"
            role="button"
            flex={1}
            alignItems="center"
            className={className}
            onClick={prevent(change)}
            display="flex"
            sx={{ mb: 0, cursor: "pointer", ...props.sx }}
        >
            {!labelRight && (
                <Typography variant="body2" component="div" mr={1} flexGrow={1} {...labelProps}>
                    {label}
                </Typography>
            )}
            <Box>
                <Checkbox
                    className={`checkbox ${
                        !value && indeterminate ? "indeterminate" : value ? "checked" : "not-checked"
                    }`}
                    indeterminate={!value && indeterminate}
                    disabled={props.disabled}
                    size="small"
                    color={color}
                    checked={!!value}
                    inputProps={{ "data-cy": cy }}
                />
            </Box>
            {!!labelRight && (
                <Typography variant="body2" component="div" mr={1} flexGrow={1} {...labelProps}>
                    {label}
                </Typography>
            )}
        </Box>
    )

    function change() {
        const params = { target: { value: !value } }
        onChange(params)
        onBlur(params)
    }
}
