import { isInEditableRegime } from "routes/facilities/lib/when-parent-item"
import { makeLazy } from "lib/make-lazy"
import { and } from "lib/logic"
import { isEnabled } from "lib/@components/feature"

import { FEATURE_FLAG_ASSET_MAPPING } from "routes/asset-register/feature-flag-asset-mapping"
import { registerTab } from "lib/@components/tabs"
import { REGIME_ASSETS_TAB } from "routes/asset-register/plugins/regime-display/regime-display.runtime"

const AssetRegimeTab = makeLazy(() => import("./assets-regime-tab"), "AssetsRegimeTab")
registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "setup",
    title: "Asset Settings",
    predicate: and(isInEditableRegime, () => isEnabled(FEATURE_FLAG_ASSET_MAPPING)),
    content: <AssetRegimeTab />,
})
