import { appRoute } from "routes/app/home.runtime"
import { TechnicalBulletinBoard } from "./routes/technical-update-bulletin-board"
import { TabbedPage } from "routes/@lib/tabbed-page/tabbed-page.runtime"
import { registerTab } from "lib/@components/tabs"
import { HomePageTile } from "slot-definitions"
import { LatestTechnicalBulletinsWidgets } from "./components/technical-bulletins-widgets"
import { TechnicalUpdateBulletin } from "./routes/technical-update-bulletin"
import "./technical-update.scss"

appRoute.register("technical-updates/:type/:id", <TechnicalUpdateBulletin />)
appRoute.register("technical-updates/", <TechnicalUpdates />)

HomePageTile.plug(<LatestTechnicalBulletinsWidgets priority={101} />) //

function TechnicalUpdates() {
    return <TabbedPage tabId="technicalUpdates" pageTitle="Technical Updates" />
}

registerTab({
    tab: "technicalUpdates",
    id: "technicalBulletins",
    title: "Technical Update Bulletins",
    content: <TechnicalBulletinBoard type={"technical"} priority={50} />,
})

registerTab({
    tab: "technicalUpdates",
    id: "scheduleUpdateBulletins",
    title: "Schedule Update Bulletins",
    content: <TechnicalBulletinBoard type={"schedule"} priority={100} />,
})
