import noop from "lib/noop"
import { useEffect, useRef, useState } from "react"
import { Box, Pagination } from "@mui/material"
import { NotOnMobile, OnMobile } from "lib/@components/mobile"
import { ListItemBox } from "lib/@components/ListItemBox"
import { setFromValueParam } from "lib/setFromEvent"
import { Repeat } from "lib/@components/repeat"
import { NoResults } from "lib/@components/no-results/no-results"
import { Frag } from "lib/@components/slot/frag"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { createPortal } from "react-dom"

export function RequestPageRepeat({
    collection,
    total = 0,
    page: initialPage = 1,
    setSkip = noop,
    setTake = noop,
    children,
    setSettings = noop,
    showBottom = true,
    showTop = false,
    ContentWrapper = Frag,
    Component = Box,
    useRetrieve = (v) => v,
    refreshKey = "",
    fallback = <NoResults />,
    sx,
    color = "primary",
    variant,
    list = collection,
    pageSize = 10,
    ...props
}) {
    const { paginationPortal } = useBoundContext()
    const nop = useRef(Math.ceil(total / pageSize))
    // eslint-disable-next-line prefer-const
    let [page, setPage] = useState(initialPage)
    ;({ total, list } = useRetrieve({ skip: (page - 1) * pageSize, take: pageSize, total, list }))
    const numberOfPages = (nop.current = Math.ceil(total / pageSize))

    useEffect(() => {
        setSkip((page - 1) * pageSize)
        setSettings({ skip: (page - 1) * pageSize, take: pageSize })
        setTake(pageSize)
    }, [page, setSkip, setSettings, setTake, initialPage, pageSize])

    useEffect(() => {
        setPage(1)
    }, [refreshKey, numberOfPages])

    const paginator = (
        <ListItemBox mb={1}>
            {children}
            <Box flex={1} />
            <Pagination
                size="small"
                variant={variant}
                onChange={setFromValueParam((v) => {
                    setPage(v)
                })}
                color={color}
                count={numberOfPages}
                page={page}
                data-cy="request-page-repeat-paginator"
            />
        </ListItemBox>
    )

    if (!list) return null

    return (
        <Component sx={sx}>
            <NotOnMobile>
                {numberOfPages > 1 &&
                    showTop &&
                    (paginationPortal
                        ? createPortal(paginator, paginationPortal)
                        : paginationPortal === false
                          ? null
                          : paginator)}
            </NotOnMobile>
            <OnMobile>
                {numberOfPages > 1 && (
                    <ListItemBox mb={1}>
                        {children}
                        <Box flex={1} />
                        <Pagination
                            size="small"
                            boundaryCount={1}
                            siblingCount={0}
                            variant={variant}
                            onChange={setFromValueParam((v) => {
                                setPage(v)
                            })}
                            color={color}
                            count={numberOfPages}
                            page={page}
                        />
                        <Box flex={1} />
                    </ListItemBox>
                )}
            </OnMobile>
            <ContentWrapper>
                {list.length ? <Repeat {...props} list={list.slice(0, pageSize)} /> : fallback}
            </ContentWrapper>
            <NotOnMobile>
                {numberOfPages > 1 &&
                    showBottom &&
                    (paginationPortal
                        ? createPortal(paginator, paginationPortal)
                        : paginationPortal === false
                          ? null
                          : paginator)}
            </NotOnMobile>
        </Component>
    )
}
