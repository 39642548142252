import { Chart, useChart } from "lib/@components/chart"
import { Box, useTheme } from "@mui/material"
import { useReferenceState } from "lib/@hooks/use-reference"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { createSelectionFromItem } from "routes/asset-register/plugins/regime-display/create-selection-from-item"
import noop from "lib/noop"
import Color from "color"

export function ColumnPie({ list, onClick = noop }) {
    const { currentFilters, filterKey, selectionColor, transformValue = (v) => v } = useBoundContext()
    useReferenceState(filterKey)

    const reportable = list.sortBy("count", true)
    let current = list.reduce((a, c) => a + c.count, 0)
    const report = []
    let i
    for (i = 0; i < reportable.length && i < 7; i++) {
        report.push(reportable[i])
        current -= reportable[i].count
        if (current < reportable[Math.max(0, i - 1)]?.count / 1.75 && i > 3 && reportable.length >= 4) break
    }
    const other = reportable.slice(i + 1).reduce((a, c) => a + c.count, 0)
    const theme = useTheme()

    const data = [
        ...report.map((r, i) => {
            r.onChart = true
            return {
                ...r,
                label: transformValue(r.value[0]) || "Empty",
                color: selectionColor(currentFilters.has(createSelectionFromItem(r)), i, r.value[0]),

                data: r.count,
            }
        }),
        { label: "Other", color: Color(theme.palette.background.default).darken(0.1).hex(), count: other },
    ]

    // .slice(0, 6)
    const options = useChart({
        colors: data.map("color"),
        labels: data.map("label"),
        legend: {
            show: false,
            position: "right",
        },
        stroke: {
            show: true,
            width: 1,
            colors: [theme.palette.background.default],
            opacity: 0,
        },
        chart: {
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    onClick(config.dataPointIndex)
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "75%",
                    labels: {
                        show: false,
                        total: {
                            show: false,
                        },
                    },
                },
                expandOnClick: false,
            },
        },
    })
    return (
        <Box
            sx={{
                mt: 3,
                mb: 1,
                "& .apexcharts-legend-series span": {
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    maxWidth: 180,

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            }}
        >
            <Chart height={160} type="donut" series={data.map("count")} options={options} />
        </Box>
    )
}
