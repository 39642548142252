import { registerTab } from "lib/@components/tabs"

import { makeLazy } from "lib/make-lazy"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

const Guidance = makeLazy(() => import("routes/schedule/plugins/guidance-tab/guidance"), "Guidance")

registerTab({
    tab: "ScheduleRightBar",
    id: "Guidance",
    title: "References",
    predicate(context) {
        return context.target.legislation?.length || context.editMode
    },
    content: <Guidance priority={30} />,
})

export function useLegislationFromId(id) {
    const { legislation } = useBoundContext()
    return legislation.find((l) => l.ScheduleLegislationID.toString() === id.toString())
}
